<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content style-modal-spinner">
      <div class="text-center">
        <div class="spinner-border style-modal-spinner-dim" role="status">
        </div>
      </div>
    </div>
  </div>
</div>
<br/>
<h5 class="card-title">On Boarding Projects</h5>
<div class="btn-group float-right" style="margin:0px" role="group">
  <a class="nav-link text-primary" style="cursor:pointer;" (click)="getAllProjects()">Refresh</a>
</div>
<hr/>

<table class="table table-borderless" style="border: 1px solid #F0F0F0;">
  <thead class="bg-light">
  <tr>
    <th class="font-weight-normal" scope="col" style="width:15%">Name</th>
    <th class="font-weight-normal" scope="col" style="width:20%">Description</th>
    <th class="font-weight-normal" scope="col" style="width:15%">Owner</th>
    <th class="font-weight-normal" scope="col" style="width:5%">Status</th>
    <th class="font-weight-normal" scope="col" style="width:10%">Created On</th>
    <th class="font-weight-normal" scope="col" style="width:15%">Last Processed</th>
    <th class="font-weight-normal" scope="col" style="width:20%">&nbsp;</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let onBoardingProject of onBoardingProjects; index as i" style="border-bottom: 1px solid #F0F0F0;">
    <td>{{ onBoardingProject.projectName}}</td>
    <td>{{ onBoardingProject.description}}</td>
    <td>{{ onBoardingProject.owner}}</td>
    <td>{{ onBoardingProject.processingStatus}}</td>
    <td>{{ onBoardingProject.createdOn | date : 'MM-dd-yy'}}</td>
    <td>{{ onBoardingProject.updatedOn | date : 'MM-dd-yy hh:mm:ss a'}}</td>
    <td>
      <div class="btn-group float-right" style="margin:0px" role="group">
        <button type="button" class="btn btn-primary" [hidden]="!onBoardingProject.canRecreate" (click)="recreateProject(onBoardingProject)">Re-create</button>
        <button type="button" class="btn btn-primary" [hidden]="onBoardingProject.canRecreate" [disabled]="onBoardingProject.processingStatus=='CREATING' || onBoardingProject.processingStatus=='ERROR'" (click)="editProject(onBoardingProject)">Details</button>&nbsp;
        <button type="button" class="btn btn-primary" [hidden]="onBoardingProject.canRecreate" [disabled]="onBoardingProject.processingStatus=='CREATING' || onBoardingProject.processingStatus=='ERROR'" (click)="scanProject(onBoardingProject)">Sync</button>&nbsp;
        <button type="button" class="btn btn-primary" [hidden]="onBoardingProject.canRecreate" [disabled]="onBoardingProject.processingStatus=='CREATING' || onBoardingProject.processingStatus=='ERROR'" (click)="showDueDiligenceForms(onBoardingProject)">Forms</button>&nbsp;
        <button type="button" class="btn btn-primary" [hidden]="onBoardingProject.canRecreate" [disabled]="onBoardingProject.processingStatus=='CREATING' || onBoardingProject.processingStatus=='ERROR'" (click)="archiveProject(onBoardingProject)">Archive</button>
      </div>
    </td>
  </tr>
  </tbody>
</table>
