import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ItformService } from './itform.service';
import { v4 as uuid } from 'uuid';
import { GlobalConstants } from '../../global-constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-itform',
  templateUrl: './itform.component.html',
  styleUrls: ['./itform.component.css']
})
export class ItformComponent implements OnInit {

  itForm: any;
  spinnerModalDisplay = "none";
  projectId: string;
  projectName: string;
  onBoardingProject: any;
  loaded: boolean = false;
  validationFields = [];
  errorMessageInvalidDate = GlobalConstants.ERROR_MSG_INVALID_DATE;
  infoModalDisplay = "none";
  infoModalMessage: String = '';
  infoModalTitle: String = '';

  constructor(private route: ActivatedRoute, private router: Router, private itformService: ItformService, private datePipe: DatePipe) {

  }

  validateDate(event){
      var validationSuccessful = true;
      if(event.target.value!=null && event.target.value.length>0){
        validationSuccessful = GlobalConstants.REGEX_DATE_VALIDATION.test(event.target.value);
      }
      if(validationSuccessful){
        this.validationFields.splice(this.validationFields.indexOf(event.target.name), 1);
        event.target.style.backgroundColor = 'white';
      }else{
        this.validationFields.push(event.target.name);
        event.target.style.backgroundColor = GlobalConstants.ERROR_COLOR;
      }
  }

  showSpinner() {
    this.spinnerModalDisplay = "block";
  }

  hideSpinner() {
    this.spinnerModalDisplay = "none";
  }

  showInfoModalDisplay(modalTitle:string, modalMessage:string) {
    this.infoModalTitle = modalTitle;
    this.infoModalMessage = modalMessage;
    this.infoModalDisplay = "block";
  }

  hideInfoModalDisplay() {
    this.infoModalDisplay = "none";
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.queryParamMap.get('onBoardingProjectId');
    sessionStorage.setItem(GlobalConstants.AUTH_USER_PROJECT_ID, this.projectId);
    this.getProjectById(this.projectId);
    this.getFormData();
  }

  getProjectById(id:string) {
    this.itformService.getProjectById(this.projectId)
      .subscribe(
        data => {
          this.onBoardingProject = data;
          this.projectName = this.onBoardingProject.projectName;
        },
        error => {
          console.log(error);
          this.showInfoModalDisplay("Error","Error while getting project data because "+error.error+". Please try again or reach out to Rarebreed IT.");
        });
  }

  onPimsProductCheckboxChange(event) {
    this.onCheckBoxEvent(this.itForm.pimsInfo.pimsProducts, event);
  }

  onLabIntegrationCheckboxChange(event){
    this.onCheckBoxEvent(this.itForm.pimsInfo.integrations, event);
  }

  onBkUpDestCheckboxChange(event){
    this.onCheckBoxEvent(this.itForm.utilityInfo.backupLocations, event);
  }

  onRemoteAccCheckboxChange(event){
    this.onCheckBoxEvent(this.itForm.remoteAccessInfo.connectionMethods, event);
  }

  onCheckBoxEvent(data:Array<any>, event){
    if (event.target.checked) {
      data.push(event.target.value);
    } else {
      data.splice(data.indexOf(event.target.value), 1);
      }
  }

  addImagingSystem(){
    this.itForm.imagingInfo.imagingSystems.push({id:uuid(), make:'', model:'', serial:'', ageYears:0, ageMonths:0, modalityTypes:[]});
  }

  removeImagingSystem(value){
    this.itForm.imagingInfo.imagingSystems.splice(this.itForm.imagingInfo.imagingSystems.indexOf(value), 1);
  }

  addSwitch(){
    this.itForm.networkInfo.switches.push({id:uuid(), make:'', model:'', location:'', ageYears:0, ageMonths:0, upsBackup:null});
  }

  removeSwitch(value){
    this.itForm.networkInfo.switches.splice(this.itForm.networkInfo.switches.indexOf(value), 1);
  }

  addRouter(){
    this.itForm.networkInfo.routers.push({id:uuid(), make:'', model:'', location:'', ageYears:0, ageMonths:0, upsBackup:null});
  }

  removeRouter(value){
    this.itForm.networkInfo.routers.splice(this.itForm.networkInfo.routers.indexOf(value), 1);
  }

  addModem(){
    this.itForm.networkInfo.modems.push({id:uuid(), make:'', model:'', location:'', ageYears:0, ageMonths:0, upsBackup:null});
  }

  removeModem(value){
    this.itForm.networkInfo.modems.splice(this.itForm.networkInfo.modems.indexOf(value), 1);
  }

  addHandsetLocation(){
    this.itForm.phoneInfo.handsetLocations.push({id:uuid(), location:''});
  }

  removeHandsetLocation(value){
    this.itForm.phoneInfo.handsetLocations.splice(this.itForm.phoneInfo.handsetLocations.indexOf(value), 1);
  }

  addVideoDetail(){
    this.itForm.videoInfo.videoDetails.push({id:uuid(), make:'', model:'', location:'', isSupported:null, supWarContact:'', lastServicedOn:'', problemDesc:''});
  }

  removeVideoDetail(value){
    this.itForm.videoInfo.videoDetails.splice(this.itForm.videoInfo.videoDetails.indexOf(value), 1);
  }

  addServer(){
    this.itForm.serverInfo.servers.push({id:uuid(), name:'', make:'', model:'', serial:'', ageYears:0, ageMonths:0, operatingSystem:'', supportType:null, supWarContact:'',
                                                  backupAvailable:null, backupDesc:'', backupMonitorDesc:'', backupVerificationDesc:'', lastServicedOn:'',problemDesc:'',
                                                  upsBackup:null, upsMake:'',upsModel:'',upsLocation:'', installedSwList:'', serviceAccessDesc:''});
  }

  removeServer(value){
    this.itForm.serverInfo.servers.splice(this.itForm.serverInfo.servers.indexOf(value), 1);
  }

  addWorkstation(){
    this.itForm.workstationInfo.workstations.push({id:uuid(), make:'', model:'', serial:'', ageYears:0, ageMonths:0, monitorAttached:null, monitorMake:'', monitorModel:'', monitorSerial:'', monitorSize:'',
                                                  operatingSystem:'', supportType:null, supWarContact:'', backupAvailable:null, backupDesc:'', backupMonitorDesc:'', backupVerificationDesc:'',
                                                  lastServicedOn:'',problemDesc:'', upsBackup:null, upsMake:'',upsModel:'',connectionMethod:null, installedSwList:'', anyOtherIssues:''});
  }

  removeWorkstation(value){
    this.itForm.workstationInfo.workstations.splice(this.itForm.workstationInfo.workstations.indexOf(value), 1);
  }

  addPrinterDetail(){
    this.itForm.printerInfo.printers.push({id:uuid(), name:'', ageYears:0, ageMonths:0, make:'', model:'', serial:'', connectionMethod:null, location:'', isSupported:null,
                                            supWarContact:'', lastServicedOn:'', maintenanceDesc:'', printerFunctions:[], problemDesc:''});
  }

  removePrinterDetail(value){
    this.itForm.printerInfo.printers.splice(this.itForm.printerInfo.printers.indexOf(value), 1);
  }

  getFormData(){
    this.itformService.getFormData(this.projectId, sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER))
      .subscribe(
        response => {
          console.log(response);
          this.itForm = response;
          this.loaded = true;
        },
        error => {
          console.log(error);
          this.showInfoModalDisplay("Error","Error while getting form data because "+error.error+". Please try again or reach out to Rarebreed IT.");
        });
  }

  save(){
    // console.log(this.itForm);
    this.showSpinner();
    this.itformService.saveForm(this.itForm)
      .subscribe(
        response => {
          this.itForm = response;
          this.hideSpinner();
        },
        error => {
          console.log(error);
          this.hideSpinner();
          this.showInfoModalDisplay("Error","Error while saving form data because "+error.error+". Please try again or reach out to Rarebreed IT.");
        });
  }

  nextForm(){
    this.router.navigate(['/marketingform'], { queryParams: { onBoardingProjectId: sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID) } });
  }

  prevForm(){
    this.router.navigate(['/hrform'], { queryParams: { onBoardingProjectId: sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID) } });
  }

}
