import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DdformService } from './ddform.service';
import { v4 as uuid } from 'uuid';
import { GlobalConstants } from '../../global-constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ddform',
  templateUrl: './ddform.component.html',
  styleUrls: ['./ddform.component.css']
})
export class DdformComponent implements OnInit {

      dueDiligenceForm: any;

      projectId: string;
      projectName: string;
      onBoardingProject: any;
      loaded: boolean = false;
      spinnerModalDisplay = "none";
      infoModalDisplay = "none";
      infoModalMessage: String = '';
      infoModalTitle: String = '';
      validationFields = [];
      errorMessageInvalidDate = GlobalConstants.ERROR_MSG_INVALID_DATE;

      constructor(private route: ActivatedRoute, private router: Router, private ddformService: DdformService, private datePipe: DatePipe) {

      }

      validateDate(event){
          var validationSuccessful = true;
          if(event.target.value!=null && event.target.value.length>0){
            validationSuccessful = GlobalConstants.REGEX_DATE_VALIDATION.test(event.target.value);
          }
          if(validationSuccessful){
            this.validationFields.splice(this.validationFields.indexOf(event.target.name), 1);
            event.target.style.backgroundColor = 'white';
          }else{
            this.validationFields.push(event.target.name);
            event.target.style.backgroundColor = GlobalConstants.ERROR_COLOR;
          }
      }

      showSpinner() {
        this.spinnerModalDisplay = "block";
      }

      hideSpinner() {
        this.spinnerModalDisplay = "none";
      }

      showInfoModalDisplay(modalTitle:string, modalMessage:string) {
        this.infoModalTitle = modalTitle;
        this.infoModalMessage = modalMessage;
        this.infoModalDisplay = "block";
      }

      hideInfoModalDisplay() {
        this.infoModalDisplay = "none";
      }

      ngOnInit() {
        this.projectId = this.route.snapshot.queryParamMap.get('onBoardingProjectId');
        sessionStorage.setItem(GlobalConstants.AUTH_USER_PROJECT_ID, this.projectId);
        this.getProjectById(this.projectId);
        this.getFormData();
      }

      getProjectById(id:string) {
        this.ddformService.getProjectById(this.projectId)
          .subscribe(
            data => {
              this.onBoardingProject = data;
              this.projectName = this.onBoardingProject.projectName;
            },
            error => {
              console.log(error);
              this.showInfoModalDisplay("Error","Error while getting project data because "+error.error+". Please try again or reach out to Rarebreed IT.");
            });
      }

      onClinicOfferingsCheckboxChange(event) {
        this.onCheckBoxEvent(this.dueDiligenceForm.businessInfo.clinicOfferings, event);
      }

      onClinicTypesCheckboxChange(event) {
        this.onCheckBoxEvent(this.dueDiligenceForm.businessInfo.clinicTypes, event);
      }

      onPppCheckboxChange(event) {
        this.onCheckBoxEvent(this.dueDiligenceForm.swInfo.patientPaymentPlanProviders, event);
      }

      onCheckBoxEvent(data:Array<any>, event){
        if (event.target.checked) {
          data.push(event.target.value);
        } else {
          data.splice(data.indexOf(event.target.value), 1);
          }
      }

      addPracticeOwnership(){
        this.dueDiligenceForm.businessInfo.practiceOwnerships.push({id:uuid(), name:'', percentage:0});
      }

      removePracticeOwnership(value){
        this.dueDiligenceForm.businessInfo.practiceOwnerships.splice(this.dueDiligenceForm.businessInfo.practiceOwnerships.indexOf(value), 1);
      }

      addPppAccount(){
        this.dueDiligenceForm.swInfo.patientPaymentPlanAccounts.push({id:uuid(), name:'', accountNumber:''});
      }

      removePppAccount(value){
        this.dueDiligenceForm.swInfo.patientPaymentPlanAccounts.splice(this.dueDiligenceForm.swInfo.patientPaymentPlanAccounts.indexOf(value), 1);
      }

      getFormData(){
        this.ddformService.getFormData(this.projectId, sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER))
          .subscribe(
            response => {
              //console.log(response);
              this.dueDiligenceForm = response;
              this.loaded = true;
            },
            error => {
              console.log(error);
              this.showInfoModalDisplay("Error","Error while getting form data because "+error.error+". Please try again or reach out to Rarebreed IT.");
            });
      }

      save(){
        //console.log(this.dueDiligenceForm);
        this.showSpinner();
        this.ddformService.saveForm(this.dueDiligenceForm)
          .subscribe(
            response => {
              this.dueDiligenceForm = response;
              this.hideSpinner();
            },
            error => {
              console.log(error);
              this.hideSpinner();
              this.showInfoModalDisplay("Error","Error while saving form data because "+error.error+". Please try again or reach out to Rarebreed IT.");
            });
      }

      nextForm(){
        this.router.navigate(['/deaform'], { queryParams: { onBoardingProjectId: sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID) } });
      }
}
