import { Component, OnInit } from '@angular/core';
import { AddprojectserviceService } from './addprojectservice.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-addproject',
  templateUrl: './addproject.component.html',
  styleUrls: ['./addproject.component.css']
})
export class AddprojectComponent implements OnInit {

  onBoardingProject = {
    projectName: '',
    description: '',
    owner: ''
  };
  completed = false;
  inprogress = false;
  isErrorResponse = false;
  errorMessage = '';
  spinnerModalDisplay = "none";
  mode: 'import' | 'create' = 'create';
  asanaLink: string = '';

  constructor(private route: ActivatedRoute, private router: Router, private addprojectserviceService: AddprojectserviceService) { }

  ngOnInit() {

  }

  showSpinner() {
    this.spinnerModalDisplay = "block";
  }

  hideSpinner() {
    this.spinnerModalDisplay = "none";
  }

  createProject() {
    this.showSpinner();
    const data = {projectName: this.onBoardingProject.projectName,
                      description: this.onBoardingProject.description,
                      owner: this.onBoardingProject.owner
                    };

    this.inprogress = true;
    this.completed = false;
    this.isErrorResponse = false;
    this.errorMessage = '';
    this.addprojectserviceService.createProject(data)
      .subscribe(
        response => {
          this.hideSpinner();
          //console.log(response);
          this.completed = true;
          this.inprogress = false;
          this.isErrorResponse = false;
          this.onBoardingProject.projectName = '';
          this.onBoardingProject.description = '';
          this.onBoardingProject.owner = '';
          this.asanaLink = '';
          this.router.navigate(['/listproject']);
        },
        error => {
          this.hideSpinner();
          console.log(error);
          this.completed = false;
          this.inprogress = false;
          this.isErrorResponse = true;
          this.errorMessage = error.error;
        });
        //setTimeout (() => {this.hideSpinner(); this.router.navigate(['/listproject']);}, 5000);
  }

  importProject() {
    this.showSpinner();
    const data = {asanaLink: this.asanaLink};

    this.inprogress = true;
    this.completed = false;
    this.isErrorResponse = false;
    this.errorMessage = '';
    this.addprojectserviceService.syncFromAsana(data)
      .subscribe(
        response => {
          this.hideSpinner();
          //console.log(response);
          this.completed = true;
          this.inprogress = false;
          this.isErrorResponse = false;
          this.onBoardingProject.projectName = '';
          this.onBoardingProject.description = '';
          this.onBoardingProject.owner = '';
          this.asanaLink = '';
          this.router.navigate(['/listproject']);
        },
        rs => {
          this.hideSpinner();
          console.log(rs.error);
          this.completed = false;
          this.inprogress = false;
          this.isErrorResponse = true;
          this.errorMessage = rs.error.error;
        });

  }
}
