<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content style-modal-spinner">
      <div class="text-center">
        <div class="spinner-border style-modal-spinner-dim" role="status">
        </div>
      </div>
    </div>
  </div>
</div>

<br />
<div class="card">
  <div class="card-header">
    <ul class="nav nav-tabs card-header-tabs">
      <li class="nav-item">
        <button class="nav-link"[ngClass]="{ active: mode=='create'}" (click)="mode='create'">New onboarding project.</button>
<!--        <div class="form-check form-check-inline">-->
<!--          <input class="form-check-input form-control-sm" type="radio" name="importFromAsana" id="importFromAsana"/>-->
<!--          <label class="form-check-label col-form-label-sm" for="importFromAsana">Import project from Asana.</label>-->
<!--        </div>-->
      </li>
      <li class="nav-item">
        <button class="nav-link" [ngClass]="{ active: mode==='import'}" (click)="mode='import'">Import project from Asana.</button>
        <!--        <div class="form-check form-check-inline">-->
<!--          <input class="form-check-input form-control-sm" type="radio" name="importFromAsana" id="importFromAsana"/>-->
<!--          <label class="form-check-label col-form-label-sm" for="importFromAsana">Import project from Asana.</label>-->
<!--        </div>-->
      </li>
    </ul>
  </div>



    <div class="card-body" >
      <form class="form-group" *ngIf="mode==='create'">
        <div class="form-group">
          <label for="projectName">Project Name :</label>
          <input type="text" class="form-control" id="projectName" [(ngModel)]="onBoardingProject.projectName" placeholder="Enter project name" name="projectName" required />
        </div>
        <div class="form-group">
          <label for="description">Description :</label>
          <input type="text" class="form-control" id="description" [(ngModel)]="onBoardingProject.description" placeholder="Enter project description" name="description" required />
        </div>
        <div class="form-group">
          <label for="owner">Owner :</label>
          <input type="text" class="form-control" id="owner" [(ngModel)]="onBoardingProject.owner" placeholder="Enter project owner email" name="owner" required />
        </div>
        <br/><hr/>
        <button (click)="createProject()" class="btn btn-primary" [disabled]="inprogress || onBoardingProject.projectName.length < 1 || onBoardingProject.description.length < 1 || onBoardingProject.owner.length < 1">Add Project</button>
      </form>

      <form class="form-group" *ngIf="mode==='import'">
        <div class="form-group">
          <label for="projectName">Asana project link :</label>
          <input type="text" class="form-control" id="asanaLink" [(ngModel)]="asanaLink" placeholder="Enter link to Asana project" name="asanaLink" required />
        </div>
        <br/><hr/>
        <button (click)="importProject()" class="btn btn-primary" [disabled]="inprogress || asanaLink.length < 1">Import Project</button>
      </form>
    </div>

  <div class="alert alert-light" role="alert" *ngIf="inprogress">
    Setting up project. This will take several minutes. {{ mode==='import' ? 'Please do not close this window.' : 'You can also check Asana for progress.' }}
  </div>
  <div class="alert alert-warning" *ngIf='isErrorResponse'>{{errorMessage}}</div>


</div>
