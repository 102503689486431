import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../login/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from '../global-constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  isLoggedIn = false;
  public username: String;
  public userrole: String;
  public userFullName: String;

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {

  }

  ngOnInit() {
    this.isLoggedIn = this.authenticationService.isUserLoggedIn();
    this.username = sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER);
    this.userrole = sessionStorage.getItem(GlobalConstants.AUTH_USER_ROLE);
    this.userFullName = sessionStorage.getItem(GlobalConstants.AUTH_USER_FULL_NAME)
  }

  handleLogout() {
    this.authenticationService.logout().subscribe(
      (res) => {
        this.router.navigate(['login']);
      }
    );

  }
}
