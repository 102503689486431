import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';

@Injectable({
  providedIn: 'root'
})
export class AddprojectserviceService {

  //BASE_PATH = '.'
  //BASE_PATH = 'http://localhost:7077'

  constructor(private http: HttpClient) { }

   getAll() {
     //return this.http.get(baseUrl);
   }

   get(id) {
     //return this.http.get(`${baseUrl}/${id}`);
   }

   syncFromAsana(data) {
     console.log("In AddprojectserviceService now and calling rest service ");
     return this.http.post(GlobalConstants.apiURL+'/api/onboarding/project/asana/sync', data);
   }

   createProject(data) {
     console.log("In AddprojectserviceService now and calling rest service ");
     return this.http.post(GlobalConstants.apiURL+'/api/onboarding/project/setup', data);
   }

   update(id, data) {
     //return this.http.put(`${baseUrl}/${id}`, data);
   }

   delete(id) {
     //return this.http.delete(`${baseUrl}/${id}`);
   }

   deleteAll() {
     //return this.http.delete(baseUrl);
   }

   findByTitle(title) {
     //return this.http.get(`${baseUrl}?title=${title}`);
   }
}
