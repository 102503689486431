import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-ddmenu',
  templateUrl: './ddmenu.component.html',
  styleUrls: ['./ddmenu.component.css']
})
export class DdmenuComponent implements OnInit {

  isAdmin = false;
  public userrole: String;
  constructor() { }

  ngOnInit() {
    this.userrole = sessionStorage.getItem('USER_ROLE');
    if(this.userrole=='ADMIN'){
      this.isAdmin = true;
    }else{
      this.isAdmin = false;
    }
  }

  isCensusConfigEnabled(): boolean {
    return environment.isCensusConfigEnabled;
  }
}
