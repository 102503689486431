import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';

@Injectable({
  providedIn: 'root'
})
export class HrformService {

  constructor(private http: HttpClient) { }

  saveForm(data) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/hrform', data);
  }

  savePayrollForm(data) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/hrpayrollform', data);
  }

  getProjectById(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/'+projectId);
  }

  getFormData(projectId:string, userName:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/form/hrform/'+projectId+'/'+userName);
  }

  getPayrollFormData(projectId:string, userName:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/form/hrpayrollform/'+projectId+'/'+userName);
  }

}
export interface DayEnablementWithNote {
  enabled: boolean,
  note: string
}
export interface WeekCheck {
  sunday?: DayEnablementWithNote;
  monday?: DayEnablementWithNote;
  tuesday?: DayEnablementWithNote;
  wednesday?: DayEnablementWithNote;
  thursday?: DayEnablementWithNote;
  friday?: DayEnablementWithNote;
  saturday?: DayEnablementWithNote;
}


export interface HrCompPayInfo {
  practiceManager?: string;
  additionalAuthorizedApprovers?: string;
  hoursOfOperation: WeekCheck;
  overnightShifts?: "YES"|"NO";
  shiftDifferentialPay?: "YES"|"NO";
  shiftDifferentialApply?:  Array<"HOURLY_FT_EMPLOYEES" | "HOURLY_PT_EMPLOYEES" | "HOURLY_PER_DIEM_EMPLOYEES" | "SALARIED_EMPLOYEES">;

  shiftDifferentialApplyDayOfWeek?: Array<any>;

  shiftDifferentialRate: {
    type?: "HOURLY_ADD"| "HOURLY_PERCENT" | "BONUS";
    amount?: number;
  }

  shiftDifferentialNotes?: string;

  workForCommission?: "YES"|"NO";
  workForCommissionDescription?: string;


  paidBreaks?: "YES"|"NO";
  paidBreaksNotes?: string;

  fullRemoteExistance?: "YES"|"NO";
  stateRemoteWorkIn?: string;
  currentPayrollSystem?: string;
  regularPayCycle?: "WEEKLY"|"BI_WEEKLY";

  firstDayOfPayCycle?: "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
  lastDayOfPayCycle?: "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
  benefitDeductions?: "BI_WEEKLY"|"TWICE_MONTH"|"NA";

  petBenefit?: "YES"|"NO";
  petBenefitNote?: string;

  monthlyAllowances?: "YES"|"NO";
  monthlyAllowancesNote?: string;
  otherBenefits?: "YES"|"NO";
  otherBenefitsNote?: string;
  holidayList? : Array<string>;
  standardHolidayHours?: "YES"|"NO";
  standardHolidayHoursNote?: string;
  standardHolidayPayHours?: number;

}
export interface HrForm {
  benefitInfo?: any;
  compInfo?: any;
  empInfo?: any;


  projectId?: string;
  userId?: string;
  lastSavedOn?: Date;
  status?: string;
}

export interface HrPayrollForm {
  compPayInfo?: HrCompPayInfo;
  projectId?: string;
  userId?: string;
  lastSavedOn?: Date;
  status?: string;
}
