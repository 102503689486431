import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MarketingformService } from './marketingform.service';
import { v4 as uuid } from 'uuid';
import { GlobalConstants } from '../../global-constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-marketingform',
  templateUrl: './marketingform.component.html',
  styleUrls: ['./marketingform.component.css']
})
export class MarketingformComponent implements OnInit {

      marketingForm: any;
      spinnerModalDisplay = "none";
      projectId: string;
      projectName: string;
      onBoardingProject: any;
      loaded: boolean = false;
      validationFields = [];
      errorMessageInvalidDate = GlobalConstants.ERROR_MSG_INVALID_DATE;
      infoModalDisplay = "none";
      infoModalMessage: String = '';
      infoModalTitle: String = '';

      formEditPermission = {
        userId: '',
        projectId: '',
        enable: true
      };

      constructor(private route: ActivatedRoute, private router: Router, private marketingformService: MarketingformService, private datePipe: DatePipe) {

      }

      validateDate(event){
          var validationSuccessful = true;
          //var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
          if(event.target.value!=null && event.target.value.length>0){
            validationSuccessful = GlobalConstants.REGEX_DATE_VALIDATION.test(event.target.value);
          }
          if(validationSuccessful){
            this.validationFields.splice(this.validationFields.indexOf(event.target.name), 1);
            event.target.style.backgroundColor = 'white';
          }else{
            this.validationFields.push(event.target.name);
            event.target.style.backgroundColor = GlobalConstants.ERROR_COLOR;
          }
      }

      showSpinner() {
        this.spinnerModalDisplay = "block";
      }

      hideSpinner() {
        this.spinnerModalDisplay = "none";
      }

      showInfoModalDisplay(modalTitle:string, modalMessage:string) {
        this.infoModalTitle = modalTitle;
        this.infoModalMessage = modalMessage;
        this.infoModalDisplay = "block";
      }

      hideInfoModalDisplay() {
        this.infoModalDisplay = "none";
      }

      ngOnInit() {
        this.projectId = this.route.snapshot.queryParamMap.get('onBoardingProjectId');
        sessionStorage.setItem(GlobalConstants.AUTH_USER_PROJECT_ID, this.projectId);
        this.getProjectById(this.projectId);
        this.getFormData();
      }

      getProjectById(id:string) {
        this.marketingformService.getProjectById(this.projectId)
          .subscribe(
            data => {
              this.onBoardingProject = data;
              this.projectName = this.onBoardingProject.projectName;
            },
            error => {
              console.log(error);
              this.showInfoModalDisplay("Error","Error while getting project data because "+error.error+". Please try again or reach out to Rarebreed IT.");
            });
      }

      onDiscountTypesCheckboxChange(event) {
        this.onCheckBoxEvent(this.marketingForm.internalInfo.discountTypes, event);
      }

      onCheckBoxEvent(data:Array<any>, event){
        if (event.target.checked) {
          data.push(event.target.value);
        } else {
          data.splice(data.indexOf(event.target.value), 1);
          }
      }

      getFormData(){
        this.marketingformService.getFormData(this.projectId, sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER))
          .subscribe(
            response => {
              console.log(response);
              this.marketingForm = response;
              this.loaded = true;
            },
            error => {
              console.log(error);
              this.showInfoModalDisplay("Error","Error while getting form data because "+error.error+". Please try again or reach out to Rarebreed IT.");
            });
      }

      save(){
        // console.log(this.marketingForm);
        this.showSpinner();
        this.marketingformService.saveForm(this.marketingForm)
          .subscribe(
            response => {
              this.marketingForm = response;
              this.hideSpinner();
            },
            error => {
              console.log(error);
              this.hideSpinner();
              this.showInfoModalDisplay("Error","Error while saving form data because "+error.error+". Please try again or reach out to Rarebreed IT.");
            });
      }

      finish(){
        this.showSpinner();
        this.formEditPermission.projectId = this.projectId;
        this.formEditPermission.enable = false;
        this.formEditPermission.userId = sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER);
        this.marketingformService.finish(this.formEditPermission)
          .subscribe(
            response => {
              this.marketingForm.status='COMPLETE';
              this.hideSpinner();
            },
            error => {
              console.log(error);
              this.hideSpinner();
              this.showInfoModalDisplay("Error","Error while finalizing form because "+error.error+". Please try again or reach out to Rarebreed IT.");
            });
      }

      prevForm(){
        this.router.navigate(['/itform'], { queryParams: { onBoardingProjectId: sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID) } });
      }

}
