import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../../global-constants';
import { ListprojectService } from './listproject.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listproject',
  templateUrl: './listproject.component.html',
  styleUrls: ['./listproject.component.css']
})
export class ListprojectComponent implements OnInit {

  onBoardingProjects: any;
  spinnerModalDisplay = "none";

  constructor(private route: ActivatedRoute, private router: Router, private listprojectService: ListprojectService) { }

  ngOnInit() {
    this.getAllProjects();
  }

  showSpinner() {
    this.spinnerModalDisplay = "block";
  }

  hideSpinner() {
    this.spinnerModalDisplay = "none";
  }

  getAllProjects() {
    this.showSpinner();
    this.listprojectService.getAll()
      .subscribe(
        data => {
          this.onBoardingProjects = data;
          this.hideSpinner();
        },
        error => {
          console.log(error);
          this.hideSpinner();
        });
  }
  recreateProject(onBoardingProject:any){
    this.showSpinner();
    this.listprojectService.recreateProjectById(onBoardingProject.id)
      .subscribe(
        data => {
          this.hideSpinner();
          this.getAllProjects();
        },
        error => {
          this.hideSpinner();
          console.log(error);
        });
  }

  editProject(onBoardingProject:any) {
    this.router.navigate(['/editproject'], { queryParams: { id: onBoardingProject.id } });
  }

  showDueDiligenceForms(onBoardingProject:any){
    this.router.navigate(['/duediligenceform'], { queryParams: { onBoardingProjectId: onBoardingProject.id } });
  }

  scanProject(onBoardingProject:any){
    this.showSpinner();
    this.listprojectService.scanProject(onBoardingProject.id)
      .subscribe(
        data => {
          this.hideSpinner();
        },
        error => {
          this.hideSpinner();
          console.log(error);
        });
  }

  archiveProject(onBoardingProject:any){
    this.showSpinner();
    this.listprojectService.archiveProject(onBoardingProject.id)
      .subscribe(
        data => {
          this.hideSpinner();
          this.getAllProjects();
        },
        error => {
          this.hideSpinner();
          console.log(error);
        });
  }

}
