import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {GlobalConstants} from '../global-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public username: string;
  public password: string;
  public isAuthenticated: string;
  public userRole: string;
  public fullName: string;
  public projectId: string;
  authenticationResponse: any;

  constructor(private http: HttpClient) {

  }

  authenticationService(username: string, password: string) {
    return this.http.get(GlobalConstants.apiURL + `/api/authenticate`,
      {headers: {authorization: this.createBasicAuthToken(username, password)}}).pipe(map((res) => {
      this.authenticationResponse = res;
      this.username = username;
      this.password = password;
      this.isAuthenticated = 'true';
      this.userRole = this.authenticationResponse.role; // 'ADMIN'; //ADMIN or USER
      this.fullName = this.authenticationResponse.fullName;
      this.projectId = this.authenticationResponse.projectId;
      this.registerSuccessfulLogin(username, password, this.isAuthenticated, this.userRole, this.fullName, this.projectId);
    }));

  }

  createBasicAuthToken(username: string, password: string) {
    const basicAuthToken = 'Basic ' + window.btoa(username + ':' + password);
    sessionStorage.setItem(GlobalConstants.AUTH_SESSION_TOKEN, basicAuthToken);
    return basicAuthToken;
  }

  registerSuccessfulLogin(username, password, isAuthenticated, userRole, fullName, projectId) {
    sessionStorage.setItem(GlobalConstants.AUTH_LOGGED_IN_USER, username)
    sessionStorage.setItem(GlobalConstants.AUTH_IS_AUTHENTICATED, isAuthenticated);
    sessionStorage.setItem(GlobalConstants.AUTH_USER_ROLE, userRole);
    sessionStorage.setItem(GlobalConstants.AUTH_USER_FULL_NAME, fullName);
    sessionStorage.setItem(GlobalConstants.AUTH_USER_PROJECT_ID, projectId);
  }

  logout() {
    console.log('logging out ' + this.username + ' session is ' + GlobalConstants.AUTH_LOGGED_IN_USER);
    sessionStorage.removeItem(GlobalConstants.AUTH_LOGGED_IN_USER);
    sessionStorage.removeItem(GlobalConstants.AUTH_IS_AUTHENTICATED);
    sessionStorage.removeItem(GlobalConstants.AUTH_USER_ROLE);
    sessionStorage.removeItem(GlobalConstants.AUTH_USER_FULL_NAME);
    sessionStorage.removeItem(GlobalConstants.AUTH_USER_PROJECT_ID);
    sessionStorage.removeItem(GlobalConstants.AUTH_SESSION_TOKEN);
    this.username = null;
    this.password = null;
    return this.http.get(GlobalConstants.apiURL + `/api/logoff`);
  }

  isUserLoggedIn() {
    const user = sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER)
    if (user === null) return false;
    return true
  }

  getLoggedInUserName() {
    const user = sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER)
    if (user === null) return '';
    return user
  }
}
