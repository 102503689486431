<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay}">
     <div class="modal-dialog modal-dialog-centered" role="document">
           <div class="modal-content style-modal-spinner">
             <div class="text-center">
                <div class="spinner-border style-modal-spinner-dim" role="status">
                </div>
              </div>
          </div>
     </div>
</div>
<br />
<h5 class="card-title">{{projectName}}</h5>
<div class="btn-group float-right" style="margin-top:5px" role="group">
  <button type="button" class="btn btn-primary" [hidden]="!onBoardingProject.canRecreate" (click)="recreateProject(onBoardingProject)">Re-create</button>
  <button type="button" class="btn btn-primary" [disabled]="onBoardingProject.processingStatus=='CREATING' || onBoardingProject.processingStatus=='ERROR'" (click)="scanProject()">Sync</button>&nbsp;
  <button type="button" class="btn btn-primary" [disabled]="onBoardingProject.processingStatus=='CREATING' || onBoardingProject.processingStatus=='ERROR'" (click)="getUserActivityProjectById()">Refresh Activity</button>&nbsp;
  <button type="button" class="btn btn-primary" [disabled]="onBoardingProject.processingStatus=='CREATING' || onBoardingProject.processingStatus=='ERROR'" (click)="showDueDiligenceForms()">Forms</button>&nbsp;
  <button type="button" class="btn btn-primary" [disabled]="onBoardingProject.processingStatus=='CREATING' || onBoardingProject.processingStatus=='ERROR'" (click)="showAddUserDialog()">Add user</button>
</div>
<hr/>
<div class="card border border-white">
  <div class="card-body">
    <h6 class="card-subtitle mb-2 text-muted">Description: {{projectDescription}}</h6>
    <br/>
    <h6 class="card-subtitle mb-2 text-muted">Owner: {{projectOwner}}</h6>
    <br/>
    <h6 class="card-subtitle mb-2 text-muted">Status: {{processingStatus}}</h6>
    <br/>
    <h6 class="card-subtitle mb-2 text-muted">Created On: {{createdOn | date : 'MM-dd-yyyy'}}</h6>
    <br/>
    <h6 class="card-subtitle mb-2 text-muted">Last Scanned: {{lastProcessedOn | date : 'MM-dd-yyyy hh:mm:ss a'}}</h6>
    <br/>
    <ng-container *ngIf="onBoardingProject.sharepointUrl">
      <h6 class="card-subtitle mb-2 text-muted">Sharepoint location: <a [href]="onBoardingProject.sharepointUrl" target="_blank" class="btn btn-outline-secondary">Go to Sharepoint <span class="bi bi-box-arrow-up-right"></span></a></h6>
      <br/>
    </ng-container>
    <ng-container *ngIf="onBoardingProject.externalProjectId">
      <h6 class="card-subtitle mb-2 text-muted">Asana project link: <a href="{{asanaLink()}}" target="_blank" class="btn btn-outline-secondary">Go to Asana project <span class="bi bi-box-arrow-up-right"></span></a> </h6>
      <br/>
    </ng-container>
    <h6 class="card-subtitle mb-2 text-muted" *ngIf="formStatusResp">Form Status: {{formStatusResp.status}}</h6>
    <br>
    <h6 class="card-subtitle mb-2 text-muted" *ngIf="formStatusResp">Form Last Updated: {{formStatusResp.lastUpdatedOn | date : 'MM-dd-yyyy hh:mm:ss a'}}</h6>
    <br *ngIf="formStatusResp && formStatusResp.status=='COMPLETE'"/>
    <h6 class="card-subtitle mb-2 text-muted" *ngIf="formStatusResp && formStatusResp.status=='COMPLETE'"><button type="button" class="btn btn-success" (click)="enableFormEdit()">Re-enable</button>&nbsp;form editing.</h6>
  </div>
</div>

<!--User-->
<div class="font-weight-bold" style="width: 200px;margin-top:20px">
  Project Users
</div>
<!--Add new user-->
<div *ngIf="showUserDialogFlag" class="card" style="width: 50rem;">
  <div class="card-header">{{actionTitle}}</div>
  <div class="card-body">
    <form class="form-group">
      <div class="form-group">
        <label for="name">Name :</label>
        <input [disabled]="editUserFlag" type="text" class="form-control" id="name" [(ngModel)]="selectedUser.name" placeholder="Enter full name of user. (min 6 characters long)" name="name" required />
      </div>
      <div class="form-group">
        <label for="userName">User Name :</label>
        <input [disabled]="editUserFlag" type="text" class="form-control" id="userName" minlength="6" [(ngModel)]="selectedUser.userName" placeholder="Enter user name. (min 6 characters long)" name="userName" required />
      </div>
      <div class="form-group">
        <label for="password">Password :</label>
        <input type="password" class="form-control" id="password" minlength="8" [(ngModel)]="selectedUser.password" placeholder="Enter password. (min 6 characters long)" name="password" required />
      </div>
      <div class="form-group">
        <label for="retypePassword">Re-type Password :</label>
        <input type="password" class="form-control" id="retypePassword" [(ngModel)]="selectedUser.retypePassword" placeholder="Enter password again. (should match with password entered above)" name="retypePassword" required />
      </div>

      <div class="btn-group pull-right" style="margin:10px" role="group">
        <button [disabled]="selectedUser.userName?.length<6 || selectedUser.name?.length<6 || selectedUser.password?.length<6 || selectedUser.retypePassword?.length<6 || selectedUser.password!=selectedUser.retypePassword" (click)="saveUserAction()" class="btn btn-primary">Save</button>
        &nbsp;
        <button (click)="cancelUserDialog()" class="btn btn-warning">Cancel</button>
      </div>
    </form>
  </div>
</div>
<div *ngIf="showUserDialogFlag"><br/></div>
<div class="alert alert-warning" *ngIf='isErrorResponse'>{{errorMessage}}</div>

<!--List all users for project-->
<div>
  <table class="table table-borderless table-sm" style="border: 1px solid #F0F0F0;">
    <thead class="bg-light">
    <tr>
      <th class="font-weight-normal" scope="col" style="width:25%">Name</th>
      <th class="font-weight-normal" scope="col" style="width:15%">User Name</th>
      <th class="font-weight-normal" scope="col" style="width:5%">Role</th>
      <th class="font-weight-normal" scope="col" style="width:5%">Active</th>
      <th class="font-weight-normal" scope="col" style="width:15%">Created Date</th>
      <th class="font-weight-normal" scope="col" style="width:15%">Last Logged In</th>
      <th class="font-weight-normal" scope="col" style="width:20%">&nbsp;</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users; index as i" style="border-bottom: 1px solid #F0F0F0;">
      <td>{{user.name}}</td>
      <td>{{user.userName}}</td>
      <td>{{user.role}}</td>
      <td>{{user.enabled}}</td>
      <td>{{user.createdOn | date : 'MM-dd-yyyy hh:mm:ss a'}}</td>
      <td>{{user.lastLoggedIn | date : 'MM-dd-yyyy hh:mm:ss a'}}</td>
      <td>
        <div class="btn-group float-right" style="margin:0px" role="group">
          <button type="button" class="btn btn-secondary" (click)="showChangePasswordDialog(user)">Change Password</button>&nbsp;
          <button type="button" class="btn btn-secondary" (click)="deleteUserAction(user)">Delete</button>&nbsp;
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<!--End Users-->

<!--Comments and attachments-->
<div class="font-weight-bold" style="width: 200px;margin-top:25px">
  User Activities
</div>
<div>
  <table class="table table-borderless table-sm table-striped" style="border: 1px solid #F0F0F0; table-layout:fixed;">
    <thead class="bg-light">
    <tr>
      <th class="font-weight-normal" style="width:5%" scope="col">Type</th>
      <th class="font-weight-normal" style="width:15%" scope="col">Date</th>
      <th class="font-weight-normal" style="width:30%" scope="col">Comment/File</th>
      <th class="font-weight-normal" style="width:10%" scope="col">By</th>
      <th class="font-weight-normal" style="width:20%;" scope="col">Task</th>
      <th class="font-weight-normal" style="width:10%" scope="col">Status</th>
      <th class="font-weight-normal" style="width:5%" scope="col">Location</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let userActivity of pageOf(userActivities, currentPage); index as i" style="border-bottom: 1px solid #F0F0F0;">
      <td><img height="30" src="assets/images/{{userActivity.type}}.png"/></td>
      <td>{{userActivity.addedOn | date : 'MM-dd-yyyy hh:mm:ss a'}}</td>
      <td style="text-wrap: normal">{{userActivity.data}}</td>
      <td>{{userActivity.addedBy}}</td>
      <td>{{userActivity.taskName}}</td>
      <td>{{userActivity.status}}</td>
      <td><a href="{{loc}}" class="btn-outline-secondary btn btn-sm" style="font-size: 1.0em;" target="_blank" *ngFor="let loc of userActivity.location">Link <span class="bi bi-folder-symlink"></span></a></td>
    </tr>
    </tbody>
    <tfoot *ngIf="pages.length>1">
      <nav style="margin-top: 1em; margin-left: 1.1em;">
        <ul class="pagination">
          <li class="page-item" *ngFor="let page of pages;" [ngClass]="{ active: currentPage == page}">
            <span class="page-link" (click)="currentPage = page;" >{{ page }}</span>
          </li>
        </ul>
      </nav>
    </tfoot>
  </table>
</div>
<!--End Comments and attachments-->
