import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import {DDSettings, DdsettingsService} from "./ddsettings.service";

@Component({
  selector: 'dd-settings',
  templateUrl: './ddsettings.component.html',
  styleUrls: ['./ddsettings.component.css']
})
export class DDSettingsComponent implements OnInit {

      spinnerModalDisplay = "none";
      selectedFile: File;
      settings: DDSettings = {};
      editMode: boolean;
      summary: string;
      constructor(private route: ActivatedRoute, private router: Router, private ddsettingsService: DdsettingsService) { }

      ngOnInit() {
        this.showSpinner();
        this.ddsettingsService.getDDTemplate().subscribe(data => {
          this.settings = data as DDSettings;
          this.hideSpinner();
        }, error => {
          this.settings = {};
          this.summary = 'Cannot load settings';
          this.hideSpinner();
        });
        this.editMode=false;
      }

      showSpinner() {
        this.spinnerModalDisplay = "block";
      }

      hideSpinner() {
        this.spinnerModalDisplay = "none";
      }
      onFileChanged(event) {
        this.selectedFile = event.target.files[0];
      }

      saveSettings() {

        this.showSpinner();
        this.summary = null;
        this.ddsettingsService.uploadDDTemplate(this.selectedFile)
          .subscribe(
            data => {
              this.settings = data as DDSettings;
              this.editMode = false;
              this.hideSpinner();
              this.selectedFile = null;
              this.summary = null;
              // this.settings = data as DDSettings;
            },
            error => {
              this.summary = error.error;
              this.hideSpinner();
              this.selectedFile = null;
            });


        this.selectedFile = null;
      }
}
