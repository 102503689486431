<app-menu></app-menu>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay}">
     <div class="modal-dialog modal-dialog-centered" role="document">
           <div class="modal-content style-modal-spinner">
             <div class="text-center">
                <div class="spinner-border style-modal-spinner-dim" role="status">
                </div>
              </div>
          </div>
     </div>
</div>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':infoModalDisplay}">
     <div class="modal-dialog modal-dialog-centered" role="document">
           <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title">{{infoModalTitle}}</h5>
                     <button type="button" class="close" aria-label="Close" (click)="hideInfoModalDisplay()"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <p>{{infoModalMessage}}</p>
                </div>
                <div class="modal-footer">
                    <br/>
                </div>
          </div>
     </div>
</div>

<div class="container mw-100 h-100">
    <div class="row h-100">
        <div class="col col-lg-2 bg-light">
            <app-usermenu></app-usermenu>
        </div>

        <div class="col col-lg-7">
          <h6 style="padding: 2px;"><a routerLink="/editproject" [queryParams]="{id: projectId}"><span class="bi bi-arrow-left"></span> Back to project</a></h6>
              <div [hidden]="!loaded" class="d-flex flex-row-reverse">
                <div class="p-2 font-weight-light"><small>Last updated on {{hrForm.lastSavedOn | date : 'MM-dd-yyyy hh:mm:ss a'}}</small></div>
              </div>
              <h5 class="card-title">People Ops and Payroll Questionnaire&nbsp;<small>{{projectName}}</small></h5>
              <div class="card" *ngIf="loaded">
                  <div class="card-body">
                      <form class="form-group">
                          <div class="alert alert-primary" role="alert" style="text-align:center;">Benefit Information</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Practice Name</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="practiceName" placeholder="Enter practice name"  [(ngModel)]="hrForm.benefitInfo.practiceName" name="practiceName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Type of Insurance Offered</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ins_type_health" value="HEALTH" [checked]="hrForm.benefitInfo.offeredInsuranceTypes.indexOf('HEALTH') > -1" (change)="insuranceTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ins_type_health">Health</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ins_type_dental" value="DENTAL" [checked]="hrForm.benefitInfo.offeredInsuranceTypes.indexOf('DENTAL') > -1" (change)="insuranceTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ins_type_dental">Dental</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ins_type_vison" value="VISON" [checked]="hrForm.benefitInfo.offeredInsuranceTypes.indexOf('VISON') > -1" (change)="insuranceTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ins_type_vison">Vison</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ins_type_supplemental" value="SUPPLEMENTAL" [checked]="hrForm.benefitInfo.offeredInsuranceTypes.indexOf('SUPPLEMENTAL') > -1" (change)="insuranceTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ins_type_supplemental">Supplemental</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ins_type_life" value="LIFE" [checked]="hrForm.benefitInfo.offeredInsuranceTypes.indexOf('LIFE') > -1" (change)="insuranceTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ins_type_life">Life</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ins_type_st" value="SHORT_TERM" [checked]="hrForm.benefitInfo.offeredInsuranceTypes.indexOf('SHORT_TERM') > -1" (change)="insuranceTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ins_type_st">Short-Term Disability</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ins_type_lt" value="LONG_TERM" [checked]="hrForm.benefitInfo.offeredInsuranceTypes.indexOf('LONG_TERM') > -1" (change)="insuranceTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ins_type_lt">Long-Term Disability</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Health Insurance Provider</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="healthInsuranceProvider" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.healthInsuranceProvider" name="healthInsuranceProvider" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Dental Insurance Provider</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="dentalInsuranceProvider" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.dentalInsuranceProvider" name="dentalInsuranceProvider" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Vison Insurance Provider</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="visonInsuranceProvider" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.visonInsuranceProvider" name="visonInsuranceProvider" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Supplemental Insurance Provider</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="supplementalInsuranceProvider" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.supplementalInsuranceProvider" name="supplementalInsuranceProvider" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Life Insurance Provider</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="lifeInsuranceProvider" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.lifeInsuranceProvider" name="lifeInsuranceProvider" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Short Term Disability Insurance Provider</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="shortTermInsuranceProvider" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.shortTermInsuranceProvider" name="shortTermInsuranceProvider" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Long Term Disability Insurance Provider</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="longTermInsuranceProvider" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.longTermInsuranceProvider" name="longTermInsuranceProvider" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Who is your Benefits Broker, Name & Contact Info?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="benefitBrokerInfo" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.benefitBrokerInfo" name="benefitBrokerInfo" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you pay % or $ towards employee for insurance?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="matchEmpInsurance" id="matchEmpInsuranceYes" value="YES" [(ngModel)]="hrForm.benefitInfo.matchEmpInsurance"/>
                                        <label class="form-check-label col-form-label-sm" for="matchEmpInsuranceYes">Yes&nbsp;</label>
                                        <div class="input-group input-group-sm mb-2">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">%</div>
                                          </div>
                                          <input type="number" class="form-control form-control-sm" placeholder="" name="matchEmpInsurancePct" [(ngModel)]="hrForm.benefitInfo.matchEmpInsurancePct" />
                                        </div>
                                        <div>&nbsp;Or&nbsp;</div>
                                        <div class="input-group input-group-sm mb-2">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">$</div>
                                          </div>
                                          <input type="number" class="form-control form-control-sm" placeholder="" name="matchEmpInsuranceAmt" [(ngModel)]="hrForm.benefitInfo.matchEmpInsuranceAmt" />
                                        </div>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="matchEmpInsurance" id="matchEmpInsuranceNo" value="NO" [(ngModel)]="hrForm.benefitInfo.matchEmpInsurance" />
                                        <label class="form-check-label col-form-label-sm" for="matchEmpInsuranceNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you pay % or $ towards employee family for insurance?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="matchEmpFamilyInsurance" id="matchEmpFamilyInsuranceYes" value="YES" [(ngModel)]="hrForm.benefitInfo.matchEmpFamilyInsurance"/>
                                        <label class="form-check-label col-form-label-sm" for="matchEmpFamilyInsuranceYes">Yes&nbsp;</label>
                                        <div class="input-group input-group-sm mb-2">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">%</div>
                                          </div>
                                          <input type="number" class="form-control form-control-sm" placeholder="" name="matchEmpFamilyInsurancePct" [(ngModel)]="hrForm.benefitInfo.matchEmpFamilyInsurancePct" />
                                        </div>
                                        <div>&nbsp;Or&nbsp;</div>
                                        <div class="input-group input-group-sm mb-2">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">$</div>
                                          </div>
                                          <input type="number" class="form-control form-control-sm" placeholder="" name="matchEmpFamilyInsuranceAmt" [(ngModel)]="hrForm.benefitInfo.matchEmpFamilyInsuranceAmt" />
                                        </div>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="matchEmpFamilyInsurance" id="matchEmpFamilyInsuranceNo" value="NO" [(ngModel)]="hrForm.benefitInfo.matchEmpFamilyInsurance" />
                                        <label class="form-check-label col-form-label-sm" for="matchEmpFamilyInsuranceNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ contribution to Health</label>
                              <div class="col-sm-3">
                                <input type="number" class="form-control form-control-sm" id="pctOfAmtEmployerHealth" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.pctOfAmtEmployerHealth" name="pctOfAmtEmployerHealth" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ contribution to Dental</label>
                              <div class="col-sm-3">
                                <input type="number" class="form-control form-control-sm" id="pctOfAmtEmployerDental" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.pctOfAmtEmployerDental" name="pctOfAmtEmployerDental" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ contribution to Vison</label>
                              <div class="col-sm-3">
                                <input type="number" class="form-control form-control-sm" id="pctOfAmtEmployerVison" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.pctOfAmtEmployerVison" name="pctOfAmtEmployerVison" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ contribution to Life</label>
                              <div class="col-sm-3">
                                <input type="number" class="form-control form-control-sm" id="pctOfAmtEmployerLife" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.pctOfAmtEmployerLife" name="pctOfAmtEmployerLife" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ contribution to STD</label>
                              <div class="col-sm-3">
                                <input type="number" class="form-control form-control-sm" id="pctOfAmtEmployerStd" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.pctOfAmtEmployerStd" name="pctOfAmtEmployerStd" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ contribution to LTD</label>
                              <div class="col-sm-3">
                                <input type="number" class="form-control form-control-sm" id="pctOfAmtEmployerLtd" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.pctOfAmtEmployerLtd" name="pctOfAmtEmployerLtd" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">When is employee insurance renewal date?</label>
                              <div class="col-sm-3">
                                <input type="text" class="form-control form-control-sm" id="empInsuranceRenewalDate" placeholder=""  [(ngModel)]="hrForm.benefitInfo.empInsuranceRenewalDate" name="empInsuranceRenewalDate" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What type of Retirement Plan do you provide?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ret_plan_fourOneK" value="FOUR_ZERO_ONE_K" [checked]="hrForm.benefitInfo.retirementPlans.indexOf('FOUR_ZERO_ONE_K') > -1" (change)="retirementPlansCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ret_plan_fourOneK">401 K</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ret_plan_sira" value="SIMPLE_IRA" [checked]="hrForm.benefitInfo.retirementPlans.indexOf('SIMPLE_IRA') > -1" (change)="retirementPlansCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ret_plan_sira">Simple IRA</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ret_plan_rira" value="ROTH_IRA" [checked]="hrForm.benefitInfo.retirementPlans.indexOf('ROTH_IRA') > -1" (change)="retirementPlansCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ret_plan_rira">Roth IRA</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ret_plan_none" value="NONE" [checked]="hrForm.benefitInfo.retirementPlans.indexOf('NONE') > -1" (change)="retirementPlansCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ret_plan_none">None</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Who is your Retirement Broker, Name and Contact Info?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="retirementBrokerInfo" placeholder="Enter N/A if not applicable"  [(ngModel)]="hrForm.benefitInfo.retirementBrokerInfo" name="retirementBrokerInfo" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you provide a match or give to the employee for their retirement?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="retirementBenefit" id="retirementBenefitMatch" value="MATCH" [(ngModel)]="hrForm.benefitInfo.retirementBenefit"/>
                                        <label class="form-check-label col-form-label-sm" for="retirementBenefitMatch">Match&nbsp;</label>
                                        <div class="input-group input-group-sm mb-2">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">% or $</div>
                                          </div>
                                          <input type="text" class="form-control form-control-sm" placeholder="" name="retirementBenefitMatchValue" [(ngModel)]="hrForm.benefitInfo.retirementBenefitMatchValue" />
                                        </div>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="retirementBenefit" id="retirementBenefitGive" value="GIVE" [(ngModel)]="hrForm.benefitInfo.retirementBenefit" />
                                        <label class="form-check-label col-form-label-sm" for="retirementBenefitGive">Give&nbsp;</label>
                                        <div class="input-group input-group-sm mb-2">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">% or $</div>
                                          </div>
                                          <input type="text" class="form-control form-control-sm" placeholder="" name="retirementBenefitGiveValue" [(ngModel)]="hrForm.benefitInfo.retirementBenefitGiveValue" />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If Roth IRA is offered what kind of contributions do you offer?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="rothIRA" id="rothIRAPreTax" value="PRE_TAX" [(ngModel)]="hrForm.benefitInfo.rothIRA"/>
                                        <label class="form-check-label col-form-label-sm" for="rothIRAPreTax">Pre&nbsp;Tax&nbsp;</label>
                                        <div class="input-group input-group-sm mb-2">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">% or $</div>
                                          </div>
                                          <input type="text" class="form-control form-control-sm" placeholder="" name="rothIRAPreTaxValue" [(ngModel)]="hrForm.benefitInfo.rothIRAPreTaxValue" />
                                        </div>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="rothIRA" id="rothIRAPostTax" value="POST_TAX" [(ngModel)]="hrForm.benefitInfo.rothIRA" />
                                        <label class="form-check-label col-form-label-sm" for="rothIRAPostTax">Post&nbsp;Tax&nbsp;</label>
                                        <div class="input-group input-group-sm mb-2">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text">% or $</div>
                                          </div>
                                          <input type="text" class="form-control form-control-sm" placeholder="" name="rothIRAPostTaxValue" [(ngModel)]="hrForm.benefitInfo.rothIRAPostTaxValue" />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <!--End Benefit Information-->
                          <br/>

                          <!--Begin Compensation / PTO Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Compensation / PTO Information</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What is your Vacation / PTO Policy ?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="vacationPtoPolicy" placeholder=""  [(ngModel)]="hrForm.compInfo.vacationPtoPolicy" name="vacationPtoPolicy" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What is your Sick Time Policy ?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="sickTimePolicy" placeholder=""  [(ngModel)]="hrForm.compInfo.sickTimePolicy" name="sickTimePolicy" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How is vacation calculated?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="vacationCalculated" id="vacationCalculatedAccrual" value="ACCURAL" [(ngModel)]="hrForm.compInfo.vacationCalculated"/>
                                        <label class="form-check-label col-form-label-sm" for="vacationCalculatedAccrual">Accrual Basis</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="vacationCalculated" id="vacationCalculatedLumpSum" value="LUMPSUM" [(ngModel)]="hrForm.compInfo.vacationCalculated" />
                                        <label class="form-check-label col-form-label-sm" for="vacationCalculatedLumpSum">Lump Sum on a Specific Date</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If granted on a date, what is the date?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="vacationGrantDay" id="vacationGrantDayAnniv" value="ANNIVERSARY" [(ngModel)]="hrForm.compInfo.vacationGrantDay"/>
                                        <label class="form-check-label col-form-label-sm" for="vacationGrantDayAnniv">Anniversary</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="vacationGrantDay" id="vacationGrantDayFirstDay" value="FIRST_DAY_YEAR" [(ngModel)]="hrForm.compInfo.vacationGrantDay" />
                                        <label class="form-check-label col-form-label-sm" for="vacationGrantDayFirstDay">First of year</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="vacationGrantDay" id="vacationGrantDayOther" value="OTHER" [(ngModel)]="hrForm.compInfo.vacationGrantDay" />
                                        <label class="form-check-label col-form-label-sm" for="vacationGrantDayOther">Other&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="vacationGrantDayOtherDesc" placeholder="specify"  [(ngModel)]="hrForm.compInfo.vacationGrantDayOtherDesc" name="vacationGrantDayOtherDesc" />
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Does anyone in the hospital receive a monthly cell phone allowance?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline" style="width:70%">
                                        <input class="form-check-input form-control-sm" type="radio" name="monthlyPhoneAllowance" id="monthlyPhoneAllowanceYes" value="YES" [(ngModel)]="hrForm.compInfo.monthlyPhoneAllowance"/>
                                        <label class="form-check-label col-form-label-sm" for="monthlyPhoneAllowanceYes">Yes&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="monthlyPhoneAllowanceDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.monthlyPhoneAllowanceDesc" name="monthlyPhoneAllowanceDesc" />
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="monthlyPhoneAllowance" id="monthlyPhoneAllowanceNo" value="NO" [(ngModel)]="hrForm.compInfo.monthlyPhoneAllowance" />
                                        <label class="form-check-label col-form-label-sm" for="monthlyPhoneAllowanceNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes to cell phone allowance, who and how much?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="monthlyPhoneRecipients" placeholder=""  [(ngModel)]="hrForm.compInfo.monthlyPhoneRecipients" name="monthlyPhoneRecipients" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are there any special employee arrangements we should be aware of?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline" style="width:70%">
                                        <input class="form-check-input form-control-sm" type="radio" name="specialEmpArrangement" id="specialEmpArrangementYes" value="YES" [(ngModel)]="hrForm.compInfo.specialEmpArrangement"/>
                                        <label class="form-check-label col-form-label-sm" for="specialEmpArrangementYes">Yes&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="specialEmpArrangementDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.specialEmpArrangementDesc" name="specialEmpArrangementDesc" />
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="specialEmpArrangement" id="specialEmpArrangementNo" value="NO" [(ngModel)]="hrForm.compInfo.specialEmpArrangement" />
                                        <label class="form-check-label col-form-label-sm" for="specialEmpArrangementNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are there any special grandfathered arrangements we should be made aware of?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline" style="width:70%">
                                        <input class="form-check-input form-control-sm" type="radio" name="specialEmpGFatheredArrangement" id="specialEmpGFatheredArrangementYes" value="YES" [(ngModel)]="hrForm.compInfo.specialEmpGFatheredArrangement"/>
                                        <label class="form-check-label col-form-label-sm" for="specialEmpGFatheredArrangementYes">Yes&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="specialEmpGFatheredArrangementDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.specialEmpGFatheredArrangementDesc" name="specialEmpGFatheredArrangementDesc" />
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="specialEmpGFatheredArrangement" id="specialEmpGFatheredArrangementNo" value="NO" [(ngModel)]="hrForm.compInfo.specialEmpGFatheredArrangement" />
                                        <label class="form-check-label col-form-label-sm" for="specialEmpGFatheredArrangementNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are there any current Worker's Comp Claims pending?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline" style="width:70%">
                                        <input class="form-check-input form-control-sm" type="radio" name="pendingWorkerComp" id="pendingWorkerCompYes" value="YES" [(ngModel)]="hrForm.compInfo.pendingWorkerComp"/>
                                        <label class="form-check-label col-form-label-sm" for="pendingWorkerCompYes">Yes&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="pendingWorkerCompDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.pendingWorkerCompDesc" name="pendingWorkerCompDesc" />
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="pendingWorkerComp" id="pendingWorkerCompNo" value="NO" [(ngModel)]="hrForm.compInfo.pendingWorkerComp" />
                                        <label class="form-check-label col-form-label-sm" for="pendingWorkerCompNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are there any employess currently unemployed?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline" style="width:70%">
                                        <input class="form-check-input form-control-sm" type="radio" name="empOnUnEmp" id="empOnUnEmpYes" value="YES" [(ngModel)]="hrForm.compInfo.empOnUnEmp"/>
                                        <label class="form-check-label col-form-label-sm" for="empOnUnEmpYes">Yes&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="empOnUnEmpDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.empOnUnEmpDesc" name="empOnUnEmpDesc" />
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="empOnUnEmp" id="empOnUnEmpNo" value="NO" [(ngModel)]="hrForm.compInfo.empOnUnEmp" />
                                        <label class="form-check-label col-form-label-sm" for="empOnUnEmpNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are there any employees currently on any type of leave? STD, LTD, Maternity, Leave of Absence?</label>
                              <div class="col-sm-9">
                              <div *ngFor="let empLeaveDetail of hrForm.compInfo.empLeaveDetails; let i=index">

                                      <div class="form-row" style="margin-top:5px">
                                          <div class="col">
                                              <div class="input-group input-group-sm mb-2">
                                                <div class="input-group-prepend">
                                                  <div class="input-group-text">Name</div>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" placeholder="Name" name = "name-{{i}}" [(ngModel)]="hrForm.compInfo.empLeaveDetails[i].name"/>
                                              </div>
                                            </div>
                                            <div class="col">
                                              <div class="input-group input-group-sm mb-2">
                                                <div class="input-group-prepend">
                                                  <div class="input-group-text">Leave Type</div>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" placeholder="Leave Type" name = "leaveType-{{i}}" [(ngModel)]="hrForm.compInfo.empLeaveDetails[i].leaveType"/>
                                              </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                          <div class="col">
                                              <div class="input-group input-group-sm mb-2">
                                                <div class="input-group-prepend">
                                                  <div class="input-group-text">Last Worked On</div>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" placeholder="MM/DD/YYYY" name = "lastDayOfWork-{{i}}" [(ngModel)]="hrForm.compInfo.empLeaveDetails[i].lastDayOfWork" (blur)="validateDate($event)"/>
                                                <!--<div class="alert alert-warning" *ngIf="validationFields.indexOf('lastDayOfWork-{{i}}')> -1">{{errorMessageInvalidDate}}</div>-->
                                              </div>
                                          </div>
                                          <div class="col">
                                              <div class="input-group input-group-sm mb-2">
                                                <div class="input-group-prepend">
                                                  <div class="input-group-text">Return Date</div>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" placeholder="MM/DD/YYYY" name = "returnDate-{{i}}" [(ngModel)]="hrForm.compInfo.empLeaveDetails[i].returnDate" (blur)="validateDate($event)"/>
                                                <!--<div class="alert alert-warning" *ngIf="validationFields.indexOf('lastDayOfWork-{{i}}')> -1">{{errorMessageInvalidDate}}</div>-->
                                              </div>
                                          </div>
                                        </div>
                                        <div class="form-row">
                                          <div class="col text-right">
                                            <button type="submit" (click)="removeEmployeeLeaveDetail(empLeaveDetail)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                            <hr/>
                                          </div>
                                        </div>


                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addEmployeeLeaveDetail()" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are there any staff excluding Vets on Pro Sal Basis?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline" style="width:70%">
                                        <input class="form-check-input form-control-sm" type="radio" name="proSalBasis" id="proSalBasisYes" value="YES" [(ngModel)]="hrForm.compInfo.proSalBasis"/>
                                        <label class="form-check-label col-form-label-sm" for="proSalBasisYes">Yes&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="proSalBasisDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.proSalBasisDesc" name="proSalBasisDesc" />
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="proSalBasis" id="proSalBasisNo" value="NO" [(ngModel)]="hrForm.compInfo.proSalBasis" />
                                        <label class="form-check-label col-form-label-sm" for="proSalBasisNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are there any Employees on a bonus Structure?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline" style="width:70%">
                                        <input class="form-check-input form-control-sm" type="radio" name="empOnBonusStructure" id="empOnBonusStructureYes" value="YES" [(ngModel)]="hrForm.compInfo.empOnBonusStructure"/>
                                        <label class="form-check-label col-form-label-sm" for="empOnBonusStructureYes">Yes&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="empOnBonusStructureDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.empOnBonusStructureDesc" name="empOnBonusStructureDesc" />
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="empOnBonusStructure" id="empOnBonusStructureNo" value="NO" [(ngModel)]="hrForm.compInfo.empOnBonusStructure" />
                                        <label class="form-check-label col-form-label-sm" for="empOnBonusStructureNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How are vets compensated?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="vetCompType" id="vetCompType_sp" value="STRAIGHT_PAY" [(ngModel)]="hrForm.compInfo.vetCompType"/>
                                        <label class="form-check-label col-form-label-sm" for="empOnBonusStructureYes">Straight Pay</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="vetCompType" id="vetCompType_ps" value="PRO_SAL" [(ngModel)]="hrForm.compInfo.vetCompType" />
                                        <label class="form-check-label col-form-label-sm" for="vetCompType_ps">Pro-Sal</label>
                                      </div>
                                      <div class="form-check form-check-inline" style="width:40%">
                                        <input class="form-check-input form-control-sm" type="radio" name="vetCompType" id="vetCompType_other" value="OTHER" [(ngModel)]="hrForm.compInfo.vetCompType" />
                                        <label class="form-check-label col-form-label-sm" for="vetCompType_other">Other&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="vetCompTypeOtherDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.vetCompTypeOtherDesc" name="vetCompTypeOtherDesc" />
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How often is pro-Sal compensation calculated?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="proSalCalcFreq" id="proSalCalcFreq_weekly" value="WEEKLY" [(ngModel)]="hrForm.compInfo.proSalCalcFreq"/>
                                        <label class="form-check-label col-form-label-sm" for="proSalCalcFreq_weekly">Weekly</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="proSalCalcFreq" id="proSalCalcFreq_monthly" value="MONTHLY" [(ngModel)]="hrForm.compInfo.proSalCalcFreq" />
                                        <label class="form-check-label col-form-label-sm" for="proSalCalcFreq_monthly">Monthly</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="proSalCalcFreq" id="proSalCalcFreq_qtrly" value="QUARTERLY" [(ngModel)]="hrForm.compInfo.proSalCalcFreq" />
                                        <label class="form-check-label col-form-label-sm" for="proSalCalcFreq_qtrly">Quarterly</label>
                                      </div>
                                      <div class="form-check form-check-inline" style="width:40%">
                                        <input class="form-check-input form-control-sm" type="radio" name="proSalCalcFreq" id="proSalCalcFreq_other" value="OTHER" [(ngModel)]="hrForm.compInfo.proSalCalcFreq" />
                                        <label class="form-check-label col-form-label-sm" for="proSalCalcFreq_other">Other&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="proSalCalcOtherDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.proSalCalcOtherDesc" name="proSalCalcOtherDesc" />
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Provide formula for calculating Pro-Sal compensation</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="proSalCompFormula" placeholder=""  [(ngModel)]="hrForm.compInfo.proSalCompFormula" name="proSalCompFormula" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you provide bonuses at any specific time during the year?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline" style="width:70%">
                                        <input class="form-check-input form-control-sm" type="radio" name="midYearBonus" id="midYearBonusYes" value="YES" [(ngModel)]="hrForm.compInfo.midYearBonus"/>
                                        <label class="form-check-label col-form-label-sm" for="midYearBonusYes">Yes&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="midYearBonusDesc" placeholder="If yes, explain"  [(ngModel)]="hrForm.compInfo.midYearBonusDesc" name="midYearBonusDesc" />
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="midYearBonus" id="midYearBonusNo" value="NO" [(ngModel)]="hrForm.compInfo.midYearBonus" />
                                        <label class="form-check-label col-form-label-sm" for="midYearBonusNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what are bonuses based on?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="midYearBonusBasis_formula" value="FORMULA" [checked]="hrForm.compInfo.midYearBonusBasis.indexOf('FORMULA') > -1" (change)="bonusBasisCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="midYearBonusBasis_formula">Formula</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="midYearBonusBasis_perf" value="PERFORMANCE" [checked]="hrForm.compInfo.midYearBonusBasis.indexOf('PERFORMANCE') > -1" (change)="bonusBasisCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="midYearBonusBasis_perf">Practice Performance</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="midYearBonusBasis_seniority" value="SENIORITY" [checked]="hrForm.compInfo.midYearBonusBasis.indexOf('SENIORITY') > -1" (change)="bonusBasisCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="midYearBonusBasis_seniority">Seniority</label>
                                    </div>
                                    <div class="form-check form-check-inline" style="width:40%">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="midYearBonusBasis_other" value="OTHER" [checked]="hrForm.compInfo.midYearBonusBasis.indexOf('OTHER') > -1" (change)="bonusBasisCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="midYearBonusBasis_other">Other&nbsp;</label>
                                      <input type="text" class="form-control form-control-sm" id="midYearBonusBasisOtherDesc" placeholder="specify"  [(ngModel)]="hrForm.compInfo.midYearBonusBasisOtherDesc" name="midYearBonusBasisOtherDesc" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <!--End Compensation / PTO Information-->

                          <br/>

                          <!--Begin Employee Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Employee Information</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you provide offer letters to staff, exclusing vets, upon hire?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="offerLettersOnHire" id="offerLettersOnHireYes" value="YES" [(ngModel)]="hrForm.empInfo.offerLettersOnHire"/>
                                        <label class="form-check-label col-form-label-sm" for="offerLettersOnHireYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="offerLettersOnHire" id="offerLettersOnHireNo" value="NO" [(ngModel)]="hrForm.empInfo.offerLettersOnHire" />
                                        <label class="form-check-label col-form-label-sm" for="offerLettersOnHireNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you require contracts withh your Veterinarians?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="contractsOnHire" id="contractsOnHireYes" value="YES" [(ngModel)]="hrForm.empInfo.contractsOnHire"/>
                                        <label class="form-check-label col-form-label-sm" for="contractsOnHireYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="contractsOnHire" id="contractsOnHireNo" value="NO" [(ngModel)]="hrForm.empInfo.contractsOnHire" />
                                        <label class="form-check-label col-form-label-sm" for="contractsOnHireNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are raises provided to staff?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="raisesProvideToStaff" id="raisesProvideToStaffYes" value="YES" [(ngModel)]="hrForm.empInfo.raisesProvideToStaff"/>
                                        <label class="form-check-label col-form-label-sm" for="raisesProvideToStaffYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="raisesProvideToStaff" id="raisesProvideToStaffNo" value="NO" [(ngModel)]="hrForm.empInfo.raisesProvideToStaff" />
                                        <label class="form-check-label col-form-label-sm" for="raisesProvideToStaffNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How often are raises provided?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="raiseFrequencies_qtrly" value="QUARTERLY" [checked]="hrForm.empInfo.raiseFrequencies.indexOf('QUARTERLY') > -1" (change)="raiseFrequenciesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="raiseFrequencies_qtrly">Quarterly</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="raiseFrequencies_yrly" value="ANNUALLY" [checked]="hrForm.empInfo.raiseFrequencies.indexOf('ANNUALLY') > -1" (change)="raiseFrequenciesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="raiseFrequencies_yrly">Annually</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="raiseFrequencies_merit" value="MERIT" [checked]="hrForm.empInfo.raiseFrequencies.indexOf('MERIT') > -1" (change)="raiseFrequenciesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="raiseFrequencies_merit">Merit</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="raiseFrequencies_colng" value="COST_OF_LIVING" [checked]="hrForm.empInfo.raiseFrequencies.indexOf('COST_OF_LIVING') > -1" (change)="raiseFrequenciesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="raiseFrequencies_colng">Cost of Living</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If annually, when it is provided?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="annualRaiseGrantDay" id="annualRaiseGrantDayAnniv" value="ANNIVERSARY" [(ngModel)]="hrForm.empInfo.annualRaiseGrantDay"/>
                                        <label class="form-check-label col-form-label-sm" for="annualRaiseGrantDayAnniv">Anniversary</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="annualRaiseGrantDay" id="annualRaiseGrantDayOne" value="FIRST_DAY_YEAR" [(ngModel)]="hrForm.empInfo.annualRaiseGrantDay" />
                                        <label class="form-check-label col-form-label-sm" for="annualRaiseGrantDayOne">First of year</label>
                                      </div>
                                      <div class="form-check form-check-inline" style="width:40%">
                                        <input class="form-check-input form-control-sm" type="radio" name="annualRaiseGrantDay" id="annualRaiseGrantDayOther" value="OTHER" [(ngModel)]="hrForm.empInfo.annualRaiseGrantDay" />
                                        <label class="form-check-label col-form-label-sm" for="annualRaiseGrantDayOther">Other&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="annualRaiseGrantDayOtherDesc" placeholder="specify"  [(ngModel)]="hrForm.empInfo.annualRaiseGrantDayOtherDesc" name="annualRaiseGrantDayOtherDesc" />
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">When were the last raises given?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="lastRaiseGiven" placeholder=""  [(ngModel)]="hrForm.empInfo.lastRaiseGiven" name="lastRaiseGiven" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you provide annual employee reviews?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="provideAnnualReview" id="provideAnnualReviewYes" value="YES" [(ngModel)]="hrForm.empInfo.provideAnnualReview"/>
                                        <label class="form-check-label col-form-label-sm" for="provideAnnualReviewYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="provideAnnualReview" id="provideAnnualReviewNo" value="NO" [(ngModel)]="hrForm.empInfo.provideAnnualReview" />
                                        <label class="form-check-label col-form-label-sm" for="provideAnnualReviewNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, who provides reviews?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="annualReviewersList" placeholder="Enter Reviewer(s) comma separated."  [(ngModel)]="hrForm.empInfo.annualReviewersList" name="annualReviewersList" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is there documentation of review in employee file?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="annualReviewOnEmpFile" id="annualReviewOnEmpFileYes" value="YES" [(ngModel)]="hrForm.empInfo.annualReviewOnEmpFile"/>
                                        <label class="form-check-label col-form-label-sm" for="annualReviewOnEmpFileYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="annualReviewOnEmpFile" id="annualReviewOnEmpFileNo" value="NO" [(ngModel)]="hrForm.empInfo.annualReviewOnEmpFile" />
                                        <label class="form-check-label col-form-label-sm" for="annualReviewOnEmpFileNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Who are your key employees, managers or supervisors?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="keyPeopleList" placeholder="Enter names comma separated"  [(ngModel)]="hrForm.empInfo.keyPeopleList" name="keyPeopleList" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What shifts do employees typically work?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="workShifts_eight" value="EIGHT" [checked]="hrForm.empInfo.workShifts.indexOf('EIGHT') > -1" (change)="workShiftsCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="workShifts_eight">8 hrs</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="workShifts_nine" value="NNIE" [checked]="hrForm.empInfo.workShifts.indexOf('NNIE') > -1" (change)="workShiftsCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="workShifts_nine">9 hrs</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="workShifts_ten" value="TEN" [checked]="hrForm.empInfo.workShifts.indexOf('TEN') > -1" (change)="workShiftsCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="workShifts_ten">10 hrs</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="workShifts_twelve" value="TWELVE" [checked]="hrForm.empInfo.workShifts.indexOf('TWELVE') > -1" (change)="workShiftsCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="workShifts_twelve">12 hrs</label>
                                    </div>
                                    <div class="form-check form-check-inline" style="width:40%">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="workShifts_other" value="OTHER" [checked]="hrForm.empInfo.workShifts.indexOf('OTHER') > -1" (change)="workShiftsCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="workShifts_other">Other&nbsp;</label>
                                      <input type="text" class="form-control form-control-sm" id="workShiftOtherDesc" placeholder="specify"  [(ngModel)]="hrForm.empInfo.workShiftOtherDesc" name="workShiftOtherDesc" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are you currently hiring any positions?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="hiringPositions" id="hiringPositionsYes" value="YES" [(ngModel)]="hrForm.empInfo.hiringPositions"/>
                                        <label class="form-check-label col-form-label-sm" for="hiringPositionsYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="hiringPositions" id="hiringPositionsNo" value="NO" [(ngModel)]="hrForm.empInfo.hiringPositions" />
                                        <label class="form-check-label col-form-label-sm" for="hiringPositionsNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes what positions and how many openings?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Vet #</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringVetCount" [(ngModel)]="hrForm.empInfo.hiringVetCount" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">LVT #</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringLvtCount" [(ngModel)]="hrForm.empInfo.hiringLvtCount" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Tech #</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringTechCount" [(ngModel)]="hrForm.empInfo.hiringTechCount" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">CSR #</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringCsrCount" [(ngModel)]="hrForm.empInfo.hiringCsrCount" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How long has each position been posted?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Vet</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringLvtDaysPosted" [(ngModel)]="hrForm.empInfo.hiringLvtDaysPosted" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">LVT</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringLvtDaysPosted" [(ngModel)]="hrForm.empInfo.hiringLvtDaysPosted" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Tech</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringTechDaysPosted" [(ngModel)]="hrForm.empInfo.hiringTechDaysPosted" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">CSR</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringCsrDaysPosted" [(ngModel)]="hrForm.empInfo.hiringCsrDaysPosted" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have any contracts with any recruiting agencies?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline" style="width:60%">
                                        <input class="form-check-input form-control-sm" type="radio" name="recruitingAgencyContract" id="recruitingAgencyContractYes" value="YES" [(ngModel)]="hrForm.empInfo.recruitingAgencyContract"/>
                                        <label class="form-check-label col-form-label-sm" for="recruitingAgencyContractYes">Yes&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="recruitingAgencyName" placeholder="Enter agency info"  [(ngModel)]="hrForm.empInfo.recruitingAgencyName" name="recruitingAgencyName" />
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="recruitingAgencyContract" id="recruitingAgencyContractNo" value="NO" [(ngModel)]="hrForm.empInfo.recruitingAgencyContract" />
                                        <label class="form-check-label col-form-label-sm" for="hiringPositionsNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How long does it take you typically fill your Vet positions?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="empFillFreq_month" value="MONTH" [checked]="hrForm.empInfo.empFillFrequencies.indexOf('MONTH') > -1" (change)="empFillFreqCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="empFillFreq_month">Month</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="empFillFreq_sixmonth" value="SIX_MONTH" [checked]="hrForm.empInfo.empFillFrequencies.indexOf('SIX_MONTH') > -1" (change)="empFillFreqCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="empFillFreq_sixmonth">6 Months</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="empFillFreq_year" value="YEAR" [checked]="hrForm.empInfo.empFillFrequencies.indexOf('YEAR') > -1" (change)="empFillFreqCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="empFillFreq_year">1 Year</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="empFillFreq_more" value="MORE" [checked]="hrForm.empInfo.empFillFrequencies.indexOf('MORE') > -1" (change)="empFillFreqCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="empFillFreq_more">More than 1 year</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How about your Licensed Tech positions?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="techFillFreq_weeks" value="WEEKS" [checked]="hrForm.empInfo.techFillFrequencies.indexOf('WEEKS') > -1" (change)="techFillFreqCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="techFillFreq_weeks">Weeks</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="techFillFreq_onethreemonths" value="ONE_THREE_MONTHS" [checked]="hrForm.empInfo.techFillFrequencies.indexOf('ONE_THREE_MONTHS') > -1" (change)="techFillFreqCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="techFillFreq_onethreemonths">1-3 Months</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="techFillFreq_threesixmonths" value="THREE_SIX_MONTHS" [checked]="hrForm.empInfo.techFillFrequencies.indexOf('THREE_SIX_MONTHS') > -1" (change)="techFillFreqCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="techFillFreq_threesixmonths">3-6 Months</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="techFillFreq_more" value="MORE" [checked]="hrForm.empInfo.techFillFrequencies.indexOf('MORE') > -1" (change)="techFillFreqCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="techFillFreq_more"> &gt; 6 months</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What is your hiring budget per position?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Vet $</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringBudgetVet" [(ngModel)]="hrForm.empInfo.hiringBudgetVet" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">LVT $</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringBudgetLvt" [(ngModel)]="hrForm.empInfo.hiringBudgetLvt" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Other $</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="" name="hiringBudgetOther" [(ngModel)]="hrForm.empInfo.hiringBudgetOther" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Who does the hiring?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="hiringContact" placeholder="Enter names comma separated"  [(ngModel)]="hrForm.empInfo.hiringContact" name="hiringContact" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do any employees have a company credit card?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="employeeCreditCard" id="employeeCreditCardYes" value="YES" [(ngModel)]="hrForm.empInfo.employeeCreditCard"/>
                                        <label class="form-check-label col-form-label-sm" for="employeeCreditCardYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="employeeCreditCard" id="employeeCreditCardNo" value="NO" [(ngModel)]="hrForm.empInfo.employeeCreditCard" />
                                        <label class="form-check-label col-form-label-sm" for="employeeCreditCardNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, list who currently has credit cards?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="employeesWithCreditCard" placeholder="Enter names comma separated"  [(ngModel)]="hrForm.empInfo.employeesWithCreditCard" name="employeesWithCreditCard" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is there a Union or Collective Bargaining Agreement in place?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="unionCBAgreement" id="unionCBAgreementYes" value="YES" [(ngModel)]="hrForm.empInfo.unionCBAgreement"/>
                                        <label class="form-check-label col-form-label-sm" for="unionCBAgreementYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="unionCBAgreement" id="unionCBAgreementNo" value="NO" [(ngModel)]="hrForm.empInfo.unionCBAgreement" />
                                        <label class="form-check-label col-form-label-sm" for="unionCBAgreementNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <!--End Employee Information-->

                        <br/>

                        <!--Begin Compensation/Payroll Information-->
                        <div>
                          <div class="alert alert-primary" role="alert" style="text-align:center;">Compensation/Payroll Information</div>
                          <div>
                            <label class="col-sm-12 col-form-label row">Practice manager’s name and any other employees who need access to edit and
                              approve timecards.</label>
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right" for="practiceManager">Practice manager</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="practiceManager" placeholder="Practice manager's name"  [(ngModel)]="hrPayrollForm.compPayInfo.practiceManager" name="practiceManager" />
                              </div>
                              <label class="col-sm-3 col-form-label-sm text-right" for="additionalAuthorizedApprovers">Additional Authorized Approvers</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="additionalAuthorizedApprovers" placeholder="Enter names comma separated"  [(ngModel)]="hrPayrollForm.compPayInfo.additionalAuthorizedApprovers" name="additionalAuthorizedApprovers" />
                              </div>
                            </div>
                          </div>
                          <div>
                            <label class="col-sm-12 col-form-label row">What are your hours of operation? If not open, please put Closed in the box.</label>
                            <div class="form-group row" *ngFor="let hoursOfOperation of weekDays; let x=index">
                              <label class="col-sm-3 col-form-label-sm text-right" for="hoursOfOperation_{{hoursOfOperation.value}}">{{hoursOfOperation.full}}</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text input-group-text-radio">
                                          <input type="checkbox" name="hoursOfOperation_{{hoursOfOperation.value}}" [checked]="hrPayrollForm.compPayInfo.hoursOfOperation[hoursOfOperation.full.toLowerCase()].enabled===true" (change)="onHoursOfOperationChange($event, hoursOfOperation.full.toLowerCase())">
                                        </div>
                                        <div class="input-group-text input-group-second-text">{{hrPayrollForm.compPayInfo.hoursOfOperation[hoursOfOperation.full.toLowerCase()].enabled ? 'Open' : 'Closed'}} </div>
                                      </div>
                                      <input type="text" name="hoursOfOperation_{{hoursOfOperation.value}}_note" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="!hrPayrollForm.compPayInfo.hoursOfOperation[hoursOfOperation.full.toLowerCase()].enabled" [(ngModel)]="hrPayrollForm.compPayInfo.hoursOfOperation[hoursOfOperation.full.toLowerCase()].note">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Do employees work overnight shifts (third shift)?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="overnightShifts" id="overnightShiftsYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.overnightShifts"/>
                                    <label class="form-check-label col-form-label-sm" for="overnightShiftsYes">Yes</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="overnightShifts" id="overnightShiftsNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.overnightShifts" />
                                    <label class="form-check-label col-form-label-sm" for="overnightShiftsNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label class="col-sm-12 col-form-label text-left row">A shift differential is an incentive offered to employees in exchange for working
                              harder to schedule shifts.</label>
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you offer shift differential pay?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialPay" id="shiftDifferentialPayYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialPay"/>
                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialPayYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialPay" id="shiftDifferentialPayNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialPay" />
                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialPayNo">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, who does the shift differential apply to</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApply" id="shiftDifferentialApplyHFTE" value="HOURLY_FT_EMPLOYEES" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApply.indexOf('HOURLY_FT_EMPLOYEES') > 0" (change)="onShiftDifferentialApplyCheck($event);"/>
                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialApplyHFTE">Hourly FT employees</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApply" id="shiftDifferentialApplyHPTE" value="HOURLY_PT_EMPLOYEES" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApply.indexOf('HOURLY_PT_EMPLOYEES') > 0"  (change)="onShiftDifferentialApplyCheck($event);"/>
                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialApplyHPTE">Hourly PT employees</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApply" id="shiftDifferentialApplyHPDE" value="HOURLY_PER_DIEM_EMPLOYEES" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApply.indexOf('HOURLY_PER_DIEM_EMPLOYEES') > 0"  (change)="onShiftDifferentialApplyCheck($event);"/>
                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialApplyHPDE">Hourly Per Diem employees</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApply" id="shiftDifferentialApplySE" value="SALARIED_EMPLOYEES" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApply.indexOf('SALARIED_EMPLOYEES') > 0"  (change)="onShiftDifferentialApplyCheck($event);"/>
                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialApplySE">Salaried employees</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What days of the week does the shift differential apply to</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline"  *ngFor="let applyDayOfWeek of weekDays; let x=index">
                                      <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApplyDayOfWeek" [id]="'shiftDifferentialApplyDayOfWeek-'+applyDayOfWeek.id" [value]="applyDayOfWeek.id" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApplyDayOfWeek.indexOf(applyDayOfWeek.id) > 0" (change)="onShiftDifferentialApplyDayCheck($event);"/>
                                      <label class="form-check-label col-form-label-sm" [for]="'shiftDifferentialApplyDayOfWeek-'+applyDayOfWeek.id">{{applyDayOfWeek.value}}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What is the shift differential rate?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text input-group-text-radio">
                                          <input type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_HOURLY_ADD" value="HOURLY_ADD" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type"/>
                                        </div>
                                        <div class="input-group-text input-group-second-text">Hourly rate + $  </div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="Amount" name="shiftDifferentialRate_HOURLY_ADD_amount" [(ngModel)]="shiftDifferentialRate.hourly" />
                                    </div>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text input-group-text-radio">
                                          <input type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_HOURLY_PERCENT" value="HOURLY_PERCENT" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type"/>
                                        </div>
                                        <div class="input-group-text input-group-second-text">Hourly rate + % </div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="Percentage" name="shiftDifferentialRate_HOURLY_PERCENT_amount" [(ngModel)]="shiftDifferentialRate.percent" />
                                    </div>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text input-group-text-radio">
                                          <input type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_BONUS_AMOUNT" value="BONUS" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type"/>
                                        </div>
                                        <div class="input-group-text input-group-second-text">Bonus Amount: </div>
                                      </div>
                                      <input type="number" placeholder="Amount" class="form-control form-control-sm" name="shiftDifferentialRate_BONUS_AMOUNT_amount" [(ngModel)]="shiftDifferentialRate.bonus" />
                                    </div>
                                  </div>
                                </div>

<!--                                <div class="form-row">-->
<!--                                  <div class="col">-->
<!--&lt;!&ndash;                                    <div class="form-check form-check-inline">&ndash;&gt;-->
<!--&lt;!&ndash;                                      <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_HOURLY_ADD" value="HOURLY_ADD" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialRate_HOURLY_ADD">Hourly rate + $</label>&ndash;&gt;-->
<!--&lt;!&ndash;                                      <input class="form-check-input form-control-sm" type="number" name="shiftDifferentialRate_HOURLY_ADD" id="shiftDifferentialRate_HOURLY_ADD_amount" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.amount" />&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div class="form-check form-check-inline">&ndash;&gt;-->
<!--&lt;!&ndash;                                      <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_HOURLY_PERCENT" value="HOURLY_PERCENT" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type" />&ndash;&gt;-->
<!--&lt;!&ndash;                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialRate_HOURLY_ADD">Hourly rate + %</label>&ndash;&gt;-->
<!--&lt;!&ndash;                                      <input class="form-check-input form-control-sm" type="number" name="shiftDifferentialRate_HOURLY_PERCENT" id="shiftDifferentialRate_HOURLY_PERCENT_amount" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.amount" />&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                    <div class="form-check form-check-inline">-->
<!--                                      <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_BONUS_AMOUNT" value="BONUS" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type" />-->
<!--                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialRate_BONUS_AMOUNT">Bonus Amount: </label>-->
<!--                                      <input class="form-check-input form-control-sm" type="number" name="shiftDifferentialRate_BONUS_AMOUNT" id="shiftDifferentialRate_BONUS_AMOUNT_amount" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.amount" />-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Please explain anything else you’d like us to know regarding shift differential pay:</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="shiftDifferentialNotes" placeholder="Explain here"  [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialNotes" name="shiftDifferentialNotes" />
                              </div>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Do you have any employees who work for commission and/or tips?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline" style="width:70%">
                                    <input class="form-check-input form-control-sm" type="radio" name="workForCommission" id="workForCommissionYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.workForCommission"/>
                                    <label class="form-check-label col-form-label-sm" for="workForCommissionYes">Yes&nbsp;</label>
                                    <input type="text" class="form-control form-control-sm" id="workForCommissionDesc" placeholder="If yes, explain"  [(ngModel)]="hrPayrollForm.compPayInfo.workForCommissionDescription" name="workForCommissionDesc" />
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="workForCommission" id="workForCommissionNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.workForCommission" />
                                    <label class="form-check-label col-form-label-sm" for="workForCommissionNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Do employees receive paid breaks and/or lunches?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline" style="width:70%">
                                    <input class="form-check-input form-control-sm" type="radio" name="paidBreaks" id="paidBreaksYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.paidBreaks"/>
                                    <label class="form-check-label col-form-label-sm" for="paidBreaksYes">Yes&nbsp;</label>
                                    <input type="text" class="form-control form-control-sm" id="paidBreaksDesc" placeholder="If yes, explain"  [(ngModel)]="hrPayrollForm.compPayInfo.paidBreaksNotes" name="paidBreaksDesc" />
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="paidBreaks" id="paidBreaksNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.paidBreaks" />
                                    <label class="form-check-label col-form-label-sm" for="paidBreaksNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Do you have any employees who work fully remote?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline" style="width:70%">
                                    <input class="form-check-input form-control-sm" type="radio" name="fullRemoteExistance" id="fullRemoteExistanceYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.fullRemoteExistance"/>
                                    <label class="form-check-label col-form-label-sm" for="fullRemoteExistanceYes">Yes&nbsp;</label>
                                    <input type="text" class="form-control form-control-sm" id="stateRemoteWorkIn" placeholder="If yes, who and please describe the arrangement"  [(ngModel)]="hrPayrollForm.compPayInfo.stateRemoteWorkIn" name="fullRemoteExistanceDesc" />
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="fullRemoteExistance" id="fullRemoteExistanceNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.fullRemoteExistance" />
                                    <label class="form-check-label col-form-label-sm" for="fullRemoteExistanceNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">What Payroll/timekeeping system do you currently use?</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control form-control-sm" id="currentPayrollSystem" placeholder="Enter Payroll/timekeeping system"  [(ngModel)]="hrPayrollForm.compPayInfo.currentPayrollSystem" name="currentPayrollSystem" />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">What is your regular pay cycle?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="regularPayCycle" id="regularPayCycleWeekly" value="WEEKLY" [(ngModel)]="hrPayrollForm.compPayInfo.regularPayCycle"/>
                                    <label class="form-check-label col-form-label-sm" for="regularPayCycleWeekly">Weekly</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="regularPayCycle" id="regularPayCycleBiWeekly" value="BI_WEEKLY" [(ngModel)]="hrPayrollForm.compPayInfo.regularPayCycle" />
                                    <label class="form-check-label col-form-label-sm" for="regularPayCycleBiWeekly">Bi-weekly</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">What is the first day of your current pay cycle?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline" *ngFor="let weekDay of weekDays; let x=index">
                                    <input class="form-check-input form-control-sm" type="radio" id="firstDayOfPayCycle_{{x}}" [value]="weekDay.id" [(ngModel)]="hrPayrollForm.compPayInfo.firstDayOfPayCycle"/>
                                    <label class="form-check-label col-form-label-sm" for="firstDayOfPayCycle_{{x}}">{{weekDay.value}}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">What is the last day of your current pay cycle?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline" *ngFor="let weekDay of weekDays; let j=index">
                                    <input class="form-check-input form-control-sm" type="radio" name="" id="lastDayOfPayCycle_{{j}}" [value]="weekDay.id" [(ngModel)]="hrPayrollForm.compPayInfo.lastDayOfPayCycle" />
                                    <label class="form-check-label col-form-label-sm" [for]="'lastDayOfPayCycle_'+j">{{weekDay.value}}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Are benefit deductions (medical, dental, vision, etc.) taken:</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="benefitDeductions" id="benefitDeductionsBiWeekly" value="BI_WEEKLY" [(ngModel)]="hrPayrollForm.compPayInfo.benefitDeductions"/>
                                    <label class="form-check-label col-form-label-sm" for="benefitDeductionsBiWeekly">Bi-weekly</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="benefitDeductions" id="benefitDeductionsTwiceMonth" value="BI_WEEKLY" [(ngModel)]="hrPayrollForm.compPayInfo.benefitDeductions" />
                                    <label class="form-check-label col-form-label-sm" for="benefitDeductionsTwiceMonth">Twice a month</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="benefitDeductions" id="benefitDeductionsNA" value="NA" [(ngModel)]="hrPayrollForm.compPayInfo.benefitDeductions" />
                                    <label class="form-check-label col-form-label-sm" for="benefitDeductionsNA">N/A</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Do you allow employees to deduct funds out of their paycheck (bi-weekly) and add
                              to an account, as a savings, for services at the hospital?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline" style="width:70%">
                                    <input class="form-check-input form-control-sm" type="radio" name="petBenefit" id="petBenefitYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.petBenefit"/>
                                    <label class="form-check-label col-form-label-sm" for="petBenefitYes">Yes&nbsp;</label>
                                    <input type="text" class="form-control form-control-sm" id="petBenefitNote" placeholder="If yes, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.petBenefitNote" name="petBenefitDesc" />
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="petBenefit" id="petBenefitNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.petBenefit" />
                                    <label class="form-check-label col-form-label-sm" for="petBenefitNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Do any employees receive any monthly allowances such as cell phone or auto
                              allowances?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="monthlyAllowances" id="monthlyAllowancesYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.monthlyAllowances"/>
                                    <label class="form-check-label col-form-label-sm" for="monthlyAllowancesYes">Yes&nbsp;</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="monthlyAllowances" id="monthlyAllowancesNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.monthlyAllowances" />
                                    <label class="form-check-label col-form-label-sm" for="monthlyAllowancesNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">If yes, please describe the type of allowance, the amount of the allowance and who
                              receives the allowance.</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control form-control-sm" id="monthlyAllowancesNote" placeholder="If yes, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.monthlyAllowancesNote" name="monthlyAllowancesNote" />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Are there any other benefits (other than the standard medical, dental, vision, 401k)
                              you offer for your employees such as employee discounts?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline" style="width:70%">
                                    <input class="form-check-input form-control-sm" type="radio" name="otherBenefits" id="otherBenefitsYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.otherBenefits"/>
                                    <label class="form-check-label col-form-label-sm" for="otherBenefitsYes">Yes&nbsp;</label>
                                    <input type="text" class="form-control form-control-sm" id="otherBenefitsNote" placeholder="If yes, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.otherBenefitsNote" name="otherBenefitsDesc" />
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="otherBenefits" id="otherBenefitsNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.otherBenefits" />
                                    <label class="form-check-label col-form-label-sm" for="otherBenefitsNo">No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <label class="col-sm-12 col-form-label-sm row ">
                            What are your standard paid holidays and dates they are observed? For example:
                            <ul>
                              <li>New Year’s Day  (1/1) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('New Year’s Day  (1/1)')">add</button></li>
                              <li>Labor Day (9/5) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Labor Day (9/5)')">add</button></li>
                              <li>Memorial Day (5/30) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Memorial Day (5/30)')">add</button></li>
                              <li>July 4 (7/4) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('July 4 (7/4)')">add</button></li>
                              <li>Thanksgiving Day (11/24) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Thanksgiving Day (11/24)')">add</button></li>
                              <li>Post-Thanksgiving (11/25) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Post-Thanksgiving (11/25)')">add</button></li>
                              <li>Christmas eve (12/24) Christmas Day (falls on a Sunday when we are closed, so we will honor it on Monday 12/26) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Christmas eve (12/24)')">add</button></li>
                            </ul>
                          </label>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Holiday List</label>
                            <div class="col-sm-9">
                              <div *ngFor="let holiday of hrPayrollForm.compPayInfo.holidayList; let i=index">
                                <div class="form-row" style="margin-top:5px">
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="Holiday description" name = "holiday-{{i}}" [(ngModel)]="hrPayrollForm.compPayInfo.holidayList[i]"/>
                                  </div>
                                  <div class="col">
                                    <button type="submit" (click)="removeHolidayToList(holiday)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                  </div>
                                </div>
                              </div>
                              <button style="margin-top:5px" type="submit" (click)="addHolidayToList('')" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
                            </div>
                          </div>


                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">Is a standard holiday between the hours of 12:00am to 12:00pm?</label>
                            <div class="col-sm-9">
                              <div class="form-row">
                                <div class="col">
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="standardHolidayHours" id="standardHolidayHoursYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.standardHolidayHours"/>
                                    <label class="form-check-label col-form-label-sm" for="standardHolidayHoursYes">Yes&nbsp;</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input form-control-sm" type="radio" name="standardHolidayHours" id="standardHolidayHoursNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.standardHolidayHours" />
                                    <label class="form-check-label col-form-label-sm" for="standardHolidayHoursNo">No</label>
                                    <input type="text" class="form-control form-control-sm" id="standardHolidayHoursNote" placeholder="If no, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.standardHolidayHoursNote" name="standardHolidayHoursNote" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label-sm text-right">How many hours do employees receive for standard holiday pay?</label>
                            <div class="col-sm-9">
                              <input type="number" min="0" class="form-control form-control-sm" id="standardHolidayPayHours" placeholder="If yes, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.standardHolidayPayHours" name="standardHolidayPayHours" />
                            </div>
                          </div>
                        </div>



                        <div class="btn-group float-right mt-2" role="group" aria-label="Basic example">
                            <button (click)="prevForm()" class="btn btn-outline-primary">&nbsp;&lt;&lt;&nbsp;</button>
                            &nbsp;
                            <button *ngIf="hrForm.status=='IN_PROGRESS'" type="submit" [disabled]="validationFields.length>0" (click)="save()" class="btn btn-primary">Save</button>
                            &nbsp;
                            <button (click)="nextForm()" class="btn btn-outline-primary">&nbsp;&gt;&gt;&nbsp;</button>
                        </div>
                      </form>
                  </div>
              </div>
            <br/>
        </div>
    </div>
</div>
