export const environment = {
  production: true,
  isCensusConfigEnabled: true,
  apiURL: 'https://onboarding-dot-dev-companion-299315.ue.r.appspot.com',
  auth: {
    clientId: '3dbbebbc-800b-46d0-804c-84f81f55d44d', // This is your client ID
    authority: 'https://login.microsoftonline.com/4311f7b0-1d4f-429a-b2da-ec3498aded90', // This is your tenant ID
    redirectUri: 'https://onboarding.devrbcompanion.com/'// This is your redirect URI
  },
};
