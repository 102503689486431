import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {catchError, map, switchMap} from 'rxjs/operators';
import {GlobalConstants} from '../global-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {EMPTY, Observable, of} from 'rxjs';
import {AuthenticationResult} from '@azure/msal-common';
import {PopupRequest} from '@azure/msal-browser';
import {SessionTimerService} from '../session-expiration-alert/services/session-timer.service';

@Injectable({
  providedIn: 'root'
})
export class MsAuthenticationService {

  public username: string;
  public password: string;
  public isAuthenticated: string;
  public userRole: UserRole;
  public fullName: string;
  public projectId: string;
  authenticationResponse: AuthenticationResponse;

  constructor(private http: HttpClient, private router: Router, private authService: MsalService,
              private route: ActivatedRoute,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private broadcastService: MsalBroadcastService,
              private sessionTimerService: SessionTimerService) {
    this.authService.instance.handleRedirectPromise().then(res => {
      if (res != null && res.account != null) {
        this.authService.instance.setActiveAccount(res.account);
      }
    });
  }

  relogin() {
    this.login(true, () => {
    }, () => {
    });
  }

  login(relogin: boolean = false, onsuccess, onerror) {
    // Sets account as active account or first account
    let authenticationResult: Observable<AuthenticationResult>;
    let account;
    if (!!this.authService.instance.getActiveAccount()) {
      account = this.authService.instance.getActiveAccount();
    } else {
      account = this.authService.instance.getAllAccounts()[0];
    }

    if (account) {
      const authRequest = {...this.msalGuardConfig.authRequest, account};
      const scopes = ['user.read'];
      // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
      authenticationResult = this.authService.acquireTokenSilent({...authRequest, scopes, account, forceRefresh: true})
        .pipe(
          catchError(() => {
            return this.loginPopup(relogin, onsuccess);
          }),
          switchMap((result) => {
            if (!result.accessToken) {
              return this.loginPopup(relogin, onsuccess);
            }
            return of(result);
          }),
          // @ts-ignore
          switchMap((result) => {
            this.handleLogin(result.account.username, result.accessToken, result.expiresOn, relogin, onsuccess);
            return of(result);
          })
        );
    } else {
      authenticationResult = this.loginPopup(relogin, onsuccess)
    }

    return authenticationResult
      .subscribe(response => {
          console.log(response);
          onsuccess(response);
        },
        error => {
          console.log(error);
          onerror(error)
        });
  }

  loginPopup(relogin, onsuccess) {
    let authenticationResult: Observable<AuthenticationResult>;
    if (this.msalGuardConfig.authRequest) {
      authenticationResult = this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest);
    } else {
      authenticationResult = this.authService.loginPopup();
    }
    return authenticationResult.pipe(
      switchMap((result) => {
        this.handleLogin(result.account.username, result.accessToken, result.expiresOn, relogin, onsuccess);
        return of(result)
      })
    );
  }

  handleLogin(username: string, password: string, expiresOn: Date, relogin: boolean, onsuccess) {
    if (username != null && password != null) {
      this.authenticate(username, password, expiresOn).subscribe((result) => {
        onsuccess(result);
      });
    }
    return EMPTY;
  }

  authenticate(username: string, password: string, expiresOn: Date) {
    return this.http.get<AuthenticationResponse>(GlobalConstants.apiURL + `/api/authenticate`,
      {headers: {authorization: this.createBasicAuthToken(username, password)}}).pipe(map((res) => {
      this.authenticationResponse = res;
      this.isAuthenticated = 'true';
      this.username = username;
      this.password = password;
      this.userRole = res.role; // 'ADMIN'; //ADMIN or USER
      this.fullName = res.fullName;
      this.projectId = res.projectId;
      this.registerSuccessfulLogin(username, password, this.isAuthenticated, this.userRole, this.fullName, this.projectId, expiresOn);
      this.sessionTimerService.startTimer(expiresOn);
    }));
  }

  createBasicAuthToken(username: string, password: string) {
    const basicAuthToken = 'Basic ' + window.btoa(username + ':' + password);
    sessionStorage.setItem(GlobalConstants.AUTH_SESSION_TOKEN, basicAuthToken);
    return basicAuthToken;
  }

  registerSuccessfulLogin(username, password, isAuthenticated, userRole, fullName, projectId, expiresOn) {
    sessionStorage.setItem(GlobalConstants.AUTH_LOGGED_IN_USER, username);
    sessionStorage.setItem(GlobalConstants.AUTH_IS_AUTHENTICATED, isAuthenticated);
    sessionStorage.setItem(GlobalConstants.AUTH_USER_ROLE, userRole);
    sessionStorage.setItem(GlobalConstants.AUTH_USER_FULL_NAME, fullName);
    sessionStorage.setItem(GlobalConstants.AUTH_USER_PROJECT_ID, projectId);
    sessionStorage.setItem(GlobalConstants.EXPIRES_ON, expiresOn);
  }

  logout() {
    console.log('logging out ' + this.username + ' session is ' + GlobalConstants.AUTH_LOGGED_IN_USER);
    // this.authService.logout();
    sessionStorage.removeItem(GlobalConstants.AUTH_LOGGED_IN_USER);
    sessionStorage.removeItem(GlobalConstants.AUTH_IS_AUTHENTICATED);
    sessionStorage.removeItem(GlobalConstants.AUTH_USER_ROLE);
    sessionStorage.removeItem(GlobalConstants.AUTH_USER_FULL_NAME);
    sessionStorage.removeItem(GlobalConstants.AUTH_USER_PROJECT_ID);
    sessionStorage.removeItem(GlobalConstants.AUTH_SESSION_TOKEN);
    sessionStorage.removeItem(GlobalConstants.EXPIRES_ON);
    this.username = null;
    this.password = null;
    this.sessionTimerService.stopTimer();
    Promise.resolve().then(() => {
      this.router.navigate(['/login']).then(() => console.log('Routed to login page'));
    });
    this.http.get(GlobalConstants.apiURL + `/logoff`)
      .subscribe(
        response => {
          console.log(response);
        },
        error => {
          console.log(error);
        });
  }

  isUserLoggedIn() {
    if (this.username) {
      return true;
    } else {
      this.username = sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER);
      this.userRole = sessionStorage.getItem(GlobalConstants.AUTH_USER_ROLE) as UserRole;
      this.fullName = sessionStorage.getItem(GlobalConstants.AUTH_USER_FULL_NAME);
      if (this.username) return true;
    }
    return false;
  }

  getLoggedInUserName() {
    const user = sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER);
    if (user === null) return '';
    return user
  }

  isAdmin() {
    return this.userRole === UserRole.ADMIN;
  }

  getCurrentUser(): { username: string, role: UserRole, fullName: string } {
    return {
      username: this.username,
      role: this.userRole,
      fullName: this.fullName
    }
  }
}

export enum UserRole {
  // @ts-ignore
  ADMIN = 'ADMIN'
}

interface AuthenticationResponse {
  role: UserRole,
  fullName: string,
  projectId: string
}
