<app-menu></app-menu>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay}">
     <div class="modal-dialog modal-dialog-centered" role="document">
           <div class="modal-content style-modal-spinner">
             <div class="text-center">
                <div class="spinner-border style-modal-spinner-dim" role="status">
                </div>
              </div>
          </div>
     </div>
</div>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':infoModalDisplay}">
     <div class="modal-dialog modal-dialog-centered" role="document">
           <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title">{{infoModalTitle}}</h5>
                     <button type="button" class="close" aria-label="Close" (click)="hideInfoModalDisplay()"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <p>{{infoModalMessage}}</p>
                </div>
                <div class="modal-footer">
                    <br/>
                </div>
          </div>
     </div>
</div>

<div class="container mw-100 h-100">
    <div class="row h-100">
        <div class="col col-lg-2 bg-light">
            <app-usermenu></app-usermenu>
        </div>

        <div class="col col-lg-7">
          <h6 style="padding: 2px;"><a routerLink="/editproject" [queryParams]="{id: projectId}"><span class="bi bi-arrow-left"></span> Back to project</a></h6>
            <div [hidden]="!loaded" class="d-flex flex-row-reverse">
              <div class="p-2 font-weight-light"><small>Last updated on {{itForm.lastSavedOn | date : 'MM-dd-yyyy hh:mm:ss a'}}</small></div>
            </div>
              <h5 class="card-title">IT Questionnaire&nbsp;<small>{{projectName}}</small></h5>
              <div class="card" *ngIf="loaded">
                  <div class="card-body">
                      <form class="form-group">

                          <div class="alert alert-primary" role="alert" style="text-align:center;">General Information</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">IT Contact</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="contact" placeholder="Enter IT contact name or company name"  [(ngModel)]="itForm.generalInfo.contact" name="contact" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">IT Contact Phone</label>
                              <div class="col-sm-9">
                                <input type="tel" class="form-control form-control-sm" id="phone" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"  [(ngModel)]="itForm.generalInfo.phone" name="phone" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">IT Contact Email</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="email" placeholder="Enter IT contact e-mail"  [(ngModel)]="itForm.generalInfo.email" name="email" />
                              </div>
                          </div>

                          <!--End General Information-->
                          <br/>
                          <!--Begin PIMS Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Practice Management Package</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Practice Management Package</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="pimsPackage" placeholder=""  [(ngModel)]="itForm.pimsInfo.pimsPackage" name="pimsPackage" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Practice Management Product</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_avimark" value="AVIMARK" [checked]="itForm.pimsInfo.pimsProducts.indexOf('AVIMARK') > -1" (change)="onPimsProductCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_avimark">AVImark</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_cstone" value="CORNERSTONE" [checked]="itForm.pimsInfo.pimsProducts.indexOf('CORNERSTONE') > -1" (change)="onPimsProductCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_avimark">Cornerstone</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_complete" value="COMPLETE" [checked]="itForm.pimsInfo.pimsProducts.indexOf('COMPLETE') > -1" (change)="onPimsProductCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_complete">Complete</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_neo" value="NEO" [checked]="itForm.pimsInfo.pimsProducts.indexOf('NEO') > -1" (change)="onPimsProductCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_neo">Neo</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_rhapsody" value="RHAPSODY" [checked]="itForm.pimsInfo.pimsProducts.indexOf('RHAPSODY') > -1" (change)="onPimsProductCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_rhapsody">Rhapsody</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_vetspire" value="VETSPIRE" [checked]="itForm.pimsInfo.pimsProducts.indexOf('VETSPIRE') > -1" (change)="onPimsProductCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_vetspire">Vetspire</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_other" value="OTHER" [checked]="itForm.pimsInfo.pimsProducts.indexOf('OTHER') > -1" (change)="onPimsProductCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_other">Other&nbsp;</label>
                                      <input type="text" class="form-control form-control-sm" id="pimsOtherDesc" placeholder="specify"  name="pimsOtherDesc" [(ngModel)]="itForm.pimsInfo.pimsOtherDesc"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Practice Management Version</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="pimsVersion" placeholder=""  [(ngModel)]="itForm.pimsInfo.pimsVersion" name="pimsVersion" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Date of Last Update</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="pimsLastUpdatedOn" placeholder="MM/DD/YYYY"  [(ngModel)]="itForm.pimsInfo.lastUpdatedOn" name="pimsLastUpdatedOn" (blur)="validateDate($event)"/>
                                <div class="alert alert-warning" *ngIf="validationFields.indexOf('pimsLastUpdatedOn')> -1">{{errorMessageInvalidDate}}</div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Integrations</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_intg_carecredit" value="CARE_CREDIT" [checked]="itForm.pimsInfo.integrations.indexOf('CARE_CREDIT') > -1" (change)="onLabIntegrationCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_intg_carecredit">Care Credit</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_intg_scratchpay" value="SCRATCH_PAY" [checked]="itForm.pimsInfo.integrations.indexOf('SCRATCH_PAY') > -1" (change)="onLabIntegrationCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_intg_scratchpay">Scratch Pay</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_intg_wfargo" value="WELLS_FARGO" [checked]="itForm.pimsInfo.integrations.indexOf('WELLS_FARGO') > -1" (change)="onLabIntegrationCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_intg_wfargo">Wells Fargo</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_intg_petly" value="PETLY" [checked]="itForm.pimsInfo.integrations.indexOf('PETLY') > -1" (change)="onLabIntegrationCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_intg_petly">Petly</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="pims_intg_other" value="OTHER" [checked]="itForm.pimsInfo.integrations.indexOf('OTHER') > -1" (change)="onLabIntegrationCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="pims_intg_other">Other&nbsp;</label>
                                      <input type="text" class="form-control form-control-sm" id="integrationOtherDesc" placeholder="specify"  name="integrationOtherDesc" [(ngModel)]="itForm.pimsInfo.integrationOtherDesc"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Online Vendor Back-up in place</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="onlineVendorBackup" id="onlineVendorBackupYes" value="YES" [(ngModel)]="itForm.pimsInfo.onlineVendorBackup"/>
                                        <label class="form-check-label col-form-label-sm" for="onlineVendorBackupYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="onlineVendorBackup" id="onlineVendorBackupNo" value="NO" [(ngModel)]="itForm.pimsInfo.onlineVendorBackup" />
                                        <label class="form-check-label col-form-label-sm" for="onlineVendorBackupNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, which Online Vendor Back-up?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="onlineBackupVendorName" placeholder=""  [(ngModel)]="itForm.pimsInfo.onlineBackupVendorName" name="onlineBackupVendorName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How is Online Vendor Back-up monitored?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="vendorBackupMonitorDesc" placeholder=""  [(ngModel)]="itForm.pimsInfo.vendorBackupMonitorDesc" name="vendorBackupMonitorDesc" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is there a support plan in place?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="supportPlan" id="supportPlanYes" value="YES" [(ngModel)]="itForm.pimsInfo.supportPlan"/>
                                        <label class="form-check-label col-form-label-sm" for="supportPlanYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="supportPlan" id="supportPlanNo" value="NO" [(ngModel)]="itForm.pimsInfo.supportPlan" />
                                        <label class="form-check-label col-form-label-sm" for="supportPlanNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is there scanning in use?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="performScan" id="performScanYes" value="YES" [(ngModel)]="itForm.pimsInfo.performScan"/>
                                        <label class="form-check-label col-form-label-sm" for="performScanYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="performScan" id="performScanNo" value="NO" [(ngModel)]="itForm.pimsInfo.performScan" />
                                        <label class="form-check-label col-form-label-sm" for="performScanNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes to scanning, for what items?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="scanItems" placeholder="Enter comma separated list"  [(ngModel)]="itForm.pimsInfo.scanItems" name="scanItems" />
                              </div>
                          </div>

                          <!--End PIMS Information-->

                          <br/>

                          <!--Begin Software Tooling Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Practice Software Tooling</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Phone Software (ex. Weave)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="phoneProvName" placeholder=""  [(ngModel)]="itForm.swInfo.phoneProvName" name="phoneProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Texting Software (ex. Weave)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="textProvName" placeholder=""  [(ngModel)]="itForm.swInfo.textProvName" name="textProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Reminders (ex. PetDesk)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="reminderProvName" placeholder=""  [(ngModel)]="itForm.swInfo.reminderProvName" name="reminderProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Telemedicine (ex. Televet)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="telemedProvName" placeholder=""  [(ngModel)]="itForm.swInfo.telemedProvName" name="telemedProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">CCard Process Vendor (ex. Card Connect)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="ccVendorName" placeholder=""  [(ngModel)]="itForm.swInfo.ccVendorName" name="ccVendorName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Inv Procurement / Pharmacy (ex. VetCove)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="procPharmaProvName" placeholder=""  [(ngModel)]="itForm.swInfo.procPharmaProvName" name="procPharmaProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Inventory Mgmt (ex. Cubex)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="invMgmtProvName" placeholder=""  [(ngModel)]="itForm.swInfo.invMgmtProvName" name="invMgmtProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Lab System (ex. VetLab)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="labSystemProvName" placeholder=""  [(ngModel)]="itForm.swInfo.labSystemProvName" name="labSystemProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Imaging HW & SW (ex. PACS)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="imagingHwProvName" placeholder=""  [(ngModel)]="itForm.swInfo.imagingHwProvName" name="imagingHwProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Dental Digital X-Ray (ex. Progeny)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="dentalXrayProvName" placeholder=""  [(ngModel)]="itForm.swInfo.dentalXrayProvName" name="dentalXrayProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Mktg / Website / Social Media (ex. iVet360)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="mktngProvName" placeholder=""  [(ngModel)]="itForm.swInfo.mktngProvName" name="mktngProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Online Pharmacy (ex. VetSource)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="onlinePharmaProvName" placeholder=""  [(ngModel)]="itForm.swInfo.onlinePharmaProvName" name="onlinePharmaProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Insurance (ex. Trupanion)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="insuranceProvName" placeholder=""  [(ngModel)]="itForm.swInfo.insuranceProvName" name="insuranceProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Wellness (ex. Petly)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="wellnessProvName" placeholder=""  [(ngModel)]="itForm.swInfo.wellnessProvName" name="wellnessProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Online Scheduling (ex. VetStoria)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="onlineSchedulingProvName" placeholder=""  [(ngModel)]="itForm.swInfo.onlineSchedulingProvName" name="onlineSchedulingProvName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Data Back-up Vendor for Internet (ex. ATT)</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="dataBkupVendorIspName" placeholder=""  [(ngModel)]="itForm.swInfo.dataBkupVendorIspName" name="dataBkupVendorIspName" />
                              </div>
                          </div>

                          <!-- End Software Tooling Information -->

                          <br/>

                          <!--Begin Imaging and Server Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Imaging / Servers</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Any imaging in place?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="imagingAvailable" id="imagingAvailableYes" value="YES" [(ngModel)]="itForm.imagingInfo.imagingAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="imagingAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="imagingAvailable" id="imagingAvailableNo" value="NO" [(ngModel)]="itForm.imagingInfo.imagingAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="imagingAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Imaging Equipment</label>
                              <div class="col-sm-9">
                              <div *ngFor="let imagingSystem of itForm.imagingInfo.imagingSystems; let i=index">
                                      <div class="form-row" style="margin-top:10px">
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Make</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="make" name = "imagingSystemMake-{{i}}" [(ngModel)]="itForm.imagingInfo.imagingSystems[i].make"/>
                                                </div>
                                              </div>
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Model</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="model" name = "imagingSystemModel-{{i}}" [(ngModel)]="itForm.imagingInfo.imagingSystems[i].model"/>
                                                </div>
                                             </div>
                                      </div>
                                      <div class="form-row" style="margin-top:5px">
                                            <div class="col">
                                              <div class="input-group input-group-sm mb-2">
                                                <div class="input-group-prepend">
                                                  <div class="input-group-text">Serial</div>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" placeholder="serial" name = "imagingSystemSerial-{{i}}" [(ngModel)]="itForm.imagingInfo.imagingSystems[i].serialNumber"/>
                                              </div>
                                            </div>
                                            <label class="col-form-label-sm text-right text-right">Age</label>
                                            <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Years</div>
                                                  </div>
                                                  <input type="number" class="form-control form-control-sm" placeholder="" name = "imagingSystemAgeYears-{{i}}" [(ngModel)]="itForm.imagingInfo.imagingSystems[i].ageYears"/>
                                                  &nbsp;
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Months</div>
                                                  </div>
                                                  <input type="number" class="form-control form-control-sm" placeholder="" name = "imagingSystemAgeMonths-{{i}}" [(ngModel)]="itForm.imagingInfo.imagingSystems[i].ageMonths"/>
                                                </div>
                                            </div>
                                      </div>
                                      <div class="form-row" style="margin-top:5px">
                                        <div class="col">
                                            <div class="form-check form-check-inline">
                                              <label class="form-check-label col-form-label-sm" for="imagingAvailableNo">&nbsp;&nbsp;Modality&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="checkbox" id="img_mod_xr" value="XR" [checked]="itForm.imagingInfo.imagingSystems[i].modalityTypes.indexOf('XR') > -1" (change)="onCheckBoxEvent(itForm.imagingInfo.imagingSystems[i].modalityTypes, $event)" />
                                              <label class="form-check-label col-form-label-sm" for="img_mod_xr">XR&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="checkbox" id="img_mod_ct" value="CT" [checked]="itForm.imagingInfo.imagingSystems[i].modalityTypes.indexOf('CT') > -1" (change)="onCheckBoxEvent(itForm.imagingInfo.imagingSystems[i].modalityTypes, $event)" />
                                              <label class="form-check-label col-form-label-sm" for="img_mod_ct">CT&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="checkbox" id="img_mod_mr" value="MR" [checked]="itForm.imagingInfo.imagingSystems[i].modalityTypes.indexOf('MR') > -1" (change)="onCheckBoxEvent(itForm.imagingInfo.imagingSystems[i].modalityTypes, $event)" />
                                              <label class="form-check-label col-form-label-sm" for="img_mod_mr">MR&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="checkbox" id="img_mod_us" value="US" [checked]="itForm.imagingInfo.imagingSystems[i].modalityTypes.indexOf('US') > -1" (change)="onCheckBoxEvent(itForm.imagingInfo.imagingSystems[i].modalityTypes, $event)" />
                                              <label class="form-check-label col-form-label-sm" for="img_mod_us">US&nbsp;&nbsp;</label>
                                            </div>
                                        </div>
                                        <div class="col text-right">
                                            <button type="submit" (click)="removeImagingSystem(imagingSystem)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                        </div>
                                      </div>
                                      <hr/>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addImagingSystem()" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is Imaging supported?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="imagingSupported" id="imagingSupportedYes" value="YES" [(ngModel)]="itForm.imagingInfo.imagingSupported"/>
                                        <label class="form-check-label col-form-label-sm" for="imagingSupportedYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="imagingSupported" id="imagingSupportedNo" value="NO" [(ngModel)]="itForm.imagingInfo.imagingSupported" />
                                        <label class="form-check-label col-form-label-sm" for="imagingSupportedNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, by who?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="imagingSupportVendor" placeholder=""  [(ngModel)]="itForm.imagingInfo.imagingSupportVendor" name="imagingSupportVendor" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">PACs server in Use</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="usePacsServer" id="usePacsServerYes" value="YES" [(ngModel)]="itForm.imagingInfo.usePacsServer"/>
                                        <label class="form-check-label col-form-label-sm" for="usePacsServerYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="usePacsServer" id="usePacsServerNo" value="NO" [(ngModel)]="itForm.imagingInfo.usePacsServer" />
                                        <label class="form-check-label col-form-label-sm" for="usePacsServerNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, which one?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="pacsServerName" placeholder=""  [(ngModel)]="itForm.imagingInfo.pacsServerName" name="pacsServerName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, Onsite or Online?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="pacsServerSite" id="pacsServerSiteOnsite" value="ONSITE" [(ngModel)]="itForm.imagingInfo.pacsServerSite"/>
                                        <label class="form-check-label col-form-label-sm" for="pacsServerSiteOnsite">Onsite</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="pacsServerSite" id="pacsServerSiteOnline" value="ONLINE" [(ngModel)]="itForm.imagingInfo.pacsServerSite" />
                                        <label class="form-check-label col-form-label-sm" for="pacsServerSiteOnline">Online</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If system is onsite how is it backed up?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="onsitePacsServerBackupDesc" placeholder=""  [(ngModel)]="itForm.imagingInfo.onsitePacsServerBackupDesc" name="onsitePacsServerBackupDesc" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Age of onsite PACs server unit?</label>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Years</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="onsitePacsServerAgeYears" placeholder=""  [(ngModel)]="itForm.imagingInfo.onsitePacsServerAgeYears" name="onsitePacsServerAgeYears" />
                                  &nbsp;
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Months</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="onsitePacsServerAgeMonths" placeholder=""  [(ngModel)]="itForm.imagingInfo.onsitePacsServerAgeMonths" name="onsitePacsServerAgeMonths" />
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is onsite server supported or warrantied?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="serverSupported" id="serverSupportedYes" value="YES" [(ngModel)]="itForm.imagingInfo.serverSupported"/>
                                        <label class="form-check-label col-form-label-sm" for="serverSupportedYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="serverSupported" id="serverSupportedNo" value="NO" [(ngModel)]="itForm.imagingInfo.serverSupported" />
                                        <label class="form-check-label col-form-label-sm" for="serverSupportedNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, by who?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="serverSupportCompanyName" placeholder=""  [(ngModel)]="itForm.imagingInfo.serverSupportCompanyName" name="serverSupportCompanyName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is it integrated with PIMS?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="serverPimsIntegration" id="serverPimsIntegrationYes" value="YES" [(ngModel)]="itForm.imagingInfo.serverPimsIntegration"/>
                                        <label class="form-check-label col-form-label-sm" for="serverPimsIntegrationYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="serverPimsIntegration" id="serverPimsIntegrationNo" value="NO" [(ngModel)]="itForm.imagingInfo.serverPimsIntegration" />
                                        <label class="form-check-label col-form-label-sm" for="serverPimsIntegrationNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, how it is integrated?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="serverPimsIntDesc" placeholder=""  [(ngModel)]="itForm.imagingInfo.serverPimsIntDesc" name="serverPimsIntDesc" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Where is the onsite PACS server located?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="onsiteServerLocation" placeholder=""  [(ngModel)]="itForm.imagingInfo.onsiteServerLocation" name="onsiteServerLocation" />
                              </div>
                          </div>

                          <!--End Imaging and Server Information-->

                          <br/>

                          <!--Begin Network Infrastructure Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Network / Infrastructure</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have Switch(s)?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="switchesAvailable" id="switchesAvailableYes" value="YES" [(ngModel)]="itForm.networkInfo.switchesAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="serverPimsIntegrationYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="switchesAvailable" id="switchesAvailableNo" value="NO" [(ngModel)]="itForm.networkInfo.switchesAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="serverPimsIntegrationNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes to Switches, add each Switch detail</label>
                              <div class="col-sm-9">
                              <div *ngFor="let switch of itForm.networkInfo.switches; let i=index">
                                      <div class="form-row" style="margin-top:10px">
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Make</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="make" name = "switchMake-{{i}}" [(ngModel)]="itForm.networkInfo.switches[i].make"/>
                                                </div>
                                              </div>
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Model</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="model" name = "switchModel-{{i}}" [(ngModel)]="itForm.networkInfo.switches[i].model"/>
                                                </div>
                                              </div>
                                      </div>
                                      <div class="form-row" style="margin-top:5px">
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Location</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="location" name = "switchLocation-{{i}}" [(ngModel)]="itForm.networkInfo.switches[i].location"/>
                                                </div>
                                              </div>
                                              <label class="col-form-label-sm text-right">Age</label>
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Years</div>
                                                  </div>
                                                  <input type="number" class="form-control form-control-sm" placeholder="" name = "switchAgeYears-{{i}}" [(ngModel)]="itForm.networkInfo.switches[i].ageYears"/>
                                                  &nbsp;
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Months</div>
                                                  </div>
                                                  <input type="number" class="form-control form-control-sm" placeholder="" name = "switchAgeMonths-{{i}}" [(ngModel)]="itForm.networkInfo.switches[i].ageMonths"/>
                                                </div>
                                              </div>
                                      </div>
                                      <div class="form-row" style="margin-top:5px">
                                            <div class="col">
                                              <div class="form-check form-check-inline">
                                                <label class="form-check-label col-form-label-sm" for="imagingAvailableNo">&nbsp;UPS&nbsp;Backup&nbsp;&nbsp;</label>
                                                <input class="form-check-input form-control-sm" type="radio" name="switchUpsBackup-{{i}}" id="switchUpsBackupYes-{{i}}" value="YES" [(ngModel)]="itForm.networkInfo.switches[i].upsBackup"/>
                                                <label class="form-check-label col-form-label-sm" for="switchUpsBackupYes-{{i}}">Yes&nbsp;&nbsp;</label>
                                                <input class="form-check-input form-control-sm" type="radio" name="switchUpsBackup-{{i}}" id="switchUpsBackupNo-{{i}}" value="NO" [(ngModel)]="itForm.networkInfo.switches[i].upsBackup" />
                                                <label class="form-check-label col-form-label-sm" for="switchUpsBackupNo-{{i}}">No</label>
                                              </div>
                                            </div>
                                            <div class="col text-right">
                                              <button type="submit" (click)="removeSwitch(switch)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                            </div>
                                      </div>
                                      <hr/>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addSwitch()" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have Router(s)?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="routersAvailable" id="routersAvailableYes" value="YES" [(ngModel)]="itForm.networkInfo.routersAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="routersAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="routersAvailable" id="routersAvailableNo" value="NO" [(ngModel)]="itForm.networkInfo.routersAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="routersAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes to Routers, add each Router detail</label>
                              <div class="col-sm-9">
                              <div *ngFor="let router of itForm.networkInfo.routers; let i=index">
                                        <div class="form-row" style="margin-top:10px">
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Make</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="make" name = "routerMake-{{i}}" [(ngModel)]="itForm.networkInfo.routers[i].make"/>
                                                </div>
                                              </div>
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Model</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="model" name = "routerModel-{{i}}" [(ngModel)]="itForm.networkInfo.routers[i].model"/>
                                                </div>
                                              </div>
                                        </div>
                                        <div class="form-row" style="margin-top:5px">
                                          <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Location</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="location" name = "routerLocation-{{i}}" [(ngModel)]="itForm.networkInfo.routers[i].location"/>
                                                </div>
                                              </div>
                                              <label class="col-form-label-sm text-right">Age</label>
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Years</div>
                                                  </div>
                                                  <input type="number" class="form-control form-control-sm" placeholder="" name = "routerAgeYears-{{i}}" [(ngModel)]="itForm.networkInfo.routers[i].ageYears"/>
                                                  &nbsp;
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Months</div>
                                                  </div>
                                                  <input type="number" class="form-control form-control-sm" placeholder="" name = "routerAgeMonths-{{i}}" [(ngModel)]="itForm.networkInfo.routers[i].ageMonths"/>
                                                </div>
                                              </div>
                                      </div>
                                      <div class="form-row" style="margin-top:5px">
                                              <div class="col">
                                                <div class="form-check form-check-inline">
                                                  <label class="form-check-label col-form-label-sm" for="imagingAvailableNo">&nbsp;UPS&nbsp;Backup&nbsp;&nbsp;</label>
                                                  <input class="form-check-input form-control-sm" type="radio" name="routerUpsBackup-{{i}}" id="routerUpsBackupYes-{{i}}" value="YES" [(ngModel)]="itForm.networkInfo.routers[i].upsBackup"/>
                                                  <label class="form-check-label col-form-label-sm" for="routerUpsBackupYes-{{i}}">Yes&nbsp;&nbsp;</label>
                                                  <input class="form-check-input form-control-sm" type="radio" name="routerUpsBackup-{{i}}" id="routerUpsBackupNo-{{i}}" value="NO" [(ngModel)]="itForm.networkInfo.routers[i].upsBackup" />
                                                  <label class="form-check-label col-form-label-sm" for="routerUpsBackupNo-{{i}}">No</label>
                                                </div>
                                              </div>
                                              <div class="col text-right">
                                                <button type="submit" (click)="removeRouter(router)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                              </div>
                                      </div>
                                      <hr/>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addRouter()" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have Wireless Internet?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="wirelessInternetAvailable" id="wirelessInternetAvailableYes" value="YES" [(ngModel)]="itForm.networkInfo.wirelessInternetAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="wirelessInternetAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="wirelessInternetAvailable" id="wirelessInternetAvailableNo" value="NO" [(ngModel)]="itForm.networkInfo.wirelessInternetAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="wirelessInternetAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes to Wireless, add each Modem detail</label>
                              <div class="col-sm-9">
                              <div *ngFor="let modem of itForm.networkInfo.modems; let i=index">
                                      <div class="form-row" style="margin-top:10px">
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Make</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="make" name = "modemMake-{{i}}" [(ngModel)]="itForm.networkInfo.modems[i].make"/>
                                                </div>
                                              </div>
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Model</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="model" name = "modemModel-{{i}}" [(ngModel)]="itForm.networkInfo.modems[i].model"/>
                                                </div>
                                              </div>
                                      </div>
                                      <div class="form-row" style="margin-top:5px">
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Location</div>
                                                  </div>
                                                  <input type="text" class="form-control form-control-sm" placeholder="serial" name = "modemLocation-{{i}}" [(ngModel)]="itForm.networkInfo.modems[i].location"/>
                                                </div>
                                              </div>
                                              <label class="col-form-label-sm text-right">Age</label>
                                              <div class="col">
                                                <div class="input-group input-group-sm mb-2">
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Years</div>
                                                  </div>
                                                  <input type="number" class="form-control form-control-sm" placeholder="" name = "modemAgeYears-{{i}}" [(ngModel)]="itForm.networkInfo.modems[i].ageYears"/>
                                                  &nbsp;
                                                  <div class="input-group-prepend">
                                                    <div class="input-group-text">Months</div>
                                                  </div>
                                                  <input type="number" class="form-control form-control-sm" placeholder="" name = "modemAgeMonths-{{i}}" [(ngModel)]="itForm.networkInfo.modems[i].ageMonths"/>
                                                </div>
                                              </div>
                                      </div>
                                      <div class="form-row" style="margin-top:5px">
                                              <div class="col">
                                                <div class="form-check form-check-inline">
                                                  <label class="form-check-label col-form-label-sm" for="imagingAvailableNo">&nbsp;UPS&nbsp;Backup&nbsp;&nbsp;</label>
                                                  <input class="form-check-input form-control-sm" type="radio" name="modemUpsBackup-{{i}}" id="modemUpsBackupYes-{{i}}" value="YES" [(ngModel)]="itForm.networkInfo.modems[i].upsBackup"/>
                                                  <label class="form-check-label col-form-label-sm" for="modemUpsBackupYes-{{i}}">Yes&nbsp;&nbsp;</label>
                                                  <input class="form-check-input form-control-sm" type="radio" name="modemUpsBackup-{{i}}" id="modemUpsBackupNo-{{i}}" value="NO" [(ngModel)]="itForm.networkInfo.modems[i].upsBackup" />
                                                  <label class="form-check-label col-form-label-sm" for="modemUpsBackupNo-{{i}}">No</label>
                                                </div>
                                              </div>
                                              <div class="col text-right">
                                                <button type="submit" (click)="removeModem(modem)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                              </div>
                                      </div>
                                      <hr/>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addModem()" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is there a server closet?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="serverClosetAvailable" id="serverClosetAvailableYes" value="YES" [(ngModel)]="itForm.networkInfo.serverClosetAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="serverClosetAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="serverClosetAvailable" id="serverClosetAvailableNo" value="NO" [(ngModel)]="itForm.networkInfo.serverClosetAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="serverClosetAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Any patch panels?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="patchPanelAvailable" id="patchPanelAvailableYes" value="YES" [(ngModel)]="itForm.networkInfo.patchPanelAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="patchPanelAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="patchPanelAvailable" id="patchPanelAvailableNo" value="NO" [(ngModel)]="itForm.networkInfo.patchPanelAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="patchPanelAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Expansion Room</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="expansionRoomAvailable" id="expansionRoomAvailableYes" value="YES" [(ngModel)]="itForm.networkInfo.expansionRoomAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="expansionRoomAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="expansionRoomAvailable" id="expansionRoomAvailableNo" value="NO" [(ngModel)]="itForm.networkInfo.expansionRoomAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="expansionRoomAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Ventilation</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ventilationAvailable" id="ventilationAvailableYes" value="YES" [(ngModel)]="itForm.networkInfo.ventilationAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="ventilationAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ventilationAvailable" id="ventilationAvailableNo" value="NO" [(ngModel)]="itForm.networkInfo.ventilationAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="ventilationAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Heat / Cooling</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="coolingAvailable" id="coolingAvailableYes" value="YES" [(ngModel)]="itForm.networkInfo.coolingAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="coolingAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="coolingAvailable" id="coolingAvailableNo" value="NO" [(ngModel)]="itForm.networkInfo.coolingAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="coolingAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Notes</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="notes" placeholder=""  [(ngModel)]="itForm.imagingInfo.notes" name="notes" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Generator in Use</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="generatorAvailable" id="generatorAvailableYes" value="YES" [(ngModel)]="itForm.networkInfo.generatorAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="generatorAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="generatorAvailable" id="generatorAvailableNo" value="NO" [(ngModel)]="itForm.networkInfo.generatorAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="generatorAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes to Generator, Make/Model</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="generatorMakeModel" placeholder=""  [(ngModel)]="itForm.imagingInfo.generatorMakeModel" name="generatorMakeModel" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes to Generator, is it serviced?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="generatorServiced" id="generatorServicedYes" value="YES" [(ngModel)]="itForm.networkInfo.generatorServiced"/>
                                        <label class="form-check-label col-form-label-sm" for="generatorServicedYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="generatorServiced" id="generatorServicedNo" value="NO" [(ngModel)]="itForm.networkInfo.generatorServiced" />
                                        <label class="form-check-label col-form-label-sm" for="generatorServicedNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If it is, serviced by who?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="generatorServiceContact" placeholder=""  [(ngModel)]="itForm.imagingInfo.generatorServiceContact" name="generatorServiceContact" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Date of last maintenance</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="generatorLastServicedOn" placeholder="MM/DD/YYYY"  [(ngModel)]="itForm.imagingInfo.lastServicedOn" name="generatorLastServicedOn" (blur)="validateDate($event)"/>
                                <div class="alert alert-warning" *ngIf="validationFields.indexOf('generatorLastServicedOn')> -1">{{errorMessageInvalidDate}}</div>
                              </div>
                          </div>

                          <!--End Network Infrastructure-->
                          <br/>

                          <!--Begin Utilities Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Utilities</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Backup Software, Make, Serial and/or License #</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="backupSwInfo" placeholder=""  [(ngModel)]="itForm.utilityInfo.backupSwInfo" name="backupSwInfo" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Backup Destination</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="bkdest_local" value="LOCAL" [checked]="itForm.utilityInfo.backupLocations.indexOf('LOCAL') > -1" (change)="onBkUpDestCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="bkdest_local">Local</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="bkdest_cloud" value="CLOUD" [checked]="itForm.utilityInfo.backupLocations.indexOf('CLOUD') > -1" (change)="onBkUpDestCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="bkdest_cloud">Cloud</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="bkdest_other" value="OTHER" [checked]="itForm.utilityInfo.backupLocations.indexOf('OTHER') > -1" (change)="onBkUpDestCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="bkdest_other">Other&nbsp;</label>
                                      <input type="text" class="form-control form-control-sm" id="backupDestOtherDesc" placeholder="specify"  name="backupDestOtherDesc" [(ngModel)]="itForm.utilityInfo.backupDestOtherDesc"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">When was it last verified?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="backupVerifiedOn" placeholder="MM/DD/YYYY"  [(ngModel)]="itForm.utilityInfo.backupVerifiedOn" name="backupVerifiedOn" (blur)="validateDate($event)"/>
                                <div class="alert alert-warning" *ngIf="validationFields.indexOf('backupVerifiedOn')> -1">{{errorMessageInvalidDate}}</div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What monitoring is in place?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="backupMonitoringDesc" placeholder=""  [(ngModel)]="itForm.utilityInfo.backupMonitoringDesc" name="backupMonitoringDesc" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Support or warranty?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="backupSupport" id="backupSupportSup" value="SUPPORT" [(ngModel)]="itForm.utilityInfo.backupSupport"/>
                                        <label class="form-check-label col-form-label-sm" for="backupSupportSup">Support</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="backupSupport" id="backupSupportWar" value="WARRANTY" [(ngModel)]="itForm.utilityInfo.backupSupport" />
                                        <label class="form-check-label col-form-label-sm" for="backupSupportWar">Warranty</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, by who?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="backupSupWarContact" placeholder="Enter support or warranty contact"  [(ngModel)]="itForm.utilityInfo.backupSupWarContact" name="backupSupWarContact" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What specifically gets backed up?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="backupContentList" placeholder="Enter comma separated list of items"  [(ngModel)]="itForm.utilityInfo.backupContentList" name="backupContentList" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Anti-Virus Software Make, Serial, License</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="antiVirusSwInfo" placeholder=""  [(ngModel)]="itForm.utilityInfo.antiVirusSwInfo" name="antiVirusSwInfo" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">When was it last verified?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="avSwVerifiedOn" placeholder="MM/DD/YYYY"  [(ngModel)]="itForm.utilityInfo.avSwVerifiedOn" name="avSwVerifiedOn" (blur)="validateDate($event)"/>
                                <div class="alert alert-warning" *ngIf="validationFields.indexOf('avSwVerifiedOn')> -1">{{errorMessageInvalidDate}}</div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What monitoring is in place?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="avMonitoringDesc" placeholder=""  [(ngModel)]="itForm.utilityInfo.avMonitoringDesc" name="avMonitoringDesc" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Support or warranty?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="avSupport" id="avSupportSup" value="SUPPORT" [(ngModel)]="itForm.utilityInfo.avSupport"/>
                                        <label class="form-check-label col-form-label-sm" for="avSupportSup">Support</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="avSupport" id="avSupportWar" value="WARRANTY" [(ngModel)]="itForm.utilityInfo.avSupport" />
                                        <label class="form-check-label col-form-label-sm" for="avSupportWar">Warranty</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, by who?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="avSupWarContact" placeholder="Enter support or warranty contact"  [(ngModel)]="itForm.utilityInfo.avSupWarContact" name="avSupWarContact" />
                              </div>
                          </div>

                          <!--End Utilities Information-->

                          <br/>

                          <!--Begin Remote Access Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Remote Access</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Connection Methods</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="remoteAccess_vpn" value="VPN" [checked]="itForm.remoteAccessInfo.connectionMethods.indexOf('VPN') > -1" (change)="onRemoteAccCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="remoteAccess_vpn">VPN</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="remoteAccess_svc" value="SERVICE" [checked]="itForm.remoteAccessInfo.connectionMethods.indexOf('SERVICE') > -1" (change)="onRemoteAccCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="remoteAccess_svc">Service</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="remoteAccess_other" value="OTHER" [checked]="itForm.remoteAccessInfo.connectionMethods.indexOf('OTHER') > -1" (change)="onRemoteAccCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="remoteAccess_other">Other&nbsp;</label>
                                      <input type="text" class="form-control form-control-sm" id="remoteAccessMethodOtherDesc" placeholder="specify"  name="remoteAccessMethodOtherDesc" [(ngModel)]="itForm.remoteAccessInfo.remoteAccessMethodOtherDesc"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How is it monitored?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="monitoringDesc" placeholder=""  [(ngModel)]="itForm.remoteAccessInfo.monitoringDesc" name="monitoringDesc" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How is it Controlled?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="controlDesc" placeholder=""  [(ngModel)]="itForm.remoteAccessInfo.controlDesc" name="controlDesc" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Who has access?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="userAccessList" placeholder="Enter commma separated for multiple users"  [(ngModel)]="itForm.remoteAccessInfo.userAccessList" name="userAccessList" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Any issues</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="problemDesc" placeholder=""  [(ngModel)]="itForm.remoteAccessInfo.problemDesc" name="problemDesc" />
                              </div>
                          </div>

                          <!--End Remote Access Information-->

                          <br/>

                          <!--Begin Phone System Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Phone System</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Make / Model</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="phoneMakeModel" placeholder=""  [(ngModel)]="itForm.phoneInfo.makeModel" name="phoneMakeModel" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Location</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="phoneLocation" placeholder=""  [(ngModel)]="itForm.phoneInfo.location" name="phoneLocation" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Number of Phones</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="phoneCount" placeholder=""  [(ngModel)]="itForm.phoneInfo.phoneCount" name="phoneCount" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Supported</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="phoneSupport" id="phoneSupportYes" value="YES" [(ngModel)]="itForm.phoneInfo.support"/>
                                        <label class="form-check-label col-form-label-sm" for="phoneSupportYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="phoneSupport" id="phoneSupportNo" value="NO" [(ngModel)]="itForm.phoneInfo.support" />
                                        <label class="form-check-label col-form-label-sm" for="phoneSupportNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If supported, by who?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="phoneSupportContact" placeholder=""  [(ngModel)]="itForm.phoneInfo.supportContact" name="phoneSupportContact" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">When was it last serviced / maintained?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="phoneLastServicedOn" placeholder="MM/DD/YYYY"  [(ngModel)]="itForm.phoneInfo.lastServicedOn" name="phoneLastServicedOn" (blur)="validateDate($event)"/>
                                <div class="alert alert-warning" *ngIf="validationFields.indexOf('phoneLastServicedOn')> -1">{{errorMessageInvalidDate}}</div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Location of handsets / server</label>
                              <div class="col-sm-9">
                                <div *ngFor="let handsetLocation of itForm.phoneInfo.handsetLocations; let i=index">
                                    <div class="form-row" style="margin-top:5px">
                                      <div class="col">
                                        <input type="text" class="form-control form-control-sm" placeholder="enter location" name = "handsetLocation-{{i}}" [(ngModel)]="itForm.phoneInfo.handsetLocations[i].location"/>
                                      </div>
                                      <div class="col">
                                        <button type="submit" (click)="removeHandsetLocation(handsetLocation)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                      </div>
                                    </div>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addHandsetLocation()" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Any issues</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="phoneProblemDesc" placeholder=""  [(ngModel)]="itForm.phoneInfo.problemDesc" name="phoneProblemDesc" />
                              </div>
                          </div>

                          <!--End Phone System Information-->

                          <br/>

                          <!--Begin Video Monitoring -->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Video Monitoring / Surveillance</div>

                          <div class="form-group row">
                              <div class="col-sm-12">
                              <label class="col-form-label-sm"> If available, provide information for each unit&nbsp;&nbsp;</label>
                              <div *ngFor="let videoDetail of itForm.videoInfo.videoDetails; let i=index">

                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Make</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="make" name = "videoMake-{{i}}" [(ngModel)]="itForm.videoInfo.videoDetails[i].make"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Model</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="model" name = "videoModel-{{i}}" [(ngModel)]="itForm.videoInfo.videoDetails[i].model"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Location</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="location" name = "videoLocation-{{i}}" [(ngModel)]="itForm.videoInfo.videoDetails[i].location"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Supported</label>
                                          <div class="col-sm-9">
                                            <div class="form-check form-check-inline">
                                              <label class="form-check-label col-form-label-sm" for="videoIsSupportedYes">&nbsp;Supported&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="videoIsSupported-{{i}}" id="videoIsSupportedYes-{{i}}" value="YES" [(ngModel)]="itForm.videoInfo.videoDetails[i].isSupported"/>
                                              <label class="form-check-label col-form-label-sm" for="videoIsSupportedYes-{{i}}">Yes&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="videoIsSupported-{{i}}" id="videoIsSupportedNo-{{i}}" value="NO" [(ngModel)]="itForm.videoInfo.videoDetails[i].isSupported" />
                                              <label class="form-check-label col-form-label-sm" for="videoIsSupportedNo-{{i}}">No</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If supported, by who?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="Enter name and contact" name = "supWarContact-{{i}}" [(ngModel)]="itForm.videoInfo.videoDetails[i].supWarContact"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">When was it last Serviced / Maintained?</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="MM/DD/YYYY" name = "videoLastServicedOn-{{i}}" [(ngModel)]="itForm.videoInfo.videoDetails[i].lastServicedOn" (blur)="validateDate($event)"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Any issues</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "videoProblemDesc-{{i}}" [(ngModel)]="itForm.videoInfo.videoDetails[i].problemDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <div class="col text-right">
                                            <button type="submit" (click)="removeVideoDetail(videoDetail)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                          </div>
                                        </div>
                                        <hr/>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addVideoDetail()" class="btn btn-outline-primary btn-sm">&nbsp;add video surveillance&nbsp;</button>
                              </div>
                          </div>

                          <!--End Video Monitoring Info -->

                          <br/>

                          <!--Begin Miscellaneous Info -->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Miscellaneous</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Any ongoing issues with any systems</label>
                              <div class="col-sm-9">
                                <textarea class="form-control" rows="3" id="systemProblemDesc" placeholder=""  [(ngModel)]="itForm.miscInfo.problemDesc" name="systemProblemDesc"></textarea>
                              </div>
                          </div>
                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Notes</label>
                              <div class="col-sm-9">
                                <textarea class="form-control" rows="3" id="overallNotes" placeholder=""  [(ngModel)]="itForm.miscInfo.notes" name="overallNotes"></textarea>
                              </div>
                          </div>

                          <!--End Miscellaneous Info -->

                          <br/>

                          <!--Begin Miscellaneous Info -->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Internet Connection</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">ISP</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ispAvailable" id="ispAvailableYes" value="YES" [(ngModel)]="itForm.internetInfo.ispAvailable"/>
                                        <label class="form-check-label col-form-label-sm" for="ispAvailableYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ispAvailable" id="ispAvailableNo" value="NO" [(ngModel)]="itForm.internetInfo.ispAvailable" />
                                        <label class="form-check-label col-form-label-sm" for="ispAvailableNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Speed</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="speed" placeholder=""  [(ngModel)]="itForm.internetInfo.speed" name="speed" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Technology</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ispTechnology" id="ispTechCable" value="CABLE" [(ngModel)]="itForm.internetInfo.ispTechnology"/>
                                        <label class="form-check-label col-form-label-sm" for="ispTechCable">Cable</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ispTechnology" id="ispTechDsl" value="DSL" [(ngModel)]="itForm.internetInfo.ispTechnology"/>
                                        <label class="form-check-label col-form-label-sm" for="ispTechDsl">DSL</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ispTechnology" id="ispTechFiber" value="FIBER" [(ngModel)]="itForm.internetInfo.ispTechnology"/>
                                        <label class="form-check-label col-form-label-sm" for="ispTechFiber">Fiber</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ispTechnology" id="ispTechOther" value="OTHER" [(ngModel)]="itForm.internetInfo.ispTechnology"/>
                                        <label class="form-check-label col-form-label-sm" for="ispTechOther">Other&nbsp;</label>
                                        <input type="text" class="form-control form-control-sm" id="ispTechOtherDesc" placeholder="specify"  [(ngModel)]="itForm.internetInfo.ispTechOtherDesc" name="ispTechOtherDesc" />
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Location in</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="ispLocation" placeholder=""  [(ngModel)]="itForm.internetInfo.location" name="location" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Supported</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ispIsSupported" id="ispIsSupportedYes" value="YES" [(ngModel)]="itForm.internetInfo.isSupported"/>
                                        <label class="form-check-label col-form-label-sm" for="ispIsSupportedYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="ispIsSupported" id="ispIsSupportedNo" value="NO" [(ngModel)]="itForm.internetInfo.isSupported" />
                                        <label class="form-check-label col-form-label-sm" for="ispIsSupportedNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Connection Support</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="isIspConnectionSupport" id="isIspConnectionSupportYes" value="YES" [(ngModel)]="itForm.internetInfo.isConnectionSupport"/>
                                        <label class="form-check-label col-form-label-sm" for="isIspConnectionSupportYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="isIspConnectionSupport" id="isIspConnectionSupportNo" value="NO" [(ngModel)]="itForm.internetInfo.isConnectionSupport" />
                                        <label class="form-check-label col-form-label-sm" for="isIspConnectionSupportNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">When was it last maintained?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="internetLastServicedOn" placeholder="MM/DD/YYYY"  [(ngModel)]="itForm.internetInfo.lastServicedOn" name="internetLastServicedOn" (blur)="validateDate($event)"/>
                                <div class="alert alert-warning" *ngIf="validationFields.indexOf('internetLastServicedOn')> -1">{{errorMessageInvalidDate}}</div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Any issues</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="ispProblemDesc" placeholder=""  [(ngModel)]="itForm.internetInfo.problemDesc" name="ispProblemDesc" />
                              </div>
                          </div>

                          <!--End Miscellaneous Info -->

                          <br/>

                          <!--Begin Server Info -->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Server(s)</div>

                          <div class="form-group row">
                              <div class="col-sm-12">
                              <label class="col-form-label-sm"> If available, provide information for each unit&nbsp;&nbsp;</label>
                              <div *ngFor="let server of itForm.serverInfo.servers; let i=index">
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Purpose of Server</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="Enter purpose of server" name = "serverFunction-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].function"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Make</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="make" name = "serverMake-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].make"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Model</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="model" name = "serverModel-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].model"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Serial</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="serial" name = "serverSerial-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].serial"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Age</label>
                                          <div class="col-sm-4">
                                            <div class="input-group input-group-sm mb-2">
                                              <div class="input-group-prepend">
                                                <div class="input-group-text">Years</div>
                                              </div>
                                              <input type="number" class="form-control form-control-sm" placeholder="" name = "serverAgeYears-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].ageYears"/>
                                              &nbsp;
                                              <div class="input-group-prepend">
                                                <div class="input-group-text">Months</div>
                                              </div>
                                              <input type="number" class="form-control form-control-sm" placeholder="" name = "serverAgeMonths-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].ageMonths"/>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Operating System</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="operating system" name = "serverOs-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].operatingSystem"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Warranty or Supported</label>
                                          <div class="col-sm-9">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="serverSupportType-{{i}}" id="serverSupportTypeSup-{{i}}" value="SUPPORT" [(ngModel)]="itForm.serverInfo.servers[i].supportType"/>
                                              <label class="form-check-label col-form-label-sm" for="serverSupportTypeSup-{{i}}">Support&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="serverSupportType-{{i}}" id="serverSupportTypeWar-{{i}}" value="WARRANTY" [(ngModel)]="itForm.serverInfo.servers[i].supportType" />
                                              <label class="form-check-label col-form-label-sm" for="serverSupportTypeWar-{{i}}">Warranty</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If supported or warrantied, by who?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="name and contact" name = "serverSupWarContact-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].supWarContact"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Backed-Up</label>
                                          <div class="col-sm-9">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="serverBackupAvailable-{{i}}" id="serverBackupAvailableYes-{{i}}" value="YES" [(ngModel)]="itForm.serverInfo.servers[i].backupAvailable"/>
                                              <label class="form-check-label col-form-label-sm" for="serverBackupAvailableYes-{{i}}">Yes&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="serverBackupAvailable-{{i}}" id="serverBackupAvailableNo-{{i}}" value="NO" [(ngModel)]="itForm.serverInfo.servers[i].backupAvailable" />
                                              <label class="form-check-label col-form-label-sm" for="serverBackupAvailableNo-{{i}}">No</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If backed-up, what is the method?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "serverBackupDesc-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].backupDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If backed-up, how is it monitored?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "serverBackupMonitorDesc-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].backupMonitorDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If backed-up, how is it verified?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "serverBackupVerificationDesc-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].backupVerificationDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">When was is last updated?</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="MM/DD/YYYY" name = "serverLastServicedOn-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].lastServicedOn" (blur)="validateDate($event)"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Any current or ongoing issues</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "serverProblemDesc-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].problemDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">UPS</label>
                                          <div class="col-sm-9">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="serverUpsBackup-{{i}}" id="serverUpsBackupYes-{{i}}" value="YES" [(ngModel)]="itForm.serverInfo.servers[i].upsBackup"/>
                                              <label class="form-check-label col-form-label-sm" for="serverUpsBackupYes-{{i}}">Yes&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="serverUpsBackup-{{i}}" id="serverUpsBackupNo-{{i}}" value="NO" [(ngModel)]="itForm.serverInfo.servers[i].upsBackup" />
                                              <label class="form-check-label col-form-label-sm" for="serverUpsBackupNo-{{i}}">No</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If yes UPS, specify Make</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="UPS make" name = "serverUpsMake-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].upsMake"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If yes UPS, specify Model</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="UPS model" name = "serverUpsModel-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].upsModel"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If yes UPS, specify Location</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="UPS location" name = "serverUpsLocation-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].upsLocation"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Installed software</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="Enter comma separated" name = "serverInstalledSwList-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].installedSwList"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">How is server accessed for maintenance?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "serverAccessDesc-{{i}}" [(ngModel)]="itForm.serverInfo.servers[i].serviceAccessDesc"/>
                                          </div>
                                        </div>

                                        <div class="form-row" style="margin-top:10px">
                                          <div class="col text-right">
                                            <button type="submit" (click)="removeServer(server)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                          </div>
                                        </div>
                                        <hr/>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addServer()" class="btn btn-outline-primary btn-sm">&nbsp;add server&nbsp;</button>
                              </div>
                          </div>

                          <!--End Server Info -->

                          <br/>

                          <!--Begin Workstation Info -->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Workstations / Laptops / Tablets</div>

                          <div class="form-group row">
                              <div class="col-sm-12">
                              <label class="col-form-label-sm"> If available, provide information for each unit&nbsp;&nbsp;</label>
                              <div *ngFor="let workstation of itForm.workstationInfo.workstations; let i=index">
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Make</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="make" name = "wsMake-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].make"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Model</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="model" name = "wsModel-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].model"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Serial</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="serial" name = "wsSerial-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].serial"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Age</label>
                                          <div class="col-sm-4">
                                            <div class="input-group input-group-sm mb-2">
                                              <div class="input-group-prepend">
                                                <div class="input-group-text">Years</div>
                                              </div>
                                              <input type="number" class="form-control form-control-sm" placeholder="" name = "wsAgeYears-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].ageYears"/>
                                              &nbsp;
                                              <div class="input-group-prepend">
                                                <div class="input-group-text">Months</div>
                                              </div>
                                              <input type="number" class="form-control form-control-sm" placeholder="" name = "wsAgeMonths-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].ageMonths"/>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Operating System</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="operating system" name = "wsOs-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].operatingSystem"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Attached Monitor</label>
                                          <div class="col-sm-4">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="monitorAttached-{{i}}" id="monitorAttachedYes-{{i}}" value="YES" [(ngModel)]="itForm.workstationInfo.workstations[i].monitorAttached"/>
                                              <label class="form-check-label col-form-label-sm" for="monitorAttachedYes-{{i}}">Yes&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="monitorAttached-{{i}}" id="monitorAttachedNo-{{i}}" value="NO" [(ngModel)]="itForm.workstationInfo.workstations[i].monitorAttached" />
                                              <label class="form-check-label col-form-label-sm" for="monitorAttachedNo-{{i}}">No</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Monitor Make</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="monitor make" name = "monitorMake-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].monitorMake"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Monitor Model</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="monitor model" name = "monitorModel-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].monitorModel"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Monitor Serial</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="monitor serial" name = "monitorSerial-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].monitorSerial"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Monitor Size</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="monitor size" name = "monitorSize-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].monitorSize"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Warranty or Supported</label>
                                          <div class="col-sm-9">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="workstationSupportType-{{i}}" id="workstationSupportTypeSup-{{i}}" value="SUPPORT" [(ngModel)]="itForm.workstationInfo.workstations[i].supportType"/>
                                              <label class="form-check-label col-form-label-sm" for="workstationSupportTypeSup-{{i}}">Support&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="workstationSupportType-{{i}}" id="workstationSupportTypeWar-{{i}}" value="WARRANTY" [(ngModel)]="itForm.workstationInfo.workstations[i].supportType" />
                                              <label class="form-check-label col-form-label-sm" for="workstationSupportTypeWar-{{i}}">Warranty</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If supported or warrantied, by who?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="name and contact" name = "workstationSupWarContact-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].supWarContact"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Backed-Up</label>
                                          <div class="col-sm-9">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="workstationBackupAvailable-{{i}}" id="workstationBackupAvailableYes-{{i}}" value="YES" [(ngModel)]="itForm.workstationInfo.workstations[i].backupAvailable"/>
                                              <label class="form-check-label col-form-label-sm" for="workstationBackupAvailableYes-{{i}}">Yes&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="workstationBackupAvailable-{{i}}" id="workstationBackupAvailableNo-{{i}}" value="NO" [(ngModel)]="itForm.workstationInfo.workstations[i].backupAvailable" />
                                              <label class="form-check-label col-form-label-sm" for="workstationBackupAvailableNo-{{i}}">No</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If backed-up, what is the method?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "wsBackupDesc-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].backupDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If backed-up, how is it monitored?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "wsBackupMonitorDesc-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].backupMonitorDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If backed-up, how is it verified?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "wsBackupVerificationDesc-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].backupVerificationDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">When was is last updated?</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="MM/DD/YYYY" name = "wsLastServicedOn-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].lastServicedOn" (blur)="validateDate($event)"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Any current or ongoing issues</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "wsProblemDesc-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].problemDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">UPS</label>
                                          <div class="col-sm-9">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="workstationUpsBackup-{{i}}" id="workstationUpsBackupYes-{{i}}" value="YES" [(ngModel)]="itForm.workstationInfo.workstations[i].upsBackup"/>
                                              <label class="form-check-label col-form-label-sm" for="workstationUpsBackupYes-{{i}}">Yes&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="workstationUpsBackup-{{i}}" id="workstationUpsBackupNo-{{i}}" value="NO" [(ngModel)]="itForm.workstationInfo.workstations[i].upsBackup" />
                                              <label class="form-check-label col-form-label-sm" for="workstationUpsBackupNo-{{i}}">No</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If yes UPS, specify Make</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="UPS make" name = "wsUpsMake-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].upsMake"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If yes UPS, specify Model</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="UPS model" name = "wsUpsModel-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].upsModel"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Connection method</label>
                                          <div class="col-sm-4">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="wsConnectionMethod-{{i}}" id="wsConnectionMethodYes-{{i}}" value="WIRED" [(ngModel)]="itForm.workstationInfo.workstations[i].connectionMethod"/>
                                              <label class="form-check-label col-form-label-sm" for="wsConnectionMethodYes-{{i}}">Wired Direct&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="wsConnectionMethod-{{i}}" id="wsConnectionMethodNo-{{i}}" value="WIRELESS" [(ngModel)]="itForm.workstationInfo.workstations[i].connectionMethod" />
                                              <label class="form-check-label col-form-label-sm" for="wsConnectionMethodNo-{{i}}">Wireless</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Primary Software Programs</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="Enter comma separated" name = "wsInstalledSwList-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].installedSwList"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Any other issues</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "wsAnyOtherIssues-{{i}}" [(ngModel)]="itForm.workstationInfo.workstations[i].anyOtherIssues"/>
                                          </div>
                                        </div>

                                        <div class="form-row" style="margin-top:10px">
                                          <div class="col text-right">
                                            <button type="submit" (click)="removeWorkstation(workstation)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                          </div>
                                        </div>
                                        <hr/>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addWorkstation()" class="btn btn-outline-primary btn-sm">&nbsp;add workstation / laptop / tablet&nbsp;</button>
                              </div>
                          </div>

                          <!--End Workstation info-->

                          <br/>

                          <!--Begin Printer Info -->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Printers(s)</div>

                          <div class="form-group row">
                              <div class="col-sm-12">
                              <label class="col-form-label-sm"> If available, provide information for each unit&nbsp;&nbsp;</label>
                              <div *ngFor="let printer of itForm.printerInfo.printers; let i=index">

                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Name</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="name" name = "printerName-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].name"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Make</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="make" name = "printerMake-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].make"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Model</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="model" name = "printerModel-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].model"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Serial</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="serial" name = "printerSerial-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].serial"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Age</label>
                                          <div class="col-sm-4">
                                            <div class="input-group input-group-sm mb-2">
                                              <div class="input-group-prepend">
                                                <div class="input-group-text">Years</div>
                                              </div>
                                              <input type="number" class="form-control form-control-sm" placeholder="" name = "printerAgeYears-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].ageYears"/>
                                              &nbsp;
                                              <div class="input-group-prepend">
                                                <div class="input-group-text">Months</div>
                                              </div>
                                              <input type="number" class="form-control form-control-sm" placeholder="" name = "printerAgeMonths-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].ageMonths"/>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Location</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="location" name = "printerLocation-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].location"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Connection Method</label>
                                          <div class="col-sm-4">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="printerConnMethod-{{i}}" id="printerConnMethodWired-{{i}}" value="WIRED" [(ngModel)]="itForm.printerInfo.printers[i].connectionMethod"/>
                                              <label class="form-check-label col-form-label-sm" for="printerConnMethodWired-{{i}}">Wired&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="printerConnMethod-{{i}}" id="printerConnMethodWlan-{{i}}" value="WLAN" [(ngModel)]="itForm.printerInfo.printers[i].connectionMethod"/>
                                              <label class="form-check-label col-form-label-sm" for="printerConnMethodWlan-{{i}}">WLAN&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="printerConnMethod-{{i}}" id="printerConnMethodLocal-{{i}}" value="LOCAL" [(ngModel)]="itForm.printerInfo.printers[i].connectionMethod"/>
                                              <label class="form-check-label col-form-label-sm" for="printerConnMethodLocal-{{i}}">Local&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="printerConnMethod-{{i}}" id="printerConnMethodWless-{{i}}" value="WIRELESS" [(ngModel)]="itForm.printerInfo.printers[i].connectionMethod"/>
                                              <label class="form-check-label col-form-label-sm" for="printerConnMethodWless-{{i}}">Wireless</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Warranty or Supported</label>
                                          <div class="col-sm-4">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="radio" name="printerSupportType-{{i}}" id="printerSupportTypeSup-{{i}}" value="SUPPORT" [(ngModel)]="itForm.printerInfo.printers[i].supportType"/>
                                              <label class="form-check-label col-form-label-sm" for="printerSupportTypeSup-{{i}}">Support&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="radio" name="printerSupportType-{{i}}" id="printerSupportTypeWar-{{i}}" value="WARRANTY" [(ngModel)]="itForm.printerInfo.printers[i].supportType" />
                                              <label class="form-check-label col-form-label-sm" for="printerSupportTypeWar-{{i}}">Warranty</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">If supported or warrantied, by who?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="Enter name and contact" name = "printerSupWarContact-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].supWarContact"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">When was last service?</label>
                                          <div class="col-sm-4">
                                            <input type="text" class="form-control form-control-sm" placeholder="MM/DD/YYYY" name = "printerLastServicedOn-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].lastServicedOn" (blur)="validateDate($event)"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">How is it maintained?</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "printerMaintenanceDesc-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].maintenanceDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Primary functions of Printer</label>
                                          <div class="col-sm-9">
                                            <div class="form-check form-check-inline">
                                              <input class="form-check-input form-control-sm" type="checkbox" id="print_func_print" value="PRINT" [checked]="itForm.printerInfo.printers[i].printerFunctions.indexOf('PRINT') > -1" (change)="onCheckBoxEvent(itForm.printerInfo.printers[i].printerFunctions, $event)" />
                                              <label class="form-check-label col-form-label-sm" for="print_func_print">Print&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="checkbox" id="print_func_scan" value="SCAN" [checked]="itForm.printerInfo.printers[i].printerFunctions.indexOf('SCAN') > -1" (change)="onCheckBoxEvent(itForm.printerInfo.printers[i].printerFunctions, $event)" />
                                              <label class="form-check-label col-form-label-sm" for="print_func_scan">Scan&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="checkbox" id="print_func_fax" value="FAX" [checked]="itForm.printerInfo.printers[i].printerFunctions.indexOf('FAX') > -1" (change)="onCheckBoxEvent(itForm.printerInfo.printers[i].printerFunctions, $event)" />
                                              <label class="form-check-label col-form-label-sm" for="print_func_fax">Fax&nbsp;&nbsp;</label>
                                              <input class="form-check-input form-control-sm" type="checkbox" id="print_func_other" value="OTHER" [checked]="itForm.printerInfo.printers[i].printerFunctions.indexOf('OTHER') > -1" (change)="onCheckBoxEvent(itForm.printerInfo.printers[i].printerFunctions, $event)" />
                                              <label class="form-check-label col-form-label-sm" for="print_func_other">Other&nbsp;&nbsp;</label>
                                              <input type="text" class="form-control form-control-sm" placeholder="specify" name = "printerFunctionOtherDesc-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].printerFunctionOtherDesc"/>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group row">
                                          <label class="col-sm-3 col-form-label-sm text-right">Any current or ongoing issues</label>
                                          <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" placeholder="" name = "printerProblemDesc-{{i}}" [(ngModel)]="itForm.printerInfo.printers[i].problemDesc"/>
                                          </div>
                                        </div>
                                        <div class="form-row" style="margin-top:10px">
                                              <div class="col text-right">
                                                <button type="submit" (click)="removePrinterDetail(printer)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                              </div>
                                      </div>
                                      <hr/>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addPrinterDetail()" class="btn btn-outline-primary btn-sm">&nbsp;add Printer&nbsp;</button>
                              </div>
                          </div>

                          <!--End printer info-->

                          <div class="btn-group float-right mt-2" role="group" aria-label="Basic example">
                            <button (click)="prevForm()" class="btn btn-outline-primary">&nbsp;&lt;&lt;&nbsp;</button>
                            &nbsp;
                            <button *ngIf="itForm.status=='IN_PROGRESS'" type="submit" [disabled]="validationFields.length>0" (click)="save()" class="btn btn-primary">Save</button>
                            &nbsp;
                            <button (click)="nextForm()" class="btn btn-outline-primary">&nbsp;&gt;&gt;&nbsp;</button>
                          </div>
                      </form>
                  </div>
              </div>
            <br/>
        </div>
    </div>
</div>
