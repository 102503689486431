import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';

@Injectable({
  providedIn: 'root'
})
export class EditprojectService {

  constructor(private http: HttpClient) { }

  getProjectById(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/'+projectId);
  }

  recreateProjectById(projectId:string) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/project/'+projectId + '/recreate', {});
  }

  getFormStatusByProjectId(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/form/status/'+projectId);
  }

  getUserActivityProjectById(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/useractivity/'+projectId);
  }

  addUser(data) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/user/add', data);
  }

  changeUserPassword(data) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/user/changepassword', data);
  }

  getUsersByProjectId(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/users/project/'+projectId);
  }

  deleteUser(userId) {
    return this.http.delete(GlobalConstants.apiURL+'/api/onboarding/user/'+userId);
  }

  scanProject(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/scan/'+projectId);
  }

  enableFormEdit(data) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/editpermission', data);
  }

}
