import {environment} from '../environments/environment';

export class GlobalConstants {
    // public static apiURL = 'http://localhost:7077'; // .
    // public static apiURL = '.'; //Open it for prod build
    public static apiURL = environment.apiURL;

    public static AUTH_LOGGED_IN_USER = 'authenticatedUser';
    public static AUTH_USER_ROLE = 'USER_ROLE';
    public static AUTH_USER_PROJECT_ID = 'USER_PROJECT_ID';
    public static AUTH_IS_AUTHENTICATED = 'IS_AUTHENTICATED';
    public static AUTH_USER_FULL_NAME = 'USER_FULL_NAME';
    public static AUTH_SESSION_TOKEN = 'AUTH_SESSION_TOKEN';
    public static EXPIRES_ON = 'EXPIRES_ON';


    public static REGEX_DATE_VALIDATION = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    public static ERROR_COLOR = '#ff8000';
    public static ERROR_MSG_INVALID_DATE = 'Please enter valid date in MM/DD/YYYY. For example 12/04/2020';
}
