import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';

@Injectable({
  providedIn: 'root'
})
export class DdformService {

  constructor(private http: HttpClient) { }

  saveForm(data) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/ddform', data);
  }

  getProjectById(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/'+projectId);
  }

  getFormData(projectId:string, userName:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/form/ddform/'+projectId+'/'+userName);
  }

}
