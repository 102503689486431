import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';

@Injectable({
  providedIn: 'root'
})
export class MarketingformService {

    constructor(private http: HttpClient) { }

    saveForm(data) {
      return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/marketingform', data);
    }

    getProjectById(projectId:string) {
      return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/'+projectId);
    }

    getFormData(projectId:string, userName:string) {
      return this.http.get(GlobalConstants.apiURL+'/api/onboarding/form/marketingform/'+projectId+'/'+userName);
    }

    finish(data) {
      return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/editpermission', data);
    }

}
