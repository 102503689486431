import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';

@Injectable({
  providedIn: 'root'
})
export class ListprojectService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/list');
  }

  scanProject(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/scan/'+projectId);
  }

  archiveProject(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/archive/'+projectId);
  }

  recreateProjectById(projectId:string) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/project/'+projectId + '/recreate', {});
  }
}
