import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DeaformService {

  constructor(private http: HttpClient) { }

  saveForm(data: DeaForm) {
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/deaform', data);
  }

  getProjectById(projectId:string) {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/'+projectId);
  }

  getFormData(projectId:string, userName:string): Observable<DeaForm> {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/form/deaform/'+projectId+'/'+userName);
  }

}
export interface DeaForm {

  projectId?: string,
  userId?: string,
  lastSavedOn?: Date,
  status?: string,

  registrantInfo?: any,
  businessInfo?: any,
  auditInfo?: DeaAuditInfo,
}
export interface DeaAuditInfo {
  possessRegistration?: string,
  exemptRegistration?: string,
  hasControlledSubstances?: string,
  expiryDate?: string,
  scheduleControlledSubstances?: string,
  addressMatch?: string,
  activityPerformed?: string,
  keptOnSite?: string,
  keptOnSiteNote?: string,
  orderControlledSubstances?: string,
  orderControlledSubstancesText?: string,
  currentStateOnSite?: string,
  notSupportedControlledSubstances?: string,
  physicalSecurityControls?: string,
  directlyDeliveredControlledSubstances?: string,
  videoCamControlledSubstances?: string,
  hasStandardOperatingPolicy?: string,
  hasLockbox?: string,
  lockboxLocationMinTrafficFlow?: string,
  lockboxLocked?: string,
  secureRefrigerationRequired?: string,
  accessMinimized?: string,
  keysLocking?: string,
  lockChangesAbility?: string,
  sheetSigning?: string,
  masterListExistance?: string,
  backgroundChecksPerforming?: string,
  screeningProcedureExists?: string,
  screeningProcedureSpread?: string,
  drugScreeningRequired?: string,
  drugScreeningProcess?: string,
  scheduledLogs?: string,
  scheduledLogsStorage?: string,
  inventoryInLastTwoYears?: string,
  inventoryIndication?: string,
  inventoryDateChanged?: string,
  logsKeeping?: string,
  closingInventory?: string,
  logsCompliance21CFR?: string,
  logsCompliance1304?: string,
  logsRetention?: string,
  logsRetrievability?: string,
  inventoryRecordsSeparation?: string,
  inventoryReportFirst?: string,
  inventoryReportLast?: string,
  labelUnique?: string,
  dilutedSolutionsUsageLog?: string,
  vialsUsageLog?: string,
  externCSWithLog?: string,
  trainingPerformed?: string,
  handlingCSApprovalVT?: string,
  handlingCSApprovalVA?: string,
  legendDrugsReclassificationNotListed?: string,
  legendDrugsReclassificationListed?: string,
  originalPOAOnSite?: string,
  originalRevocationPOAOnSite?: string,
  originalPOAOnCentre?: string,
  unexecutedFormsSecured?: string,
  logBookEntriesCI?: string,
  forms222Compliance1305?: string,
  forms222VoidStorage?: string,
  forms222SignedByReg?: string,
  forms222FormatChangeDone?: string,
  codeFR21Supported222Format?: string,
  csosPRsignedupToSubmit?: string,
  csosCIIorderKeeping?: string,
  codeFR21SupportedCSOS?: string,
  csosWith222Forms?: string,
  hasControlledSubstancePoARevocationForm?: string,
  invoicesRetrievable?: string,
  invoicesSeparationBySchedule?: string,
  invoicesContent?: string,
  boxesInSection5Filled?: string,
  invoicesReviewScheduleII?: string,
  invoicesReviewScheduleIII?: string,
  controlledSubstancesExpiredDestruction?: string,
  controlledSubstancesExpiredRemoval?: string,
  controlledSubstancesDestruction?: string,
  controlledSubstancesWasteProcedure?: string,
  situationsUnaccountedLosses?: string,
  situationsUnaccountedLossesContractors?: string,
  accountabilitySystemsSufficiency?: string,
  automaticDispensingCabinet?: string,
  qualitySystemsUsage?: string,
  cspStandardOperatingProceduresClosedSystem?: string,
  cspStandardOperatingProceduresContinuingRecords?: string,
  cspStandardOperatingProceduresStorage?: string,
  staffTraining?: string,
  staffTrainingOccurs?: string,
  staffTrainingEvaluation?: string,
  staffTrainingEvaluationByPerformance?: string,
}
