<!--<header>-->
<!--	<div>-->
<!--		<nav class="navbar navbar-expand navbar-light bg-dark style-menu-navbar">-->
<!--			<a href="#" class="navbar-brand text-light"><img height="50" alt="Rarebreed Veterinary Partners" src="assets/images/dog.svg"/>&nbsp;Companion</a>-->
<!--			<ul class="navbar-nav navbar-collapse justify-content-end">-->
<!--				<li class="text-light">Hello:&nbsp;{{userFullName}}&nbsp;({{userrole}})&nbsp;</li>-->
<!--				<li><a *ngIf="isLoggedIn && userrole == 'ADMIN'" class="nav-link text-light" href="/#/listproject"><img height="35" title="On Boarding" src="assets/images/on_board_toolbar_button.png"/></a></li>-->
<!--				<li><a *ngIf="isLoggedIn" class="nav-link text-light" href="/#/login" (click)=handleLogout()><img height="35" title="Logout {{username}}" src="assets/images/logout.png"/></a></li>-->
<!--			</ul>-->
<!--		</nav>-->
<!--	</div>-->
<!--	<hr style="margin:0px;padding:0px"/>-->
<!--</header>-->
<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a routerLink="/listproject" class="navbar-brand text-light">
      <img height="45" alt="Rarebreed Veterinary Partners" src="assets/images/rarebreed_puli_logo.png"/>&nbsp;Onboarding
    </a>
    <span class="spacer"></span>
    <span class="text-light">&nbsp;{{userFullName}}&nbsp;({{userrole}})&nbsp;</span>
    <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar-row>

</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngIf="isLoggedIn && userrole == 'ADMIN'" routerLink='/listproject'>
    <mat-icon aria-hidden="false">handshake</mat-icon>
    Projects
  </button>
  <button mat-menu-item *ngIf="isLoggedIn" (click)=handleLogout()>
    <mat-icon aria-hidden="false">logout</mat-icon>
    Logout
  </button>
</mat-menu>

