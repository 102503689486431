<app-menu></app-menu>

  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay}">
       <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content style-modal-spinner">
               <div class="text-center">
                  <div class="spinner-border style-modal-spinner-dim" role="status">
                  </div>
                </div>
            </div>
       </div>
  </div>

  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':infoModalDisplay}">
       <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
                   <div class="modal-header">
                       <h5 class="modal-title">{{infoModalTitle}}</h5>
                       <button type="button" class="close" aria-label="Close" (click)="hideInfoModalDisplay()"><span aria-hidden="true">&times;</span></button>
                  </div>
                  <div class="modal-body">
                      <p>{{infoModalMessage}}</p>
                  </div>
                  <div class="modal-footer">
                      <br/>
                  </div>
            </div>
       </div>
  </div>

<div class="container mw-100 h-100">
    <div class="row h-100">
        <div class="col col-lg-2 bg-light">
            <app-usermenu></app-usermenu>
        </div>

        <div class="col col-lg-7">
              <h6 style="padding: 2px;"><a routerLink="/editproject" [queryParams]="{id: projectId}"><span class="bi bi-arrow-left"></span> Back to project</a></h6>
              <div [hidden]="!loaded" class="d-flex flex-row-reverse">
                <div class="p-2 font-weight-light"><small>Last updated on {{dueDiligenceForm.lastSavedOn | date : 'MM-dd-yyyy hh:mm:ss a'}}</small></div>
              </div>
              <h5 class="card-title">Due Diligence Questionnaire&nbsp;<small>{{projectName}}</small></h5>
              <div class="card" *ngIf="loaded">
                  <div class="card-body">
                      <form class="form-group">
                          <div class="alert alert-primary" role="alert" style="text-align:center;">General Information</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Practice Name</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="practiceName" placeholder="Enter practice name."  [(ngModel)]="dueDiligenceForm.generalInfo.practiceName" name="practiceName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Owner's Name</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="First name" name="ownerFirstName" [(ngModel)]="dueDiligenceForm.generalInfo.ownerFirstName" />
                                  </div>
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="Last name" name="ownerLastName" [(ngModel)]="dueDiligenceForm.generalInfo.ownerLastName" />
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Mailing Address</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <input type="text" class="form-control form-control-sm" placeholder="Street" [(ngModel)]="dueDiligenceForm.generalInfo.mailingStreet" name="mailingStreet">
                                    </div>
                                  </div>
                                  <div class="form-row style-row-gap-10">
                                    <div class="col">
                                      <input type="text" class="form-control form-control-sm" placeholder="City" [(ngModel)]="dueDiligenceForm.generalInfo.mailingCity" name="mailingCity">
                                    </div>
                                    <div class="col">
                                      <input type="text" class="form-control form-control-sm" placeholder="State" name="mailingState" [(ngModel)]="dueDiligenceForm.generalInfo.mailingState" />
                                    </div>
                                    <div class="col">
                                      <input type="number" class="form-control form-control-sm" placeholder="Zip" name="mailingZip" [(ngModel)]="dueDiligenceForm.generalInfo.mailingZip" />
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Physical Address</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <input type="text" class="form-control form-control-sm" placeholder="Street" name="physicalStreet" [(ngModel)]="dueDiligenceForm.generalInfo.physicalStreet" />
                                    </div>
                                  </div>
                                  <div class="form-row style-row-gap-10">
                                    <div class="col">
                                      <input type="text" class="form-control form-control-sm" placeholder="City" name="physicalCity" [(ngModel)]="dueDiligenceForm.generalInfo.physicalCity" />
                                    </div>
                                    <div class="col">
                                      <input type="text" class="form-control form-control-sm" placeholder="State" name="physicalState" [(ngModel)]="dueDiligenceForm.generalInfo.physicalState" />
                                    </div>
                                    <div class="col">
                                      <input type="number" class="form-control form-control-sm" placeholder="Zip" name="physicalZip" [(ngModel)]="dueDiligenceForm.generalInfo.physicalZip" />
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Office Phone/Fax</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="tel" class="form-control form-control-sm" name="officePhone" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" [(ngModel)]="dueDiligenceForm.generalInfo.officePhone" />
                                  </div>
                                  <div class="col">
                                    <input type="tel" class="form-control form-control-sm" name="officeFax" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" [(ngModel)]="dueDiligenceForm.generalInfo.officeFax" />
                                  </div>
                                </div>
                              </div>
                          </div>


                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Primary Contact</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="First name" name="primaryContactFirstName" [(ngModel)]="dueDiligenceForm.generalInfo.primaryContactFirstName" />
                                  </div>
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="Last name" name="primaryContactLastName" [(ngModel)]="dueDiligenceForm.generalInfo.primaryContactLastName" />
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Primary Contact Phone</label>
                              <div class="col-sm-9">
                                <input type="tel" class="form-control form-control-sm" id="primaryContactPhone" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"  name="primaryContactPhone" [(ngModel)]="dueDiligenceForm.generalInfo.primaryContactPhone" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Primary Contact Email</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" id="primaryContactEmail" placeholder="Enter primary contact email"  name="primaryContactEmail" [(ngModel)]="dueDiligenceForm.generalInfo.primaryContactEmail" />
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Practice Manager Name</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="First name" name="practiceManagerFirstName" [(ngModel)]="dueDiligenceForm.generalInfo.practiceManagerFirstName" />
                                  </div>
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="Last name" name="practiceManagerLastName" [(ngModel)]="dueDiligenceForm.generalInfo.practiceManagerLastName" />
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Practice Manager Email</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="email" class="form-control form-control-sm" id="practiceManagerEmail" placeholder="Enter practice manager email"  name="practiceManagerEmail" [(ngModel)]="dueDiligenceForm.generalInfo.practiceManagerEmail" />
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Vet Tech Supervisor/Lead</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="First name" name="vetTechLeadFirstName" [(ngModel)]="dueDiligenceForm.generalInfo.vetTechLeadFirstName" />
                                  </div>
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="Last name" name="vetTechLeadLastName" [(ngModel)]="dueDiligenceForm.generalInfo.vetTechLeadLastName">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Vet Tech Supervisor/Lead Email</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="email" class="form-control form-control-sm" id="vetTechEmail" placeholder="Enter Vet Tech Supervisor / Lead email"  name="vetTechEmail" [(ngModel)]="dueDiligenceForm.generalInfo.vetTechEmail" />
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">CSR Supervisor/Lead</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="First name" name="csrFirstName" [(ngModel)]="dueDiligenceForm.generalInfo.csrFirstName" />
                                  </div>
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" placeholder="Last name" name="csrLastName" [(ngModel)]="dueDiligenceForm.generalInfo.csrLastName" />
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">CSR Supervisor/Lead Email</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="email" class="form-control form-control-sm" id="csrEmail" placeholder="Enter CSR Supervisor / Lead email"  name="csrEmail" [(ngModel)]="dueDiligenceForm.generalInfo.csrEmail" />
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <!--End General Information-->
                          <br/>
                          <!--Begin Business Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Business Information</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Current Business Type</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="businessTypeOptions" id="soleProprietor" value="SOLE_PROPRIETOR" [(ngModel)]="dueDiligenceForm.businessInfo.businessType"/>
                                      <label class="form-check-label col-form-label-sm" for="soleProprietor">Sole Proprietor</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="businessTypeOptions" id="sCorp" value="SCORP" [(ngModel)]="dueDiligenceForm.businessInfo.businessType"/>
                                      <label class="form-check-label col-form-label-sm" for="sCorp">S-Corp</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="businessTypeOptions" id="cCorp" value="CCORP" [(ngModel)]="dueDiligenceForm.businessInfo.businessType"/>
                                      <label class="form-check-label col-form-label-sm" for="cCorp">C-Corp</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="businessTypeOptions" id="llc" value="LLC" [(ngModel)]="dueDiligenceForm.businessInfo.businessType"/>
                                      <label class="form-check-label col-form-label-sm" for="llc">LLC</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Federal ID (EIN or TIN)</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" id="federalId" placeholder="Federal ID (EIN or TIN) number"  name="federalId" [(ngModel)]="dueDiligenceForm.businessInfo.federalId"/>
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Ownership</label>
                              <div class="col-sm-9">
                              <div *ngFor="let practiceOwnership of dueDiligenceForm.businessInfo.practiceOwnerships; let i=index">
                                <div class="form-row" style="margin-top:5px">
                                      <div class="col">
                                        <input type="text" class="form-control form-control-sm" placeholder="Owner Full Name" name = "ownershipName-{{i}}" [(ngModel)]="dueDiligenceForm.businessInfo.practiceOwnerships[i].name"/>
                                      </div>
                                      <div class="col">
                                        <div class="input-group input-group-sm mb-2">
                                          <input type="number" class="form-control form-control-sm" placeholder="% ownership" name = "ownershipPercentage-{{i}}" [(ngModel)]="dueDiligenceForm.businessInfo.practiceOwnerships[i].percentage"/>
                                          <div class="input-group-append">
                                            <span class="input-group-text">%</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col">
                                        <button type="submit" (click)="removePracticeOwnership(practiceOwnership)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                      </div>
                                    </div>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addPracticeOwnership()" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Years in Business</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="number" class="form-control form-control-sm" id="yearsInBusiness" placeholder="Years in business"  name="yearsInBusiness" [(ngModel)]="dueDiligenceForm.businessInfo.yearsInBusiness"/>
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Current owner Purchased Date</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="text" class="form-control form-control-sm" id="yearPurchased" placeholder="Enter date in MM/DD/YYYY"  name="yearPurchased" [(ngModel)]="dueDiligenceForm.businessInfo.yearPurchased" (blur)="validateDate($event)"/>
                                    <div class="alert alert-warning" *ngIf="validationFields.indexOf('yearPurchased')> -1">{{errorMessageInvalidDate}}</div>
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Clinic offerings</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="boarding" value="BOARDING" [checked]="dueDiligenceForm.businessInfo.clinicOfferings.indexOf('BOARDING') > -1" (change)="onClinicOfferingsCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="boarding">Boarding</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="dayCare" value="DAYCARE" [checked]="dueDiligenceForm.businessInfo.clinicOfferings.indexOf('DAYCARE') > -1" (change)="onClinicOfferingsCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="dayCare">Day Care</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="grooming" value="GROOMING" [checked]="dueDiligenceForm.businessInfo.clinicOfferings.indexOf('GROOMING') > -1" (change)="onClinicOfferingsCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="grooming">Grooming</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="emergency" value="EMERGENCY" [checked]="dueDiligenceForm.businessInfo.clinicOfferings.indexOf('EMERGENCY') > -1" (change)="onClinicOfferingsCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="emergency">Emergency</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Clinic Type</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="companion" value="COMPANION" [checked]="dueDiligenceForm.businessInfo.clinicTypes.indexOf('COMPANION') > -1" (change)="onClinicTypesCheckboxChange($event)"/>
                                      <label class="form-check-label col-form-label-sm" for="companion">Companion</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="large" value="LARGE" [checked]="dueDiligenceForm.businessInfo.clinicTypes.indexOf('LARGE') > -1" (change)="onClinicTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="large">Large</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="exotics" value="EXOTIC" [checked]="dueDiligenceForm.businessInfo.clinicTypes.indexOf('EXOTIC') > -1" (change)="onClinicTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="exotics">Exotics</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="other" value="OTHER" [checked]="dueDiligenceForm.businessInfo.clinicTypes.indexOf('OTHER') > -1" (change)="onClinicTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="other">Other&nbsp;&nbsp;</label>
                                      <input type="text" class="form-control form-control-sm" id="clinicTypeOther" placeholder="If other please specify"  name="clinicTypeOther" [(ngModel)]="dueDiligenceForm.businessInfo.otherClinicTypeDescription"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Full Time Equivalent Vets</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="number" class="form-control form-control-sm" id="fteVetCount" placeholder="Number of full time equivalent vets"  name="fteVetCount" [(ngModel)]="dueDiligenceForm.businessInfo.fteVetCount" />
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Number of licensed Vets</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="number" class="form-control form-control-sm" id="licensedVetCount" placeholder="Number of licensed vets"  name="licensedVetCount" [(ngModel)]="dueDiligenceForm.businessInfo.licensedVetCount" />
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Total Number of Employees</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <input type="number" class="form-control form-control-sm" id="employeeCount" placeholder="Total number employees"  name="employeeCount" [(ngModel)]="dueDiligenceForm.businessInfo.employeeCount" />
                                  </div>
                                  <div class="col">
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Exam / Surgical Suites</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Exam</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="Number of exam rooms" name="examRoomCount" [(ngModel)]="dueDiligenceForm.businessInfo.examRoomCount" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Surgery</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="Number of surgical suites" name="surgicalSuiteCount" [(ngModel)]="dueDiligenceForm.businessInfo.surgicalSuiteCount" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">AAHA Member</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline" style="margin-left:5px;">
                                      <input class="form-check-input form-control-sm" type="radio" name="aahaMember" id="aahaMemberYes" value="YES" [(ngModel)]="dueDiligenceForm.businessInfo.aahaMember" />
                                      <label class="form-check-label col-form-label-sm" for="aahaMemberYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="aahaMember" id="aahaMemberNo" value="NO" [(ngModel)]="dueDiligenceForm.businessInfo.aahaMember" />
                                      <label class="form-check-label col-form-label-sm" for="aahaMemberNo">No</label>
                                    </div>

                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend" style="margin-left:5px;">
                                        <div class="input-group-text">Member #</div>
                                      </div>
                                      <input type="text" class="form-control form-control-sm" placeholder="" name="aahaMemberNumber" [(ngModel)]="dueDiligenceForm.businessInfo.aahaMemberNumber" />
                                    </div>
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend" style="margin-left:5px;">
                                        <div class="input-group-text">Exp</div>
                                      </div>
                                      <input type="text" class="form-control form-control-sm" placeholder="mm/dd/yyyy" name="aahaMemberExpiration" [(ngModel)]="dueDiligenceForm.businessInfo.aahaMemberExpiration" (blur)="validateDate($event)"/>
                                      <div class="alert alert-warning" *ngIf="validationFields.indexOf('aahaMemberExpiration')> -1">{{errorMessageInvalidDate}}</div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Average Animals seen</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend" style="margin-left:5px;">
                                        <div class="input-group-text">Day</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="avg per day" name="averageAnimalsPerDay" [(ngModel)]="dueDiligenceForm.businessInfo.averageAnimalsPerDay" />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend" style="margin-left:5px;">
                                        <div class="input-group-text">Week</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="avg per week" name="averageAnimalsPerWeek" [(ngModel)]="dueDiligenceForm.businessInfo.averageAnimalsPerWeek"/>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend" style="margin-left:5px;">
                                        <div class="input-group-text">Year</div>
                                      </div>
                                      <input type="number" class="form-control form-control-sm" placeholder="avg per year" name="averageAnimalsPerYear" [(ngModel)]="dueDiligenceForm.businessInfo.averageAnimalsPerYear" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Insurance Policy Renewal</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend" style="margin-left:5px;">
                                        <div class="input-group-text">Commercial</div>
                                      </div>
                                      <input type="text" class="form-control form-control-sm" placeholder="mm/dd/yyyy" name="insurancePolicyRenewalCommercial" [(ngModel)]="dueDiligenceForm.businessInfo.insurancePolicyRenewalCommercial" (blur)="validateDate($event)"/>
                                      <div class="alert alert-warning" *ngIf="validationFields.indexOf('insurancePolicyRenewalCommercial')> -1">{{errorMessageInvalidDate}}</div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend" style="margin-left:5px;">
                                        <div class="input-group-text">Health</div>
                                      </div>
                                      <input type="text" class="form-control form-control-sm" placeholder="mm/dd/yyyy" name="insurancePolicyRenewalHealth" [(ngModel)]="dueDiligenceForm.businessInfo.insurancePolicyRenewalHealth" (blur)="validateDate($event)"/>
                                      <div class="alert alert-warning" *ngIf="validationFields.indexOf('insurancePolicyRenewalHealth')> -1">{{errorMessageInvalidDate}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <!--End Business Information-->

                          <br/>

                          <!--Begin Realestate Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Real Estate</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Property Title</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="propertyTitle" id="propertyTitleOwned" value="OWNED" [(ngModel)]="dueDiligenceForm.realEstate.propertyTitle" />
                                      <label class="form-check-label col-form-label-sm" for="propertyTitleOwned">Owned</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="propertyTitle" id="propertyTitleLeased" value="LEASED" [(ngModel)]="dueDiligenceForm.realEstate.propertyTitle" />
                                      <label class="form-check-label col-form-label-sm" for="propertyTitleLeased">Leased&nbsp;</label>
                                    </div>
                                    <div class="input-group input-group-sm mb-2">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Lease Expiration Date</div>
                                      </div>
                                      <input type="text" class="form-control form-control-sm" placeholder="mm/dd/yyyy" name="leaseExpiration" [(ngModel)]="dueDiligenceForm.realEstate.leaseExpiration" (blur)="validateDate($event)"/>
                                      <div class="alert alert-warning" *ngIf="validationFields.indexOf('leaseExpiration')> -1">{{errorMessageInvalidDate}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Property Owner(s) Name</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="propertyOwnerNames" placeholder="comma separated for multiple owners"  name="propertyOwnerNames" [(ngModel)]="dueDiligenceForm.realEstate.propertyOwnerNames" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Monthly Mortgage/Rent</label>
                              <div class="col-sm-5">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">$</div>
                                  </div>
                                  <input type="number" class="form-control form-control-sm" id="monthlyMortgageOrRent" placeholder=""  name="monthlyMortgageOrRent" [(ngModel)]="dueDiligenceForm.realEstate.monthlyMortgageOrRent" />
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Age of Property</label>
                              <div class="col-sm-5">
                                <input type="number" class="form-control form-control-sm" id="ageOfProperty" placeholder=""  name="ageOfProperty" [(ngModel)]="dueDiligenceForm.realEstate.ageOfProperty" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Any liens on the property</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="liens" id="liensYes" value="YES" [(ngModel)]="dueDiligenceForm.realEstate.leins" />
                                      <label class="form-check-label col-form-label-sm" for="liensYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="liens" id="liensNo" value="NO" [(ngModel)]="dueDiligenceForm.realEstate.leins" />
                                      <label class="form-check-label col-form-label-sm" for="liensNo">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If lein, provide motrgagee information</label>
                              <div class="col-sm-5">
                                <input type="text" class="form-control form-control-sm" id="mortgageeInfo" placeholder="Enter Mortgagee name and contact"  name="mortgageeInfo"  [(ngModel)]="dueDiligenceForm.realEstate.mortgageeInfo" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Last Major Renovation Date</label>
                              <div class="col-sm-5">
                                <input type="text" class="form-control form-control-sm" id="lastMajorRenovation" placeholder="mm/dd/yyyy"  name="lastMajorRenovation"  [(ngModel)]="dueDiligenceForm.realEstate.lastMajorRenovation" (blur)="validateDate($event)"/>
                                <div class="alert alert-warning" *ngIf="validationFields.indexOf('lastMajorRenovation')> -1">{{errorMessageInvalidDate}}</div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Renovations planned in next year</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="renovationPlannedNextYear" id="renovationPlannedNextYearYes" value="YES" [(ngModel)]="dueDiligenceForm.realEstate.renovationPlannedNextYear" />
                                      <label class="form-check-label col-form-label-sm" for="plannedRenovationsYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="renovationPlannedNextYear" id="renovationPlannedNextYearNo" value="NO" [(ngModel)]="dueDiligenceForm.realEstate.renovationPlannedNextYear" />
                                      <label class="form-check-label col-form-label-sm" for="plannedRenovationsNo">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, are plans drawn up</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="plansDrawnUp" id="renovationPlansDrawnUpYes" value="YES" [(ngModel)]="dueDiligenceForm.realEstate.renovationPlansDrawnUp" />
                                      <label class="form-check-label col-form-label-sm" for="plansDrawnUpYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="plansDrawnUp" id="renovationPlansDrawnUpNo" value="NO" [(ngModel)]="dueDiligenceForm.realEstate.renovationPlansDrawnUp" />
                                      <label class="form-check-label col-form-label-sm" for="plansDrawnUpNo">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Square footage of the building</label>
                              <div class="col-sm-5">
                                <input type="number" class="form-control form-control-sm" id="buildingSqFt" placeholder=""  name="buildingSqFt"  [(ngModel)]="dueDiligenceForm.realEstate.buildingSqFt" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Building construction type</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="buildingConstType" id="buildingConstTypeFrame" value="FRAME" [(ngModel)]="dueDiligenceForm.realEstate.buildingConstType" />
                                      <label class="form-check-label col-form-label-sm" for="buildingConstTypeFrame">Frame</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="buildingConstType" id="buildingConstTypeMason" value="MASONRY" [(ngModel)]="dueDiligenceForm.realEstate.buildingConstType" />
                                      <label class="form-check-label col-form-label-sm" for="buildingConstTypeMason">Masonry</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="buildingConstType" id="buildingConstTypeJoistMason" value="JOISTED_MASONRY" [(ngModel)]="dueDiligenceForm.realEstate.buildingConstType" />
                                      <label class="form-check-label col-form-label-sm" for="buildingConstTypeJoistMason">Joisted Masonry</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="buildingConstType" id="buildingConstTypeComb" value="NON_COMBUSTIBLE" [(ngModel)]="dueDiligenceForm.realEstate.buildingConstType" />
                                      <label class="form-check-label col-form-label-sm" for="buildingConstTypeComb">Non Combustible</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is the building sprinklered?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="isSprinklered" id="isSprinkleredYes" value="YES" [(ngModel)]="dueDiligenceForm.realEstate.isSprinklered" />
                                      <label class="form-check-label col-form-label-sm" for="isSprinkleredYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="isSprinklered" id="isSprinkleredNo" value="NO" [(ngModel)]="dueDiligenceForm.realEstate.isSprinklered" />
                                      <label class="form-check-label col-form-label-sm" for="isSprinkleredNo">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Does building have fire alarm?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="isFireAlaramInstalled" id="isFireAlaramInstalledYes" value="YES" [(ngModel)]="dueDiligenceForm.realEstate.isFireAlaramInstalled" />
                                      <label class="form-check-label col-form-label-sm" for="isFireAlaramInstalledYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="isFireAlaramInstalled" id="isFireAlaramInstalledNo" value="NO" [(ngModel)]="dueDiligenceForm.realEstate.isFireAlaramInstalled" />
                                      <label class="form-check-label col-form-label-sm" for="isFireAlaramInstalledNo">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Does building have burgler alarm?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="isBurglarAlarmInstalled" id="isBurglarAlarmInstalledYes" value="YES" [(ngModel)]="dueDiligenceForm.realEstate.isBurglarAlarmInstalled" />
                                      <label class="form-check-label col-form-label-sm" for="isBurglarAlarmInstalledYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="isBurglarAlarmInstalled" id="isBurglarAlarmInstalledNo" value="NO" [(ngModel)]="dueDiligenceForm.realEstate.isBurglarAlarmInstalled" />
                                      <label class="form-check-label col-form-label-sm" for="isBurglarAlarmInstalledNo">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Any other security measure</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="otherSecurityMeasure" placeholder=""  name="otherSecurityMeasure"  [(ngModel)]="dueDiligenceForm.realEstate.otherSecurityMeasure" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Heating Type</label>
                              <div class="col-sm-5">
                                <input type="text" class="form-control form-control-sm" id="heatType" placeholder="Enter heating type"  name="heatType"  [(ngModel)]="dueDiligenceForm.realEstate.heatType" />
                              </div>
                          </div>

                          <!--End Realestate Information-->

                          <br/>

                          <!--Begin Software / Technology Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Software / Technology</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">PIMS Software</label>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Name</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="pimsName" placeholder="name"  name="pimsName"  [(ngModel)]="dueDiligenceForm.swInfo.pimsName"/>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Serial</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="pimsVersion" placeholder="version"  name="pimsVersion"  [(ngModel)]="dueDiligenceForm.swInfo.pimsVersion"/>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Online Pharmacy</label>
                              <div class="col-sm-5">
                                <input type="text" class="form-control form-control-sm" id="onlinePharmacyName" placeholder="Enter online pharmacy name / platform"  name="onlinePharmacyName"  [(ngModel)]="dueDiligenceForm.swInfo.onlinePharmacyName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Credit Card Processor</label>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Name</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="ccProcessorName" placeholder="name"  name="ccProcessorName"  [(ngModel)]="dueDiligenceForm.swInfo.ccProcessorName"/>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">MID #</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="ccAccount" placeholder="MID"  name="ccAccount"  [(ngModel)]="dueDiligenceForm.swInfo.ccAccount"/>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Number of Credit Card Readers in Hospital</label>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Stationary</div>
                                  </div>
                                  <input type="number" class="form-control form-control-sm" id="ccStationaryReaderCount" placeholder=""  name="ccStationaryReaderCount"  [(ngModel)]="dueDiligenceForm.swInfo.ccStationaryReaderCount"/>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Wireless</div>
                                  </div>
                                  <input type="number" class="form-control form-control-sm" id="ccWirelessReaderCount" placeholder=""  name="ccWirelessReaderCount"  [(ngModel)]="dueDiligenceForm.swInfo.ccWirelessReaderCount"/>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are you tied to your current Credit Card provider?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="isInCcProviderContract" id="isInCcProviderContractYes" value="YES" [(ngModel)]="dueDiligenceForm.realEstate.isInCcProviderContract" />
                                      <label class="form-check-label col-form-label-sm" for="isInCcProviderContractYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="radio" name="isInCcProviderContract" id="isInCcProviderContractNo" value="NO" [(ngModel)]="dueDiligenceForm.realEstate.isInCcProviderContract" />
                                      <label class="form-check-label col-form-label-sm" for="isInCcProviderContractNo">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Inventory Management</label>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Name</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="inventoryMgmtSwName" placeholder="name"  name="inventoryMgmtSwName"  [(ngModel)]="dueDiligenceForm.swInfo.inventoryMgmtSwName"/>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Account</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="inventoryMgmtAccount" placeholder="account"  name="inventoryMgmtAccount"  [(ngModel)]="dueDiligenceForm.swInfo.inventoryMgmtAccount"/>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Schedule Management</label>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Name</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="schedulingMgmtSwName" placeholder="name"  name="schedulingMgmtSwName"  [(ngModel)]="dueDiligenceForm.swInfo.schedulingMgmtSwName"/>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Account</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="schedulingMgmtAccount" placeholder="account"  name="schedulingMgmtAccount"  [(ngModel)]="dueDiligenceForm.swInfo.schedulingMgmtAccount"/>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Client Communication</label>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Name</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="clientCommSwName" placeholder="name"  name="clientCommSwName"  [(ngModel)]="dueDiligenceForm.swInfo.clientCommSwName"/>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Account</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="clientCommAccount" placeholder="account"  name="clientCommAccount"  [(ngModel)]="dueDiligenceForm.swInfo.clientCommAccount"/>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Referral Management</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="referralMgmtSwName" placeholder="name"  name="referralMgmtSwName"  [(ngModel)]="dueDiligenceForm.swInfo.referralMgmtSwName"/>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Reporting Software</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="reportingSwName" placeholder="name"  name="reportingSwName"  [(ngModel)]="dueDiligenceForm.swInfo.reportingSwName"/>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Payroll Company / Software</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="payrollSwName" placeholder="name"  name="payrollSwName"  [(ngModel)]="dueDiligenceForm.swInfo.payrollSwName"/>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Hosting Provider</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="hostingProvider" placeholder="name"  name="hostingProvider"  [(ngModel)]="dueDiligenceForm.swInfo.hostingProvider"/>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Email Provider</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="emailProvider" placeholder="name"  name="emailProvider"  [(ngModel)]="dueDiligenceForm.swInfo.emailProvider"/>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Patient Payment Plan Provider</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ppp_cc" value="CARE_CREDIT" [checked]="dueDiligenceForm.swInfo.patientPaymentPlanProviders.indexOf('CARE_CREDIT') > -1" (change)="onPppCheckboxChange($event)"/>
                                      <label class="form-check-label col-form-label-sm" for="ppp_cc">Care Credit</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ppp_sp" value="SCRATCH_PAY" [checked]="dueDiligenceForm.swInfo.patientPaymentPlanProviders.indexOf('SCRATCH_PAY') > -1" (change)="onPppCheckboxChange($event)"/>
                                      <label class="form-check-label col-form-label-sm" for="ppp_sp">Scratch Pay</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ppp_wf" value="WELLS_FARGO" [checked]="dueDiligenceForm.swInfo.patientPaymentPlanProviders.indexOf('WELLS_FARGO') > -1" (change)="onPppCheckboxChange($event)"/>
                                      <label class="form-check-label col-form-label-sm" for="ppp_wf">Wells Fargo</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ppp_ptly" value="PETLY" [checked]="dueDiligenceForm.swInfo.patientPaymentPlanProviders.indexOf('PETLY') > -1" (change)="onPppCheckboxChange($event)"/>
                                      <label class="form-check-label col-form-label-sm" for="ppp_ptly">Petly</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="ppp_other" value="OTHER" [checked]="dueDiligenceForm.swInfo.patientPaymentPlanProviders.indexOf('OTHER') > -1" (change)="onPppCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="ppp_other">Other&nbsp;&nbsp;</label>
                                      <input type="text" class="form-control form-control-sm" id="paymentPlanOtherDesc" placeholder="If other please specify"  name="paymentPlanOtherDesc" [(ngModel)]="dueDiligenceForm.swInfo.paymentPlanOtherDesc"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Patient Payment Plan MID#</label>
                              <div class="col-sm-9">
                              <div *ngFor="let patientPaymentPlanAccount of dueDiligenceForm.swInfo.patientPaymentPlanAccounts; let i=index">
                                <div class="form-row" style="margin-top:5px">
                                      <div class="col">
                                        <input type="text" class="form-control form-control-sm" placeholder="account" name = "pppAccount-{{i}}" [(ngModel)]="dueDiligenceForm.swInfo.patientPaymentPlanAccounts[i].accountNumber"/>
                                      </div>
                                      <div class="col">
                                        <button type="submit" (click)="removePppAccount(patientPaymentPlanAccount)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
                                      </div>
                                    </div>
                                </div>
                                <button style="margin-top:5px" type="submit" (click)="addPppAccount()" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Pet Insurance Provider</label>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Name</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="petInsuranceProviderName" placeholder="name"  name="petInsuranceProviderName"  [(ngModel)]="dueDiligenceForm.swInfo.petInsuranceProviderName"/>
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="input-group input-group-sm mb-2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Account / MID #</div>
                                  </div>
                                  <input type="text" class="form-control form-control-sm" id="petInsuranceProviderAccount" placeholder="account"  name="petInsuranceProviderAccount"  [(ngModel)]="dueDiligenceForm.swInfo.petInsuranceProviderAccount"/>
                                </div>
                              </div>
                          </div>


                          <!--End Software / Technology Information-->

                          <div class="btn-group float-right mt-2" role="group" aria-label="Basic example">
                            <button *ngIf="dueDiligenceForm.status=='IN_PROGRESS'" [disabled]="validationFields.length>0" type="submit" (click)="save()" class="btn btn-primary">Save</button>
                            &nbsp;
                            <button (click)="nextForm()" class="btn btn-outline-primary">&nbsp;&gt;&gt;&nbsp;</button>
                          </div>
                      </form>
                  </div>
              </div>
            <br/>
        </div>
    </div>
</div>
