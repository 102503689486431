<app-menu></app-menu>
<div class="container mw-100 h-100">
  <div class="row h-100">
    <div class="col col-lg-2 bg-light">
      <app-ddmenu></app-ddmenu>
    </div>

    <div class="col col-lg-10" style="padding-bottom: 15px;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
