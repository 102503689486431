import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeaformService } from './deaform.service';
import { v4 as uuid } from 'uuid';
import { GlobalConstants } from '../../global-constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-deaform',
  templateUrl: './deaform.component.html',
  styleUrls: ['./deaform.component.css']
})
export class DeaformComponent implements OnInit {

    deaForm: any;
    projectId: string;
    projectName: string;
    onBoardingProject: any;
    loaded: boolean = false;
    spinnerModalDisplay = "none";
    infoModalDisplay = "none";
    infoModalMessage: String = '';
    infoModalTitle: String = '';

    constructor(private route: ActivatedRoute, private router: Router, private deaformService: DeaformService, private datePipe: DatePipe) {

    }

    showSpinner() {
      this.spinnerModalDisplay = "block";
    }

    hideSpinner() {
      this.spinnerModalDisplay = "none";
    }

    showInfoModalDisplay(modalTitle:string, modalMessage:string) {
      this.infoModalTitle = modalTitle;
      this.infoModalMessage = modalMessage;
      this.infoModalDisplay = "block";
    }

    hideInfoModalDisplay() {
      this.infoModalDisplay = "none";
    }

    ngOnInit() {
      this.projectId = this.route.snapshot.queryParamMap.get('onBoardingProjectId');
      sessionStorage.setItem(GlobalConstants.AUTH_USER_PROJECT_ID, this.projectId);
      this.getProjectById(this.projectId);
      this.getFormData();
    }

    getProjectById(id:string) {
      this.deaformService.getProjectById(this.projectId)
        .subscribe(
          data => {
            this.onBoardingProject = data;
            this.projectName = this.onBoardingProject.projectName;
          },
          error => {
            console.log(error);
            this.showInfoModalDisplay("Error","Error while getting project data because "+error.error+". Please try again or reach out to Rarebreed IT.");
          });
    }

    onBusinessTypeCheckboxChange(event) {
      this.onCheckBoxEvent(this.deaForm.businessInfo.deaBusinessTypes, event);
    }
    onDeaProductTypeCheckboxChange(event){
      this.onCheckBoxEvent(this.deaForm.businessInfo.deaProductTypes, event);
    }

    onCsOrderFreqCheckboxChange(event){
      this.onCheckBoxEvent(this.deaForm.businessInfo.csOrderFrequencies, event);
    }

    onCsOrderMethodCheckboxChange(event){
      this.onCheckBoxEvent(this.deaForm.businessInfo.csOrderMethods, event);
    }

    onCheckBoxEvent(data:Array<any>, event){
      if (event.target.checked) {
        data.push(event.target.value);
      } else {
        data.splice(data.indexOf(event.target.value), 1);
        }
    }

    addDeaVendor(){
      this.deaForm.registrantInfo.deaVendorAccounts.push({vendorAccountId:uuid(), vendorAccountName:'', vendorAccountNumber:''});
    }

    removeDeaVendor(value){
      this.deaForm.registrantInfo.deaVendorAccounts.splice(this.deaForm.registrantInfo.deaVendorAccounts.indexOf(value), 1);
    }

    addDeaControlledSubstance(){
      this.deaForm.businessInfo.controlledSubstances.push({id:uuid(), name:'', strength:'', form:'', monthlyQuantity:''});
    }

    removeDeaControlledSubstance(value){
      this.deaForm.businessInfo.controlledSubstances.splice(this.deaForm.businessInfo.controlledSubstances.indexOf(value), 1);
    }

    getFormData(){
      this.deaformService.getFormData(this.projectId, sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER))
        .subscribe(
          response => {
            // console.log(response);
            this.deaForm = response;
            this.loaded = true;
          },
          error => {
            console.log(error);
            this.showInfoModalDisplay("Error","Error while getting form data because "+error.error+". Please try again or reach out to Rarebreed IT.");
          });
    }

    save(){
      // console.log(this.deaForm);
      this.showSpinner();
      this.deaformService.saveForm(this.deaForm)
        .subscribe(
          response => {
            this.deaForm = response;
            this.hideSpinner();
          },
          error => {
            console.log(error);
            this.hideSpinner();
            this.showInfoModalDisplay("Error","Error while saving form data because "+error.error+". Please try again or reach out to Rarebreed IT.");
          });
    }

    nextForm(){
      this.router.navigate(['/hrform'], { queryParams: { onBoardingProjectId: sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID) } });
    }

    prevForm(){
      this.router.navigate(['/ddform'], { queryParams: { onBoardingProjectId: sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID) } });
    }

}
