<app-menu></app-menu>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay}">
     <div class="modal-dialog modal-dialog-centered" role="document">
           <div class="modal-content style-modal-spinner">
             <div class="text-center">
                <div class="spinner-border style-modal-spinner-dim" role="status">
                </div>
              </div>
          </div>
     </div>
</div>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':infoModalDisplay}">
     <div class="modal-dialog modal-dialog-centered" role="document">
           <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title">{{infoModalTitle}}</h5>
                     <button type="button" class="close" aria-label="Close" (click)="hideInfoModalDisplay()"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <p>{{infoModalMessage}}</p>
                </div>
                <div class="modal-footer">
                    <br/>
                </div>
          </div>
     </div>
</div>

<div class="container mw-100 h-100">
    <div class="row h-100">
        <div class="col col-lg-2 bg-light">
            <app-usermenu></app-usermenu>
        </div>

        <div class="col col-lg-7">
          <h6 style="padding: 2px;"><a routerLink="/editproject" [queryParams]="{id: projectId}"><span class="bi bi-arrow-left"></span> Back to project</a></h6>
              <div [hidden]="!loaded" class="d-flex flex-row-reverse">
                <div class="p-2 font-weight-light"><small>Last updated on {{marketingForm.lastSavedOn | date : 'MM-dd-yyyy hh:mm:ss a'}}</small></div>
              </div>
              <h5 class="card-title">Marketing Questionnaire&nbsp;<small>{{projectName}}</small></h5>
              <div class="card" *ngIf="loaded">
                  <div class="card-body">
                      <h6 class="card-title font-weight-normal">Marketing</h6>
                      <form class="form-group">
                          <div class="alert alert-primary" role="alert" style="text-align:center;">General Information</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Marketing Contact</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="contact" placeholder="Enter marketing contact"  [(ngModel)]="marketingForm.generalInfo.contact" name="contact" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Marketing Contact E-mail</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="email" placeholder="Enter email"  [(ngModel)]="marketingForm.generalInfo.email" name="email" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Marketing Contact phone</label>
                              <div class="col-sm-4">
                                <input type="tel" class="form-control form-control-sm" id="phone" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" [(ngModel)]="marketingForm.generalInfo.phone" name="phone" />
                              </div>
                          </div>

                          <!--End General Information-->
                          <br/>
                          <!--Begin Internal Marketing-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Internal Marketing</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have a company logo?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveCompanyLogo" id="haveCompanyLogoYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveCompanyLogo"/>
                                        <label class="form-check-label col-form-label-sm" for="haveCompanyLogoYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveCompanyLogo" id="haveCompanyLogoNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveCompanyLogo" />
                                        <label class="form-check-label col-form-label-sm" for="haveCompanyLogoNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have a high res copy?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveHighResCopy" id="haveHighResCopyYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveHighResCopy"/>
                                        <label class="form-check-label col-form-label-sm" for="haveHighResCopyYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveHighResCopy" id="haveHighResCopyNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveHighResCopy" />
                                        <label class="form-check-label col-form-label-sm" for="haveHighResCopyNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have company e-mails?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveCompanyEmails" id="haveCompanyEmailsYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveCompanyEmails"/>
                                        <label class="form-check-label col-form-label-sm" for="haveCompanyEmailsYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveCompanyEmails" id="haveCompanyEmailsNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveCompanyEmails" />
                                        <label class="form-check-label col-form-label-sm" for="haveCompanyEmailsNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what is the domain?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="domain" placeholder="Enter email domain" [(ngModel)]="marketingForm.internalInfo.domain" name="domain" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Does every staff member have e-mail?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveEmailPerStaff" id="haveEmailPerStaffYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveEmailPerStaff"/>
                                        <label class="form-check-label col-form-label-sm" for="haveEmailPerStaffYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveEmailPerStaff" id="haveEmailPerStaffNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveEmailPerStaff" />
                                        <label class="form-check-label col-form-label-sm" for="haveEmailPerStaffNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If no, who does have company email?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="companyEmailStaffList" placeholder="Enter names separated by comma" [(ngModel)]="marketingForm.internalInfo.companyEmailStaffList" name="companyEmailStaffList" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you produce a monthly e-newsletter?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="generateMonthlyNewsLetter" id="generateMonthlyNewsLetterYes" value="YES" [(ngModel)]="marketingForm.internalInfo.generateMonthlyNewsLetter"/>
                                        <label class="form-check-label col-form-label-sm" for="generateMonthlyNewsLetterYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="generateMonthlyNewsLetter" id="generateMonthlyNewsLetterNo" value="NO" [(ngModel)]="marketingForm.internalInfo.generateMonthlyNewsLetter" />
                                        <label class="form-check-label col-form-label-sm" for="generateMonthlyNewsLetterNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, who writes and how it is distributed?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="monthlyNewsLetterAuthor" placeholder="Enter newsletter author name" [(ngModel)]="marketingForm.internalInfo.monthlyNewsLetterAuthor" name="monthlyNewsLetterAuthor" />
                                <input type="text" class="form-control form-control-sm" id="monthlyNewsLetterDistMode" placeholder="Enter how newsletter is distributed" [(ngModel)]="marketingForm.internalInfo.monthlyNewsLetterDistMode" name="monthlyNewsLetterDistMode" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have a blog?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveBlog" id="haveBlogYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveBlog"/>
                                        <label class="form-check-label col-form-label-sm" for="haveBlogYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveBlog" id="haveBlogNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveBlog" />
                                        <label class="form-check-label col-form-label-sm" for="haveBlogNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, who writes it and where it is posted?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="blogAuthor" placeholder="Enter blog author name" [(ngModel)]="marketingForm.internalInfo.blogAuthor" name="blogAuthor" />
                                <input type="text" class="form-control form-control-sm" id="blogDistMode" placeholder="Enter where blog is posted" [(ngModel)]="marketingForm.internalInfo.blogDistMode" name="blogDistMode" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you offer any promotions or discounts?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="offerDiscount" id="offerDiscountYes" value="YES" [(ngModel)]="marketingForm.internalInfo.offerDiscount"/>
                                        <label class="form-check-label col-form-label-sm" for="offerDiscountYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="offerDiscount" id="offerDiscountNo" value="NO" [(ngModel)]="marketingForm.internalInfo.offerDiscount" />
                                        <label class="form-check-label col-form-label-sm" for="offerDiscountNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>


                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, check all that apply</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                  <div class="col">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_dollar" value="PCT_DOLLAR_OFF" [checked]="marketingForm.internalInfo.discountTypes.indexOf('PCT_DOLLAR_OFF') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_dollar">% or $ off</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_cpns" value="COUPONS" [checked]="marketingForm.internalInfo.discountTypes.indexOf('COUPONS') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_cpns">Coupons</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_fitem" value="FREE_FIRST_ITEM" [checked]="marketingForm.internalInfo.discountTypes.indexOf('FREE_FIRST_ITEM') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_fitem">Free First Item</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_vday" value="VACCINE_DAY" [checked]="marketingForm.internalInfo.discountTypes.indexOf('VACCINE_DAY') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_vday">Vaccine Day</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_visit" value="VISIT" [checked]="marketingForm.internalInfo.discountTypes.indexOf('VISIT') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_visit">Discount on Products with Visit</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_dental" value="DENTAL_MONTH" [checked]="marketingForm.internalInfo.discountTypes.indexOf('DENTAL_MONTH') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_dental">Dental Month</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_nclient" value="NEW_CLIENT" [checked]="marketingForm.internalInfo.discountTypes.indexOf('NEW_CLIENT') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_nclient">New Client</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_mpet" value="MULTIPLE_PET" [checked]="marketingForm.internalInfo.discountTypes.indexOf('MULTIPLE_PET') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_mpet">Multiple Pet Discount</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_anniv" value="ANNIVERSARY" [checked]="marketingForm.internalInfo.discountTypes.indexOf('ANNIVERSARY') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_anniv">Anniversary Discount</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_puppy" value="PUPPY_PROMO" [checked]="marketingForm.internalInfo.discountTypes.indexOf('PUPPY_PROMO') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_puppy">Puppy Promo</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_ft" value="FLEA_TICK" [checked]="marketingForm.internalInfo.discountTypes.indexOf('FLEA_TICK') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
                                      <label class="form-check-label col-form-label-sm" for="disc_pct_ft">Flea Tick Discount</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you produce client facing marketing materials?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="produceClientFacingMaterial" id="produceClientFacingMaterialYes" value="YES" [(ngModel)]="marketingForm.internalInfo.produceClientFacingMaterial"/>
                                        <label class="form-check-label col-form-label-sm" for="produceClientFacingMaterialYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="produceClientFacingMaterial" id="produceClientFacingMaterialNo" value="NO" [(ngModel)]="marketingForm.internalInfo.produceClientFacingMaterial" />
                                        <label class="form-check-label col-form-label-sm" for="produceClientFacingMaterialNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what is created?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="clientFacingMaterialDesc" placeholder="" [(ngModel)]="marketingForm.internalInfo.clientFacingMaterialDesc" name="clientFacingMaterialDesc" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Can you provide a copy to us?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="canProvideClientFacingMaterialCopy" id="canProvideClientFacingMaterialCopyYes" value="YES" [(ngModel)]="marketingForm.internalInfo.canProvideClientFacingMaterialCopy"/>
                                        <label class="form-check-label col-form-label-sm" for="canProvideClientFacingMaterialCopyYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="canProvideClientFacingMaterialCopy" id="canProvideClientFacingMaterialCopyNo" value="NO" [(ngModel)]="marketingForm.internalInfo.canProvideClientFacingMaterialCopy" />
                                        <label class="form-check-label col-form-label-sm" for="canProvideClientFacingMaterialCopyNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>


                          <!--End Internal Marketing-->

                          <br/>

                          <!--Begin Website Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Website</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How is your website getting found online?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="businessDiscoveryDesc" placeholder="" [(ngModel)]="marketingForm.websiteInfo.businessDiscoveryDesc" name="businessDiscoveryDesc" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Does your practice show up in web searches?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="practiceShowOnSearch" id="practiceShowOnSearchYes" value="YES" [(ngModel)]="marketingForm.websiteInfo.practiceShowOnSearch"/>
                                        <label class="form-check-label col-form-label-sm" for="practiceShowOnSearchYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="practiceShowOnSearch" id="practiceShowOnSearchNo" value="NO" [(ngModel)]="marketingForm.websiteInfo.practiceShowOnSearch" />
                                        <label class="form-check-label col-form-label-sm" for="practiceShowOnSearchNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Is your website optimized for search engines?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="siteOptimized" id="siteOptimizedYes" value="YES" [(ngModel)]="marketingForm.websiteInfo.siteOptimized"/>
                                        <label class="form-check-label col-form-label-sm" for="siteOptimizedYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="siteOptimized" id="siteOptimizedNo" value="NO" [(ngModel)]="marketingForm.websiteInfo.siteOptimized" />
                                        <label class="form-check-label col-form-label-sm" for="siteOptimizedNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, which company optimizes?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="adSearchCompany" placeholder="" [(ngModel)]="marketingForm.websiteInfo.adSearchCompany" name="adSearchCompany" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Does your website include "conversion" forms where visitors can leave email address in exchange for content like newsletter?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="availableConversionForm" id="availableConversionFormYes" value="YES" [(ngModel)]="marketingForm.websiteInfo.availableConversionForm"/>
                                        <label class="form-check-label col-form-label-sm" for="availableConversionFormYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="availableConversionForm" id="availableConversionFormNo" value="NO" [(ngModel)]="marketingForm.websiteInfo.availableConversionForm" />
                                        <label class="form-check-label col-form-label-sm" for="availableConversionFormNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Who hosts your website?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="webHostingContact" placeholder="" [(ngModel)]="marketingForm.websiteInfo.webHostingContact" name="webHostingContact" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Who updates your website?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="siteUpdateContact" placeholder="" [(ngModel)]="marketingForm.websiteInfo.siteUpdateContact" name="siteUpdateContact" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">How often is it updated?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="siteUpdateFrequency" placeholder="" [(ngModel)]="marketingForm.websiteInfo.siteUpdateFrequency" name="siteUpdateFrequency" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">When was it last updated?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="siteLastUpdatedOn" placeholder="MM/DD/YYYY" [(ngModel)]="marketingForm.websiteInfo.siteLastUpdatedOn" name="siteLastUpdatedOn" (blur)="validateDate($event)"/>
                                <div class="alert alert-warning" *ngIf="validationFields.indexOf('siteLastUpdatedOn')> -1">{{errorMessageInvalidDate}}</div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">When the website built?</label>
                              <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm" id="siteCreatedOn" placeholder="" [(ngModel)]="marketingForm.websiteInfo.siteCreatedOn" name="siteCreatedOn" (blur)="validateDate($event)"/>
                                <div class="alert alert-warning" *ngIf="validationFields.indexOf('siteCreatedOn')> -1">{{errorMessageInvalidDate}}</div>
                              </div>
                          </div>

                          <!--End Website Information-->

                          <!--Begin Social Media Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Social Media</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have a facebook account?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveFacebookAccount" id="haveFacebookAccountYes" value="YES" [(ngModel)]="marketingForm.socialInfo.haveFacebookAccount"/>
                                        <label class="form-check-label col-form-label-sm" for="haveFacebookAccountYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveFacebookAccount" id="haveFacebookAccountNo" value="NO" [(ngModel)]="marketingForm.socialInfo.haveFacebookAccount" />
                                        <label class="form-check-label col-form-label-sm" for="haveFacebookAccountNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what is the account name?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="facebookAccountName" placeholder="" [(ngModel)]="marketingForm.socialInfo.facebookAccountName" name="facebookAccountName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, who manages it?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="facebookAuthor" placeholder="" [(ngModel)]="marketingForm.socialInfo.facebookAuthor" name="facebookAuthor" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, do you regularly share content?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="shareFacebookContent" id="shareFacebookContentYes" value="YES" [(ngModel)]="marketingForm.socialInfo.shareFacebookContent"/>
                                        <label class="form-check-label col-form-label-sm" for="shareFacebookContentYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="shareFacebookContent" id="shareFacebookContentNo" value="NO" [(ngModel)]="marketingForm.socialInfo.shareFacebookContent" />
                                        <label class="form-check-label col-form-label-sm" for="shareFacebookContentNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you advertise on facebook?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="advertiseOnFacebook" id="advertiseOnFacebookYes" value="YES" [(ngModel)]="marketingForm.socialInfo.advertiseOnFacebook"/>
                                        <label class="form-check-label col-form-label-sm" for="advertiseOnFacebookYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="advertiseOnFacebook" id="advertiseOnFacebookNo" value="NO" [(ngModel)]="marketingForm.socialInfo.advertiseOnFacebook" />
                                        <label class="form-check-label col-form-label-sm" for="advertiseOnFacebookNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have an Instagram account?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveInstagramAccount" id="haveInstagramAccountYes" value="YES" [(ngModel)]="marketingForm.socialInfo.haveInstagramAccount"/>
                                        <label class="form-check-label col-form-label-sm" for="haveInstagramAccountYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveInstagramAccount" id="haveInstagramAccountNo" value="NO" [(ngModel)]="marketingForm.socialInfo.haveInstagramAccount" />
                                        <label class="form-check-label col-form-label-sm" for="haveInstagramAccountNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what is the account name?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="instagramAccountName" placeholder="" [(ngModel)]="marketingForm.socialInfo.instagramAccountName" name="instagramAccountName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, who manages it?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="instagramAuthor" placeholder="" [(ngModel)]="marketingForm.socialInfo.instagramAuthor" name="instagramAuthor" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, do you regularly share content?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="shareInstagramContent" id="shareInstagramContentYes" value="YES" [(ngModel)]="marketingForm.socialInfo.shareInstagramContent"/>
                                        <label class="form-check-label col-form-label-sm" for="shareInstagramContentYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="shareInstagramContent" id="shareInstagramContentNo" value="NO" [(ngModel)]="marketingForm.socialInfo.shareInstagramContent" />
                                        <label class="form-check-label col-form-label-sm" for="shareInstagramContentNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you advertise on Instagram?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="advertiseOnInstagram" id="advertiseOnInstagramYes" value="YES" [(ngModel)]="marketingForm.socialInfo.advertiseOnInstagram"/>
                                        <label class="form-check-label col-form-label-sm" for="advertiseOnInstagramYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="advertiseOnInstagram" id="advertiseOnInstagramNo" value="NO" [(ngModel)]="marketingForm.socialInfo.advertiseOnInstagram" />
                                        <label class="form-check-label col-form-label-sm" for="advertiseOnInstagramNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have an Twitter account?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveTwitterAccount" id="haveTwitterAccountYes" value="YES" [(ngModel)]="marketingForm.socialInfo.haveTwitterAccount"/>
                                        <label class="form-check-label col-form-label-sm" for="haveITwitterAccountYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveTwitterAccount" id="haveTwitterAccountNo" value="NO" [(ngModel)]="marketingForm.socialInfo.haveTwitterAccount" />
                                        <label class="form-check-label col-form-label-sm" for="haveITwitterAccountNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what is the account name?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="twitterAccountName" placeholder="" [(ngModel)]="marketingForm.socialInfo.twitterAccountName" name="twitterAccountName" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, who manages it?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="twitterAuthor" placeholder="" [(ngModel)]="marketingForm.socialInfo.twitterAuthor" name="twitterAuthor" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, do you regularly share content?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="shareTwitterContent" id="shareTwitterContentYes" value="YES" [(ngModel)]="marketingForm.socialInfo.shareTwitterContent"/>
                                        <label class="form-check-label col-form-label-sm" for="shareTwitterContentYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="shareTwitterContent" id="shareTwitterContentNo" value="NO" [(ngModel)]="marketingForm.socialInfo.shareTwitterContent" />
                                        <label class="form-check-label col-form-label-sm" for="shareTwitterContentNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you advertise on Twitter?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="advertiseOnTwitter" id="advertiseOnTwitterYes" value="YES" [(ngModel)]="marketingForm.socialInfo.advertiseOnTwitter"/>
                                        <label class="form-check-label col-form-label-sm" for="advertiseOnTwitterYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="advertiseOnTwitter" id="advertiseOnTwitterNo" value="NO" [(ngModel)]="marketingForm.socialInfo.advertiseOnTwitter" />
                                        <label class="form-check-label col-form-label-sm" for="advertiseOnTwitterNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you regularly promote offers on social media?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="promoteOfferOnSocialMedia" id="promoteOfferOnSocialMediaYes" value="YES" [(ngModel)]="marketingForm.socialInfo.promoteOfferOnSocialMedia"/>
                                        <label class="form-check-label col-form-label-sm" for="promoteOfferOnSocialMediaYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="promoteOfferOnSocialMedia" id="promoteOfferOnSocialMediaNo" value="NO" [(ngModel)]="marketingForm.socialInfo.promoteOfferOnSocialMedia" />
                                        <label class="form-check-label col-form-label-sm" for="promoteOfferOnSocialMediaNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you direct website visitors to social media with links?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="directSiteVisitorToSocialMedia" id="directSiteVisitorToSocialMediaYes" value="YES" [(ngModel)]="marketingForm.socialInfo.directSiteVisitorToSocialMedia"/>
                                        <label class="form-check-label col-form-label-sm" for="directSiteVisitorToSocialMediaYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="directSiteVisitorToSocialMedia" id="directSiteVisitorToSocialMediaNo" value="NO" [(ngModel)]="marketingForm.socialInfo.directSiteVisitorToSocialMedia" />
                                        <label class="form-check-label col-form-label-sm" for="directSiteVisitorToSocialMediaNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <!--End Social Media Information-->

                          <!--Begin Advertising Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Advertising</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have an annual Advertising budget?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveAnnualAdBudget" id="haveAnnualAdBudgetYes" value="YES" [(ngModel)]="marketingForm.advertisingInfo.haveAnnualAdBudget"/>
                                        <label class="form-check-label col-form-label-sm" for="haveAnnualAdBudgetYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveAnnualAdBudget" id="haveAnnualAdBudgetNo" value="NO" [(ngModel)]="marketingForm.advertisingInfo.haveAnnualAdBudget" />
                                        <label class="form-check-label col-form-label-sm" for="haveAnnualAdBudgetNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what is it?</label>
                              <div class="col-sm-4">
                                <input type="number" class="form-control form-control-sm" id="annualBudgetValue" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.annualBudgetValue" name="annualBudgetValue" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, who manages it?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="budgetManagerContact" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.budgetManagerContact" name="budgetManagerContact" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What media do you advertise with/on?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="adMedaList" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.adMedaList" name="adMedaList" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What metrics do you employ to measure success or ROI?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="roiCalcMethodList" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.roiCalcMethodList" name="roiCalcMethodList" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Are you in any current Advertising contracts?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveAdContracts" id="haveAdContractsYes" value="YES" [(ngModel)]="marketingForm.advertisingInfo.haveAdContracts"/>
                                        <label class="form-check-label col-form-label-sm" for="haveAdContractsYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveAdContracts" id="haveAdContractsNo" value="NO" [(ngModel)]="marketingForm.advertisingInfo.haveAdContracts" />
                                        <label class="form-check-label col-form-label-sm" for="haveAdContractsNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Describe contract type and terms for each?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="adContractList" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.adContractList" name="adContractList" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you do seasonal advertising?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveSeasonalAd" id="haveSeasonalAdYes" value="YES" [(ngModel)]="marketingForm.advertisingInfo.haveSeasonalAd"/>
                                        <label class="form-check-label col-form-label-sm" for="haveSeasonalAdYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveSeasonalAd" id="haveSeasonalAdNo" value="NO" [(ngModel)]="marketingForm.advertisingInfo.haveSeasonalAd" />
                                        <label class="form-check-label col-form-label-sm" for="haveSeasonalAdNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what season and what advertising?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="seasonalAdList" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.seasonalAdList" name="seasonalAdList" />
                              </div>
                          </div>

                          <!--End Advertising Information-->

                          <!--Begin Sponsorship Information-->

                          <div class="alert alert-primary" role="alert" style="text-align:center;">Sponsorship</div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you sponsor any local organizations?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="sponsorLocalOrg" id="sponsorLocalOrgYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.sponsorLocalOrg"/>
                                        <label class="form-check-label col-form-label-sm" for="sponsorLocalOrgYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="sponsorLocalOrg" id="sponsorLocalOrgNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.sponsorLocalOrg" />
                                        <label class="form-check-label col-form-label-sm" for="sponsorLocalOrgNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, who when and how much?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="sponsorshipDetails" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.sponsorshipDetails" name="sponsorshipDetails" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you have signage at field or rink?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveFieldRinkSignage" id="haveFieldRinkSignageYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.haveFieldRinkSignage"/>
                                        <label class="form-check-label col-form-label-sm" for="haveFieldRinkSignageYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="haveFieldRinkSignage" id="haveFieldRinkSignageNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.haveFieldRinkSignage" />
                                        <label class="form-check-label col-form-label-sm" for="haveFieldRinkSignageNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what are terms and duration?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="termsDurations" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.termsDurations" name="termsDurations"/>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Do you donate to local charity events?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="donateToCharity" id="donateToCharityYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.donateToCharity"/>
                                        <label class="form-check-label col-form-label-sm" for="donateToCharityYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="donateToCharity" id="donateToCharityNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.donateToCharity"/>
                                        <label class="form-check-label col-form-label-sm" for="donateToCharityNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, are any recurring annually?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="recurringCharityYearly" id="recurringCharityYearlyYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.recurringCharityYearly"/>
                                        <label class="form-check-label col-form-label-sm" for="recurringCharityYearlyYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="recurringCharityYearly" id="recurringCharityYearlyNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.recurringCharityYearly"/>
                                        <label class="form-check-label col-form-label-sm" for="recurringCharityYearlyNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If recurring, what are they?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="charityList" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.charityList" name="charityList"/>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">What is your annual budget for charitable giving?</label>
                              <div class="col-sm-4">
                                <input type="number" class="form-control form-control-sm" id="annualCharityBudget" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.annualCharityBudget" name="annualCharityBudget"/>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">Does practice participate in parades and events?</label>
                              <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="participateInEvents" id="participateInEventsYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.participateInEvents"/>
                                        <label class="form-check-label col-form-label-sm" for="participateInEventsYes">Yes</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input form-control-sm" type="radio" name="participateInEvents" id="participateInEventsNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.participateInEvents"/>
                                        <label class="form-check-label col-form-label-sm" for="participateInEventsNo">No</label>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                          <div class="form-group row">
                              <label class="col-sm-3 col-form-label-sm text-right">If yes, what events, where and when?</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" id="participatingEventList" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.participatingEventList" name="participatingEventList"/>
                              </div>
                          </div>

                          <!--End Sponsorship Information-->

                          <div class="btn-group float-right mt-2" role="group" aria-label="Basic example">
                            <button (click)="prevForm()" class="btn btn-outline-primary">&nbsp;&lt;&lt;&nbsp;</button>
                            &nbsp;
                            <button *ngIf="marketingForm.status=='IN_PROGRESS'" type="submit" [disabled]="validationFields.length>0" (click)="save()" class="btn btn-primary">Save</button>
                            &nbsp;
                            <button *ngIf="marketingForm.status=='IN_PROGRESS'" (click)="finish()" class="btn btn-outline-primary">Finish</button>
                          </div>
                      </form>
                  </div>
              </div>
            <br/>
        </div>
    </div>
</div>
