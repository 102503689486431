import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './login/auth.service';
import { GlobalConstants } from './global-constants';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService, private route: ActivatedRoute,
    private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authenticationService.isUserLoggedIn() && req.url.indexOf('basicauth') === -1) {
            const authReq = req.clone({
                headers: new HttpHeaders({
                    //'Content-Type': 'application/json',
                    'Authorization': sessionStorage.getItem(GlobalConstants.AUTH_SESSION_TOKEN)
                })
            });
            return next.handle(authReq);
        } else {
            this.router.navigate(['/login']); //This redirects any page to login page if user is not authenticated.
            return next.handle(req);
        }
    }
}
