import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {HrForm, HrformService, HrPayrollForm} from './hrform.service';
import { v4 as uuid } from 'uuid';
import { GlobalConstants } from '../../global-constants';
import { DatePipe } from '@angular/common';
import {forkJoin, Observable} from "rxjs";

@Component({
  selector: 'app-hrform',
  templateUrl: './hrform.component.html',
  styleUrls: ['./hrform.component.css']
})
export class HrformComponent implements OnInit {

  hrForm: HrForm;
  spinnerModalDisplay = "none";
  projectId: string;
  projectName: string;
  onBoardingProject: any;
  loaded: boolean = false;
  validationFields = [];
  errorMessageInvalidDate = GlobalConstants.ERROR_MSG_INVALID_DATE;
  infoModalDisplay = "none";
  infoModalMessage: String = '';
  infoModalTitle: String = '';
  hrPayrollForm: HrPayrollForm;
  weekDays: {id,value, full}[] = [];
  shiftDifferentialRate : {
    percent?: number;
    hourly?: number;
    bonus?: number;
  } = {};
  constructor(private route: ActivatedRoute, private router: Router, private hrformService: HrformService, private datePipe: DatePipe) {

  }

  validateDate(event){
      var validationSuccessful = true;
      if(event.target.value!=null && event.target.value.length>0){
        validationSuccessful = GlobalConstants.REGEX_DATE_VALIDATION.test(event.target.value);
      }
      if(validationSuccessful){
        this.validationFields.splice(this.validationFields.indexOf(event.target.name), 1);
        event.target.style.backgroundColor = 'white';
      }else{
        this.validationFields.push(event.target.name);
        event.target.style.backgroundColor = GlobalConstants.ERROR_COLOR;
      }
  }

  showSpinner() {
    this.spinnerModalDisplay = "block";
  }

  hideSpinner() {
    this.spinnerModalDisplay = "none";
  }

  showInfoModalDisplay(modalTitle:string, modalMessage:string) {
    this.infoModalTitle = modalTitle;
    this.infoModalMessage = modalMessage;
    this.infoModalDisplay = "block";
  }

  hideInfoModalDisplay() {
    this.infoModalDisplay = "none";
  }

  ngOnInit() {
    this.weekDays.push(
      {id: 'MONDAY', value: 'Mon', full: 'Monday'},
      {id: 'TUESDAY', value: 'Tue', full: 'Tuesday'},
      {id: 'WEDNESDAY', value: 'Wed', full: 'Wednesday'},
      {id: 'THURSDAY', value: 'Thu', full: 'Thursday'},
      {id: 'FRIDAY', value: 'Fri', full: 'Friday'},
      {id: 'SATURDAY', value: 'Sat', full: 'Saturday'},
      {id: 'SUNDAY', value: 'Sun', full: 'Sunday'},
    )
    this.projectId = this.route.snapshot.queryParamMap.get('onBoardingProjectId');
    sessionStorage.setItem(GlobalConstants.AUTH_USER_PROJECT_ID, this.projectId);
    this.getProjectById(this.projectId);
    this.getFormData();

  }

  getProjectById(id:string) {
    this.hrformService.getProjectById(this.projectId)
      .subscribe(
        data => {
          this.onBoardingProject = data;
          this.projectName = this.onBoardingProject.projectName;
        },
        error => {
          console.log(error);
          this.showInfoModalDisplay("Error","Error while getting project data because "+error.error+". Please try again or reach out to Rarebreed IT.");
        });
  }

  insuranceTypesCheckboxChange(event) {
    this.onCheckBoxEvent(this.hrForm.benefitInfo.offeredInsuranceTypes, event);
  }

  retirementPlansCheckboxChange(event){
    this.onCheckBoxEvent(this.hrForm.benefitInfo.retirementPlans, event);
  }

  bonusBasisCheckboxChange(event){
    this.onCheckBoxEvent(this.hrForm.compInfo.midYearBonusBasis, event);
  }

  raiseFrequenciesCheckboxChange(event){
    this.onCheckBoxEvent(this.hrForm.empInfo.raiseFrequencies, event);
  }

  workShiftsCheckboxChange(event){
    this.onCheckBoxEvent(this.hrForm.empInfo.workShifts, event);
  }

  empFillFreqCheckboxChange(event){
    this.onCheckBoxEvent(this.hrForm.empInfo.empFillFrequencies, event);
  }

  techFillFreqCheckboxChange(event){
    this.onCheckBoxEvent(this.hrForm.empInfo.techFillFrequencies, event);
  }

  onCheckBoxEvent(data:Array<any>, event){
    if (event.target.checked) {
      data.push(event.target.value);
    } else {
      data.splice(data.indexOf(event.target.value), 1);
    }
  }

  addEmployeeLeaveDetail(){
    this.hrForm.compInfo.empLeaveDetails.push({id:uuid(), name:'', leaveType:'',lastDayOfWork:'',returnDate:''});
  }
  addHolidayToList(holiday: string|undefined){
    if(holiday === undefined) {
      holiday = "";
    }
    if (this.hrPayrollForm.compPayInfo.holidayList.indexOf(holiday)< 0) {
      this.hrPayrollForm.compPayInfo.holidayList.push(holiday);
    }
  }
  removeHolidayToList(holiday){
    this.hrPayrollForm.compPayInfo.holidayList.splice(this.hrPayrollForm.compPayInfo.holidayList.indexOf(holiday), 1);
  }
  removeEmployeeLeaveDetail(value){
    this.hrForm.compInfo.empLeaveDetails.splice(this.hrForm.compInfo.empLeaveDetails.indexOf(value), 1);
  }

  getFormData() {
    forkJoin(
      [
        this.hrformService.getPayrollFormData(this.projectId, sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER)),
        this.hrformService.getFormData(this.projectId, sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER))
      ]
    ).subscribe(([payrollFormData, formData]) => {
      this.hrPayrollForm = payrollFormData;
      if (this.hrPayrollForm.compPayInfo?.shiftDifferentialRate?.type !== undefined) {
        switch (this.hrPayrollForm.compPayInfo?.shiftDifferentialRate?.type) {
          case "BONUS":
            this.shiftDifferentialRate.bonus = this.hrPayrollForm.compPayInfo?.shiftDifferentialRate.amount;
            break;
          case "HOURLY_PERCENT":
            this.shiftDifferentialRate.percent = this.hrPayrollForm.compPayInfo?.shiftDifferentialRate.amount;
            break;
          case "HOURLY_ADD":
            this.shiftDifferentialRate.hourly = this.hrPayrollForm.compPayInfo?.shiftDifferentialRate.amount;
            break;
        }
      }
      this.hrPayrollForm.compPayInfo?.shiftDifferentialRate
      console.log(formData);
      this.hrForm = formData;
      this.loaded = true;
      // make your last http request here.
    }, error => {
      console.log(error);
      this.showInfoModalDisplay("Error", "Error while getting form data because " + error.error + ". Please try again or reach out to Rarebreed IT.");
    });
  }

  save(){
    // console.log(this.hrForm);
    this.showSpinner();
    this.hrForm.userId = sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER);
    this.hrPayrollForm.userId = sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER);

    if(this.hrPayrollForm.compPayInfo?.shiftDifferentialRate?.type !== undefined) {
      switch (this.hrPayrollForm.compPayInfo?.shiftDifferentialRate?.type) {
        case "BONUS": this.hrPayrollForm.compPayInfo.shiftDifferentialRate.amount = this.shiftDifferentialRate.bonus;break;
        case "HOURLY_PERCENT": this.hrPayrollForm.compPayInfo.shiftDifferentialRate.amount = this.shiftDifferentialRate.percent;break;
        case "HOURLY_ADD": this.hrPayrollForm.compPayInfo.shiftDifferentialRate.amount = this.shiftDifferentialRate.hourly;break;
      }
    }

    forkJoin(
      [
        this.hrformService.saveForm(this.hrForm),
        this.hrformService.savePayrollForm(this.hrPayrollForm)
      ]).subscribe(
      ([saveFromResponse, savePayrollFormResponse]) => {
          this.hrForm = saveFromResponse;
          this.hrPayrollForm = savePayrollFormResponse;
          this.hideSpinner();
        },
        error => {
          console.log(error);
          this.hideSpinner();
          this.showInfoModalDisplay("Error","Error while saving form data because "+error.error+". Please try again or reach out to Rarebreed IT.");
        });
  }

  nextForm(){
    this.router.navigate(['/itform'], { queryParams: { onBoardingProjectId: sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID) } });
  }

  prevForm(){
    this.router.navigate(['/deaform'], { queryParams: { onBoardingProjectId: sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID) } });
  }

  protected readonly console = console;

  onShiftDifferentialApplyCheck($event: Event) {
    this.onCheckBoxEvent(this.hrPayrollForm.compPayInfo.shiftDifferentialApply, $event);
  }
  onShiftDifferentialApplyDayCheck($event: Event) {
    this.onCheckBoxEvent(this.hrPayrollForm.compPayInfo.shiftDifferentialApplyDayOfWeek, $event);
  }

  onHoursOfOperationChange($event, day: string) {
    if($event.target.checked) {
      console.log(`${day} : checked`)
      this.hrPayrollForm.compPayInfo.hoursOfOperation[day].enabled = true;
    } else {
      console.log(`${day} : unchecked`)
      this.hrPayrollForm.compPayInfo.hoursOfOperation[day].enabled = false;
    }
  }
}
