import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DdsettingsService {

  constructor(private http: HttpClient) { }

  uploadDDTemplate(file: File) {
    const formData: FormData = new FormData();
    formData.append('files', file);
    return this.http.post(GlobalConstants.apiURL+'/api/onboarding/appconfig/duediligence_template', formData);
  }
  getDDTemplate(): Observable<DDSettings> {
    return this.http.get(GlobalConstants.apiURL+'/api/onboarding/appconfig/duediligence_template');
  }


}

export interface DDSettings {
  projectLink?: string;
  projectFile?: string;
}
