<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content style-modal-spinner">
      <div class="text-center">
        <div class="spinner-border style-modal-spinner-dim" role="status">
        </div>
      </div>
    </div>
  </div>
</div>

<br />
<h5 class="card-title">Due Diligence Settings.</h5>
<div class="card">
  <div class="card-body">
    <form>
      <h6>Due Dilingence Asana template</h6>
<!--      <div class="form-group">-->
<!--        <label for="projectLink">Project link</label>-->
<!--        <input type="text" class="form-control" id="projectLink" [(ngModel)]="settings.projectLink" placeholder="Enter project link in Asana" name="projectLink" required />-->
<!--        <small id="projectLinkHelpBlock" class="form-text text-muted">-->
<!--          Link to Asana project used as a template.-->
<!--        </small>-->
<!--      </div>-->
      <div class="alert alert-danger row" role="alert" [hidden]="!summary">
        {{ summary }}
      </div>
      <div>
        <div class="form-group" [hidden]="editMode">
          <label for="projectFileLocation">Project JSON location :</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="projectFileLocation" disabled [(ngModel)]="settings.projectFile">            <div class="input-group-append">
            <a class="btn btn-outline-secondary" *ngIf="settings.projectLink" [href]="settings.projectLink" target="_blank">Go to Asana< <span class="bi bi-box-arrow-up-right"></span></a>
            </div>
          </div>

<!--          <a *ngIf="settings.projectLink" [href]="settings.projectLink">Project link if available</a>-->
        </div>
      </div>


      <div class="form-group" [hidden]="!editMode">
        <label for="projectFile">Project JSON :</label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="projectFile" (change)="onFileChanged($event)">
          <label class="custom-file-label" for="projectFile">{{selectedFile?.name || 'Choose file'}}</label>
        </div>
      </div>
      <br/>
      <hr/>
      <button (click)="editMode=true;" class="btn btn-primary" [hidden]="editMode">Edit</button>
      <div [hidden]="!editMode">
        <div class="row" >
          <button (click)="saveSettings()" class="btn btn-primary"  [hidden]="!editMode">Upload</button>
          <button (click)="editMode=false;selectedFile = null;summary = null;" class="btn btn-danger" [hidden]="!editMode">Cancel</button>
        </div>
      </div>

    </form>
  </div>
</div>
