import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { DuediligenceComponent } from './duediligence/duediligence.component';
import { AddprojectComponent } from './duediligence/addproject/addproject.component';
import { EditprojectComponent } from './duediligence/editproject/editproject.component';
import { ListprojectComponent } from './duediligence/listproject/listproject.component';
import { DdformComponent } from './duediligence/ddform/ddform.component';
import { DeaformComponent } from './duediligence/deaform/deaform.component';
import { HrformComponent } from './duediligence/hrform/hrform.component';
import { ItformComponent } from './duediligence/itform/itform.component';
import { MarketingformComponent } from './duediligence/marketingform/marketingform.component';
import { CensusComponent } from './census/census.component';
import {ConfigComponent} from "./census/config/config.component";
import {MainComponent} from "./main/main.component";
import {AuthGuardService} from "./auth-guard";
import {DDSettingsComponent} from "./duediligence/ddsettings/ddsettings.component";

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', component: MainComponent, canActivate: [AuthGuardService],
    children: [
     {path: '', redirectTo: 'listproject', pathMatch: 'full'},
     {path: 'addproject', component: AddprojectComponent, canActivate: [AuthGuardService]},
     {path: 'editproject', component: EditprojectComponent, canActivate: [AuthGuardService]},
     {path: 'listproject', component: ListprojectComponent, canActivate: [AuthGuardService]},
     {path: 'ddsettings', component: DDSettingsComponent, canActivate: [AuthGuardService]},

     // {path: 'census', component: CensusComponent, canActivate: [AuthGuardService]},
     // {path: 'census/config', component: ConfigComponent, canActivate: [AuthGuardService]},

   ]},
  {path: 'duediligenceform', component: DdformComponent, canActivate: [AuthGuardService]},
  {path: 'deaform', component: DeaformComponent, canActivate: [AuthGuardService]},
  {path: 'hrform', component: HrformComponent, canActivate: [AuthGuardService]},
  {path: 'itform', component: ItformComponent, canActivate: [AuthGuardService]},
  {path: 'marketingform', component: MarketingformComponent, canActivate: [AuthGuardService]},
  // {path: 'logout', component: LogoutComponent},
  // {path: 'ddadmin', component: DuediligenceComponent},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
