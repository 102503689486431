import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {AuthenticationService} from "./login/auth.service";

@Injectable(
  {providedIn: 'root'}
)
export class AuthGuardService implements CanActivate {
  constructor(private authenticationService: AuthenticationService, public router: Router) {}

  canActivate(): boolean {
    if (!this.authenticationService.isUserLoggedIn()) {

      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
