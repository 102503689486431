<app-menu></app-menu>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':spinnerModalDisplay}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content style-modal-spinner">
      <div class="text-center">
        <div class="spinner-border style-modal-spinner-dim" role="status">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':infoModalDisplay}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ infoModalTitle }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="hideInfoModalDisplay()"><span
          aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p>{{ infoModalMessage }}</p>
      </div>
      <div class="modal-footer">
        <br/>
      </div>
    </div>
  </div>
</div>

<div class="container mw-100 h-100">
  <div class="row h-100">
    <div class="col col-lg-2 bg-light">
      <app-usermenu></app-usermenu>
    </div>

    <div class="col col-lg-7">
      <h6 style="padding: 2px;"><a routerLink="/editproject" [queryParams]="{id: projectId}"><span class="bi bi-arrow-left"></span> Back to project</a></h6>
      <div [hidden]="!loaded" class="d-flex flex-row-reverse">
        <div class="p-2 font-weight-light"><small>Last updated
          on {{ deaForm.lastSavedOn | date : 'MM-dd-yyyy hh:mm:ss a' }}</small></div>
      </div>
      <h5 class="card-title">DEA Questionnaire&nbsp;<small>{{ projectName }}</small></h5>
      <div class="card" *ngIf="loaded">
        <div class="card-body">
          <form class="form-group">
            <!--<div class="alert alert-warning" *ngIf='invalidLogin'>{{errorMessage}}</div>-->
            <!--<div class="alert alert-success" *ngIf='loginSuccess'>{{successMessage}}</div>-->
            <!--                          <div class="alert alert-primary" role="alert" style="text-align:center;">Registrant Information</div>-->
            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Primary DEA Registrant’s Name</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" placeholder="Primary DEA Registrant’s Name"
                           name="deaRegistrantFirstName" [(ngModel)]="deaForm.registrantInfo.deaRegistrantLastName"/>
                  </div>

                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">DEA Registrant Number</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" id="deaRegistrantNumber"
                       placeholder="Enter DEA registrant number"
                       [(ngModel)]="deaForm.registrantInfo.deaRegistrantNumber" name="deaRegistrantNumber"/>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Practice Name</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" id="deaFacilityName"
                       placeholder="Enter Practice name" [(ngModel)]="deaForm.registrantInfo.deaFacilityName"
                       name="deaFacilityName"/>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Auditors Name</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" id="deaAuditorsName" placeholder="Auditors name"
                       [(ngModel)]="deaForm.registrantInfo.deaAuditorsName" name="deaAuditorsName"/>
              </div>
            </div>
            <!--                          End General Information-->
            <br/>
            <!--Begin Business Information-->

            <div class="alert alert-primary" role="alert" style="text-align:center;">Business Information</div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Indicate your Business Type</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_businesstype_traditional"
                             value="TRADITIONAL"
                             [checked]="deaForm.businessInfo.deaBusinessTypes.indexOf('TRADITIONAL') > -1"
                             (change)="onBusinessTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm"
                             for="dea_businesstype_traditional">Traditional</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_businesstype_emergency"
                             value="EMERGENCY"
                             [checked]="deaForm.businessInfo.deaBusinessTypes.indexOf('EMERGENCY') > -1"
                             (change)="onBusinessTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm"
                             for="dea_businesstype_emergency">Emergency</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_businesstype_research"
                             value="RESEARCH" [checked]="deaForm.businessInfo.deaBusinessTypes.indexOf('RESEARCH') > -1"
                             (change)="onBusinessTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_businesstype_research">Research</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_businesstype_mobile"
                             value="MOBILE" [checked]="deaForm.businessInfo.deaBusinessTypes.indexOf('MOBILE') > -1"
                             (change)="onBusinessTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_businesstype_mobile">Mobile</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox"
                             id="dea_businesstype_humane_society" value="HUMANE_SOCIETY"
                             [checked]="deaForm.businessInfo.deaBusinessTypes.indexOf('HUMANE_SOCIETY') > -1"
                             (change)="onBusinessTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_businesstype_humane_society">Humane
                        Society</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_businesstype_other"
                             value="OTHER" [checked]="deaForm.businessInfo.deaBusinessTypes.indexOf('OTHER') > -1"
                             (change)="onBusinessTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_businesstype_other">Other&nbsp;</label>
                      <input type="text" class="form-control form-control-sm" id="businessTypeOtherDesc"
                             placeholder="specify" name="businessTypeOtherDesc"
                             [(ngModel)]="deaForm.businessInfo.businessTypeOtherDesc"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Identify % of species worked with</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Canine %</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="percentCanine"
                             [(ngModel)]="deaForm.businessInfo.percentCanine"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Feline %</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="percentFeline"
                             [(ngModel)]="deaForm.businessInfo.percentFeline"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Exotics %</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="percentExotics"
                             [(ngModel)]="deaForm.businessInfo.percentExotics"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Other %</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="percentOther"
                             [(ngModel)]="deaForm.businessInfo.percentOther"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Normal Business Hours of operation</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <input type="text" class="form-control form-control-sm" id="normalBusinessHoursOfOperation"
                         placeholder="Enter normal business hours of operation" name="normalBusinessHoursOfOperation"
                         [(ngModel)]="deaForm.businessInfo.normalBusinessHoursOfOperation"/>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Provide ratio of method of payment by client</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Cash</div>
                      </div>
                      <input type="text" class="form-control form-control-sm" placeholder="" name="ratioPaymentCash"
                             [(ngModel)]="deaForm.businessInfo.ratioPaymentCash"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Credit</div>
                      </div>
                      <input type="text" class="form-control form-control-sm" placeholder="" name="ratioPaymentCredit"
                             [(ngModel)]="deaForm.businessInfo.ratioPaymentCredit"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Other</div>
                      </div>
                      <input type="text" class="form-control form-control-sm" placeholder="" name="ratioPaymentOther"
                             [(ngModel)]="deaForm.businessInfo.ratioPaymentOther"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Provide ratio of in and out of state clients</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">In-State</div>
                      </div>
                      <input type="text" class="form-control form-control-sm" placeholder="" name="ratioInState"
                             [(ngModel)]="deaForm.businessInfo.ratioInState"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Out-State</div>
                      </div>
                      <input type="text" class="form-control form-control-sm" placeholder="" name="ratioOutState"
                             [(ngModel)]="deaForm.businessInfo.ratioOutState"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">How many DVMs</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">DVM</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="dvmCount"
                             [(ngModel)]="deaForm.businessInfo.dvmCount"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">LVT</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="lvtCount"
                             [(ngModel)]="deaForm.businessInfo.lvtCount"/>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Euthanasia Tech</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="euthTechCount"
                             [(ngModel)]="deaForm.businessInfo.euthTechCount"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Research</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="researchCount"
                             [(ngModel)]="deaForm.businessInfo.researchCount"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Average # of Animals treated</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Daily</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="animTreatAvgDaily"
                             [(ngModel)]="deaForm.businessInfo.animTreatAvgDaily"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Weekly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="animTreatAvgWeekly"
                             [(ngModel)]="deaForm.businessInfo.animTreatAvgWeekly"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Monthly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="animTreatAvgMonthly" [(ngModel)]="deaForm.businessInfo.animTreatAvgMonthly"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Average # of Animals euthanized</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Daily</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="animEuthAvgDaily"
                             [(ngModel)]="deaForm.businessInfo.animEuthAvgDaily"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Weekly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="animEuthAvgWeekly"
                             [(ngModel)]="deaForm.businessInfo.animEuthAvgWeekly"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Monthly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="animEuthAvgMonthly"
                             [(ngModel)]="deaForm.businessInfo.animEuthAvgMonthly"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Average # of Animals treated with controlled
                substance</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Daily</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="animTreatCsAvgDaily" [(ngModel)]="deaForm.businessInfo.animTreatCsAvgDaily"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Weekly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="animTreatCsAvgWeekly" [(ngModel)]="deaForm.businessInfo.animTreatCsAvgWeekly"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Monthly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="animTreatCsAvgMonthly" [(ngModel)]="deaForm.businessInfo.animTreatCsAvgMonthly"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Average # of controlled substance dispensed
                to</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Daily</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="animCsDispAvgDaily"
                             [(ngModel)]="deaForm.businessInfo.animCsDispAvgDaily"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Weekly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="animCsDispAvgWeekly" [(ngModel)]="deaForm.businessInfo.animCsDispAvgWeekly"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Monthly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="animCsDispAvgMonthly" [(ngModel)]="deaForm.businessInfo.animCsDispAvgMonthly"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Average # of surgeries performed</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Daily</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="surgeryAvgDaily"
                             [(ngModel)]="deaForm.businessInfo.surgeryAvgDaily"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Weekly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="surgeryAvgWeekly"
                             [(ngModel)]="deaForm.businessInfo.surgeryAvgWeekly"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Monthly</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder="" name="surgeryAvgMonthly"
                             [(ngModel)]="deaForm.businessInfo.surgeryAvgMonthly"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Employee responsible for ordering</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" placeholder="First name"
                           name="orderingEmpFirstName" [(ngModel)]="deaForm.businessInfo.orderingEmpFirstName"/>
                  </div>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" placeholder="Last name"
                           name="orderingEmpLastName" [(ngModel)]="deaForm.businessInfo.orderingEmpLastName"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">E-mail of employee responsible for ordering</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" id="orderingEmpEmail"
                           placeholder="Enter DEA E-mail Address" name="orderingEmpEmail"
                           [(ngModel)]="deaForm.businessInfo.orderingEmpEmail"/>
                  </div>
                  <div class="col">
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Employee responsible for record keeping</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" placeholder="First name"
                           name="recordKeepingEmpFirstName"
                           [(ngModel)]="deaForm.businessInfo.recordKeepingEmpFirstName"/>
                  </div>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" placeholder="Last name"
                           name="recordKeepingEmpLastName" [(ngModel)]="deaForm.businessInfo.recordKeepingEmpLastName"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">E-mail of employee responsible for record
                keeping</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" id="recordKeepingEmpEmail"
                           placeholder="Enter DEA E-mail Address" name="recordKeepingEmpEmail"
                           [(ngModel)]="deaForm.businessInfo.recordKeepingEmpEmail"/>
                  </div>
                  <div class="col">
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Employee authorized to sign DEA form 222</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" placeholder="First name"
                           name="deaSigningAuthEmpFirstName"
                           [(ngModel)]="deaForm.businessInfo.deaSigningAuthEmpFirstName"/>
                  </div>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" placeholder="Last name"
                           name="deaSigningAuthEmpLastName"
                           [(ngModel)]="deaForm.businessInfo.deaSigningAuthEmpLastName"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">If not a vet, does authorized signer has power of
                attorney?</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="radio" name="deaAuthHasPoa"
                             id="deaAuthHasPoaYes" value="YES" [(ngModel)]="deaForm.businessInfo.deaAuthHasPoa"/>
                      <label class="form-check-label col-form-label-sm" for="deaAuthHasPoaYes">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="radio" name="deaAuthHasPoa"
                             id="deaAuthHasPoaNo" value="NO" [(ngModel)]="deaForm.businessInfo.deaAuthHasPoa"/>
                      <label class="form-check-label col-form-label-sm" for="deaAuthHasPoaNo">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Indicate types of DEA product you order</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_producttype_antiseizure"
                             value="ANTI_SEIZURE"
                             [checked]="deaForm.businessInfo.deaProductTypes.indexOf('ANTI_SEIZURE') > -1"
                             (change)="onDeaProductTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_producttype_antiseizure">Anti
                        Seizure</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_producttype_painmgmt"
                             value="PAIN_MGMT"
                             [checked]="deaForm.businessInfo.deaProductTypes.indexOf('PAIN_MGMT') > -1"
                             (change)="onDeaProductTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_producttype_painmgmt">Pain
                        Management</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_producttype_euthanasia"
                             value="EUTHANASIA"
                             [checked]="deaForm.businessInfo.deaProductTypes.indexOf('EUTHANASIA') > -1"
                             (change)="onDeaProductTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm"
                             for="dea_producttype_euthanasia">Euthanasia</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_producttype_induction"
                             value="INDUCTION"
                             [checked]="deaForm.businessInfo.deaProductTypes.indexOf('INDUCTION') > -1"
                             (change)="onDeaProductTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm"
                             for="dea_producttype_induction">Induction</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_producttype_tranquilizer"
                             value="TRANQUILIZER"
                             [checked]="deaForm.businessInfo.deaProductTypes.indexOf('TRANQUILIZER') > -1"
                             (change)="onDeaProductTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm"
                             for="dea_producttype_tranquilizer">Tranquilizers</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox"
                             id="dea_producttype_listonechemical" value="LIST_ONE_CHEMICAL"
                             [checked]="deaForm.businessInfo.deaProductTypes.indexOf('LIST_ONE_CHEMICAL') > -1"
                             (change)="onDeaProductTypeCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_producttype_listonechemical">List 1
                        Chemicals</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">List top 10 most common controlled substances used,
                strength and form, and monthly quantity</label>
              <div class="col-sm-9">
                <div *ngFor="let controlledSubstance of deaForm.businessInfo.controlledSubstances; let i=index">
                  <div class="form-row" style="margin-top:5px">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" placeholder="Name" name="name-{{i}}"
                             [(ngModel)]="deaForm.businessInfo.controlledSubstances[i].name"/>
                    </div>
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" placeholder="Strength"
                             name="strength-{{i}}" [(ngModel)]="deaForm.businessInfo.controlledSubstances[i].strength"/>
                    </div>
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" placeholder="Form" name="form-{{i}}"
                             [(ngModel)]="deaForm.businessInfo.controlledSubstances[i].form"/>
                    </div>
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" placeholder="Monthly Qty"
                             name="monthlyQuantity-{{i}}"
                             [(ngModel)]="deaForm.businessInfo.controlledSubstances[i].monthlyQuantity"/>
                    </div>
                    <div class="col">
                      <button type="submit" (click)="removeDeaControlledSubstance(controlledSubstance)"
                              class="btn btn-outline-danger btn-sm">delete
                      </button>
                    </div>
                  </div>
                </div>
                <button style="margin-top:5px" type="submit" (click)="addDeaControlledSubstance()"
                        class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;
                </button>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">How often do you order controlled substances</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrdFreq_daily"
                             value="DAILY" [checked]="deaForm.businessInfo.csOrderFrequencies.indexOf('DAILY') > -1"
                             (change)="onCsOrderFreqCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrdFreq_daily">Daily</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrdFreq_weekly"
                             value="WEEKLY" [checked]="deaForm.businessInfo.csOrderFrequencies.indexOf('WEEKLY') > -1"
                             (change)="onCsOrderFreqCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrdFreq_weekly">Weekly</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrdFreq_monthly"
                             value="MONTHLY" [checked]="deaForm.businessInfo.csOrderFrequencies.indexOf('MONTHLY') > -1"
                             (change)="onCsOrderFreqCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrdFreq_monthly">Monthly</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrdFreq_bimonthly"
                             value="BI_MONTHLY"
                             [checked]="deaForm.businessInfo.csOrderFrequencies.indexOf('BI_MONTHLY') > -1"
                             (change)="onCsOrderFreqCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrdFreq_bimonthly">Bi
                        Monthly</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrdFreq_quarterly"
                             value="QUARTERLY"
                             [checked]="deaForm.businessInfo.csOrderFrequencies.indexOf('QUARTERLY') > -1"
                             (change)="onCsOrderFreqCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrdFreq_quarterly">Quarterly</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrdFreq_annually"
                             value="ANNUALLY"
                             [checked]="deaForm.businessInfo.csOrderFrequencies.indexOf('ANNUALLY') > -1"
                             (change)="onCsOrderFreqCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrdFreq_annually">Annually</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">How do you order controlled substances</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrd_online"
                             value="ONLINE" [checked]="deaForm.businessInfo.csOrderMethods.indexOf('ONLINE') > -1"
                             (change)="onCsOrderMethodCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrd_online">Online</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrd_phone"
                             value="PHONE" [checked]="deaForm.businessInfo.csOrderMethods.indexOf('PHONE') > -1"
                             (change)="onCsOrderMethodCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrd_phone">Phone</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrd_mail" value="MAIL"
                             [checked]="deaForm.businessInfo.csOrderMethods.indexOf('MAIL') > -1"
                             (change)="onCsOrderMethodCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrd_mail">Mail</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="checkbox" id="dea_csrOrd_other"
                             value="OTHER" [checked]="deaForm.businessInfo.csOrderMethods.indexOf('OTHER') > -1"
                             (change)="onCsOrderMethodCheckboxChange($event)"/>
                      <label class="form-check-label col-form-label-sm" for="dea_csrOrd_other">Other</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Do you plan to order from more than one
                supplier?</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="radio" name="orderFromMultipleSuppliers"
                             id="orderFromMultipleSuppliersYes" value="YES"
                             [(ngModel)]="deaForm.businessInfo.orderFromMultipleSuppliers"/>
                      <label class="form-check-label col-form-label-sm" for="orderFromMultipleSuppliersYes">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="radio" name="orderFromMultipleSuppliers"
                             id="orderFromMultipleSuppliersNo" value="NO"
                             [(ngModel)]="deaForm.businessInfo.orderFromMultipleSuppliers"/>
                      <label class="form-check-label col-form-label-sm" for="orderFromMultipleSuppliersNo">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">In a month, what % are (should=100%)</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Controlled Substances (%)</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="monthlyPctControlledSubstance"
                             [(ngModel)]="deaForm.businessInfo.monthlyPctControlledSubstance"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">List 1 Chemicals (%)</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="monthlyPctListOneChem" [(ngModel)]="deaForm.businessInfo.monthlyPctListOneChem"/>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Non Controlled Prescription (%)</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="monthlyPctNonCntrldPrescription"
                             [(ngModel)]="deaForm.businessInfo.monthlyPctNonCntrldPrescription"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">OTC Non-Prescription (%)</div>
                      </div>
                      <input type="number" class="form-control form-control-sm" placeholder=""
                             name="monthlyPctOtcNonPrescription"
                             [(ngModel)]="deaForm.businessInfo.monthlyPctOtcNonPrescription"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Do you plan on ordering Nembutal product?</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="radio" name="orderNembutalProduct"
                             id="orderNembutalProductYes" value="YES"
                             [(ngModel)]="deaForm.businessInfo.orderNembutalProduct"/>
                      <label class="form-check-label col-form-label-sm" for="orderFromMultipleSuppliersYes">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="radio" name="orderNembutalProduct"
                             id="orderNembutalProductNo" value="NO"
                             [(ngModel)]="deaForm.businessInfo.orderNembutalProduct"/>
                      <label class="form-check-label col-form-label-sm" for="orderFromMultipleSuppliersNo">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Does your website offer pharmaceuticals?</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="radio" name="pharmaOnWebsite"
                             id="pharmaOnWebsiteYes" value="YES" [(ngModel)]="deaForm.businessInfo.pharmaOnWebsite"/>
                      <label class="form-check-label col-form-label-sm" for="pharmaOnWebsiteYes">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input form-control-sm" type="radio" name="pharmaOnWebsite"
                             id="pharmaOnWebsiteNo" value="NO" [(ngModel)]="deaForm.businessInfo.pharmaOnWebsite"/>
                      <label class="form-check-label col-form-label-sm" for="pharmaOnWebsiteNo">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label-sm text-right">Who is your online pharmacy provider and what is the
                account number?</label>
              <div class="col-sm-9">
                <div class="form-row">
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" placeholder="Provider Name"
                           name="onlinePharmacyProviderName"
                           [(ngModel)]="deaForm.businessInfo.onlinePharmacyProviderName"/>
                  </div>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm" placeholder="Account Number"
                           name="onlinePharmacyProviderAccountNumber"
                           [(ngModel)]="deaForm.businessInfo.onlinePharmacyProviderAccountNumber"/>
                  </div>
                </div>
              </div>
            </div>

            <!--End Business Information-->
            <br/>
            <!--Start Audit Questions Information-->
            <div class="alert alert-primary" role="alert" style="text-align:center;">Audit Questions</div>


            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Does every DVM on site possess a current DEA
                  registration?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="possessRegistration" placeholder=""
                             name="possessRegistration" [(ngModel)]="deaForm.auditInfo.possessRegistration"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If some of the DVMs are not registered with DEA,
                  are they exempt from registration requirements as defined in 21 CFR 1301.22(b)</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="exemptRegistration" placeholder=""
                             name="exemptRegistration" [(ngModel)]="deaForm.auditInfo.exemptRegistration"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row col-sm-12 middle-align">
                <p class="col-form-label-sm smaller-label">
                  "MANUFACTURERS, DISTRIBUTORS, AND DISPENSERS OF CONTROLLED SUBSTANCES EXCEPTIONS TO REGISTRATION AND
                  FEES<br/>
                  Source: §1301.22(b) (Abbreviated) Exemption of agents and employees; affiliated practitioners. (b) “An
                  individual practitioner who is an agent or employee of another practitioner registered to dispense
                  controlled substances may, when acting in the normal course of business or employment, administer or
                  dispense (<span class="underline-text">other than by issuance of prescription</span>) controlled
                  substances if and to the extent that such individual practitioner is authorized or permitted to do so
                  by the jurisdiction in which he or she practices, under the registration of the employer or principal
                  practitioner in lieu of being registered him/herself".<br/>
                  <span class="underline-text italic-text">Special Note: A DVM may not prescribe controlled substances if he/she does not have a DEA number</span>
                </p>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Does the practice have a current Primary DEA
                  Registrant who orders the controlled substances for all the DVM registrants in the practice to
                  use?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="hasControlledSubstances"
                             placeholder="" name="hasControlledSubstances"
                             [(ngModel)]="deaForm.auditInfo.hasControlledSubstances"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Specify expiry date of each DVM's
                  registration</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="expiryDate" placeholder=""
                             name="expiryDate" [(ngModel)]="deaForm.auditInfo.expiryDate"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">What schedules of controlled substances are covered
                  under registration (CII, CIII, CIV, CV )?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="scheduleControlledSubstances"
                             placeholder="" name="scheduleControlledSubstances"
                             [(ngModel)]="deaForm.auditInfo.scheduleControlledSubstances"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is the DEA registration assigned specifically to
                  the practice that is being audited (e.g., does DEA Registration address match the practice's
                  address)?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="addressMatch" placeholder=""
                             name="addressMatch" [(ngModel)]="deaForm.auditInfo.addressMatch"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If the DEA registration is not for the practice
                  address, what activity is being performed at the site that is covered under another registration as
                  defined in 21 CFR 1301.13(e)?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="activityPerformed" placeholder=""
                             name="activityPerformed" [(ngModel)]="deaForm.auditInfo.activityPerformed"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row col-sm-12 middle-align">
                <label class="col-form-label-sm smaller-label">"APPLICATION FOR REGISTRATION 21 CFR §1301.13; TIME FOR
                  APPLICATION, EXPIRATION DATE; REGISTRATION FOR INDEPENDENT ACTIVITIES; APPLICATION FORMS; FEES;
                  CONTENTS AND SIGNATURE; COINCIDENT ACTIVITIES: Any person who is required to be registered and who is
                  not so registered, shall make application for registration for one of the following groups of
                  controlled substances activities, which are deemed to be independent of each other. Application for
                  each registration shall be made on the indicated form, and shall be accompanied by the indicated fee.
                  Fee payments shall be made in the form of a personal, certified, or cashier's check or money order
                  made payable to the “Drug Enforcement Administration”. Generally, the application fees are not
                  refundable; however, they may be issued in limited circumstances at the discretion of the
                  Administrator. These circumstances include: Applicant error, such as duplicate payments, payment for
                  incorrect business activities, or payments made by persons who are exempt under this section from
                  application or renewal fees; DEA error; and death of a registrant within the first year of the
                  three-year registration cycle. Any person, when registered to engage in the activities described in
                  each subparagraph in this paragraph, shall be authorized to engage in the coincident activities
                  described without obtaining a registration to engage in such coincident activities, provided that,
                  unless specifically exempted, he/she complies with all requirements and duties prescribed by law for
                  persons registered to engage in such coincident activities. Any person who engages in more than one
                  group of independent activities shall obtain a separate registration for each group of activities,
                  except as provided in this paragraph under coincident activities. A single registration to engage in
                  any group of independent activities listed below may include one or more controlled substances listed
                  in the schedules authorized in that group of independent activities. A person registered to conduct
                  research with controlled substances listed in Schedule I may conduct research with any substances
                  listed in Schedule I for which he/she has filed and had approved a research protocol".</label>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are all the DVMs current registrations kept on
                  site?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="keptOnSite" placeholder=""
                             name="keptOnSite" [(ngModel)]="deaForm.auditInfo.keptOnSite"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If the current DVM registrations are not kept at
                  the practice, is there a letter to DEA requesting a central recordkeeping site?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="keptOnSiteNote" placeholder=""
                             name="keptOnSiteNote" [(ngModel)]="deaForm.auditInfo.keptOnSiteNote"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Does the Primary Registrant order the controlled
                  substances? If not, who does?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="orderControlledSubstances"
                             placeholder="" name="orderControlledSubstances"
                             [(ngModel)]="deaForm.auditInfo.orderControlledSubstances"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If not, who does?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="orderControlledSubstancesText"
                             placeholder="" name="orderControlledSubstancesText"
                             [(ngModel)]="deaForm.auditInfo.orderControlledSubstancesText"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is there a current State registration on site?
                  (State specific)</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="currentStateOnSite" placeholder=""
                             name="currentStateOnSite" [(ngModel)]="deaForm.auditInfo.currentStateOnSite"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are there any controlled substances at the practice
                  and/or activities being performed that are not supported by the current registration?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="notSupportedControlledSubstances"
                             placeholder="" name="notSupportedControlledSubstances"
                             [(ngModel)]="deaForm.auditInfo.notSupportedControlledSubstances"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the physical security controls for the storage
                  area (safe or steel cabinet) that contain/ hold any CI through CV controlled substances stored in a
                  securely locked, substantially constructed cabinet that is firmly secured to the floor or
                  wall?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="physicalSecurityControls"
                             placeholder="" name="physicalSecurityControls"
                             [(ngModel)]="deaForm.auditInfo.physicalSecurityControls"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the controlled substances delivered directly to
                  an authorized employee and immediately counted, stored & logged in the correct controlled substance
                  closed and/or open logs, by schedule.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="directlyDeliveredControlledSubstances"
                             placeholder="" name="directlyDeliveredControlledSubstances"
                             [(ngModel)]="deaForm.auditInfo.directlyDeliveredControlledSubstances"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is there a video camera above the controlled
                  substance lock box to improve security? (Not required unless the practice has been the victim of a
                  theft, but strongly suggested).</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="videoCamControlledSubstances"
                             placeholder="" name="videoCamControlledSubstances"
                             [(ngModel)]="deaForm.auditInfo.videoCamControlledSubstances"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Has a controlled substance standard operating
                  policy been developed and have staff trained in the increased security requirements of controlled
                  substances?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="hasStandardOperatingPolicy"
                             placeholder="" name="hasStandardOperatingPolicy"
                             [(ngModel)]="deaForm.auditInfo.hasStandardOperatingPolicy"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is the controlled substance lockbox and the
                  controlled substances stored at the address of the practice's Primary Registrant, as identified on the
                  DEA Registration?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="hasLockbox" placeholder=""
                             name="hasLockbox" [(ngModel)]="deaForm.auditInfo.hasLockbox"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Does the controlled substance lockbox location have
                  minimal traffic flow?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="lockboxLocationMinTrafficFlow"
                             placeholder="" name="lockboxLocationMinTrafficFlow"
                             [(ngModel)]="deaForm.auditInfo.lockboxLocationMinTrafficFlow"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is the controlled substance lockbox kept locked
                  when substances are not being removed for immediate patient administration or dispensing?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="lockboxLocked" placeholder=""
                             name="lockboxLocked" [(ngModel)]="deaForm.auditInfo.lockboxLocked"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are controlled substances requiring refrigeration
                  securely stored and locked in a lockbox inside the refrigerator? Is there a corresponding log?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="secureRefrigerationRequired"
                             placeholder="" name="secureRefrigerationRequired"
                             [(ngModel)]="deaForm.auditInfo.secureRefrigerationRequired"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is the access to controlled substances kept to a
                  minimal number of authorized employees?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="accessMinimized" placeholder=""
                             name="accessMinimized" [(ngModel)]="deaForm.auditInfo.accessMinimized"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are keys to the controlled substance lockbox locked
                  up or secured when not in use?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="keysLocking" placeholder=""
                             name="keysLocking" [(ngModel)]="deaForm.auditInfo.keysLocking"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Can locks on the controlled substance lockbox be
                  changed if an employee is terminated or a theft occurs?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="lockChangesAbility" placeholder=""
                             name="lockChangesAbility" [(ngModel)]="deaForm.auditInfo.lockChangesAbility"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is there an employee sign in sheet at the front of
                  every logbook containing the following information? Date employee was granted access to controlled
                  substances, employee's full name printed and in cursive, employee's initials.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="sheetSigning" placeholder=""
                             name="sheetSigning" [(ngModel)]="deaForm.auditInfo.sheetSigning"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is there a master list of all employees who have
                  access to the controlled substances. Master list must be kept private and contain the date each
                  employee was granted access to the controlled substances, their full name, title & licensure
                  designation, social security number, home address and cell number?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="masterListExistance" placeholder=""
                             name="masterListExistance" [(ngModel)]="deaForm.auditInfo.masterListExistance"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Have background checks been performed on all staff
                  who have access to controlled substances? Are the background checks renewed annually?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="backgroundChecksPerforming"
                             placeholder="" name="backgroundChecksPerforming"
                             [(ngModel)]="deaForm.auditInfo.backgroundChecksPerforming"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is there an employee screening procedure in place
                  (per recommendations of Part 1301.90)?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="screeningProcedureExists"
                             placeholder="" name="screeningProcedureExists"
                             [(ngModel)]="deaForm.auditInfo.screeningProcedureExists"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row col-sm-12 middle-align">
                <label class="col-form-label-sm smaller-label">"PART 1301.90 -- REGISTRATION OF MANUFACTURERS,
                  DISTRIBUTORS, AND <span class="underline-text">DISPENSERS</span> OF CONTROLLED SUBSTANCES Employee
                  Screening - Sec. 1301.90 Employee screening procedures. (Abbreviated)<br/>
                  It is the position of DEA that the obtaining of certain information is vital to fairly assess the
                  likelihood of an employee committing a drug security breach. The need to know this information is a
                  matter of business necessity, essential to overall controlled substances security. In this regard, it
                  is believed that conviction of crimes and unauthorized use of controlled substances are activities
                  that are proper subjects for inquiry. It is, therefore, assumed that the following questions will
                  become a part of an employer's comprehensive employee screening program:<br/>
                  <span class="underline-text">Question.</span> Within the past five years, have you been convicted of a
                  felony, or within the past two years, of any misdemeanor or are you presently formally charged with
                  committing a criminal offense? (Do not include any traffic violations, juvenile offenses or military
                  convictions, except by general court-martial.) If the answer is yes, furnish details of conviction,
                  offense, location, date and sentence.
                  <span class="underline-text">Question.</span> In the past three years, have you ever knowingly used
                  any narcotics, amphetamines or barbiturates, other than those prescribed to you by a physician? If the
                  answer is yes, furnish details.
                  <span class="underline-text">Advice.</span> An authorization, in writing, that allows inquiries to be
                  made of courts and law enforcement agencies for possible pending charges or convictions must be
                  executed by a person who is allowed to work in an area where access to controlled substances clearly
                  exists. A person must be advised that any false information or omission of information will jeopardize
                  his or her position with respect to employment. The application for employment should inform a person
                  that information furnished or recovered as a result of any inquiry will not necessarily preclude
                  employment, but will be considered as part of an overall evaluation of the person's qualifications.
                  The maintaining of fair employment practices, the protection of the person's right of privacy, and the
                  assurance that the results of such inquiries will be treated by the employer in confidence will be
                  explained to the employee".</label>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is this employee screening procedure practice-wide
                  or just those employees who have access to the controlled substances?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="screeningProcedureSpread"
                             placeholder="" name="screeningProcedureSpread"
                             [(ngModel)]="deaForm.auditInfo.screeningProcedureSpread"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Does the practice require drug screening? If so,
                  please specify in the notes section what portion of total practice employees are drug screened (e.g.,
                  all or only those with access to controlled substances, random, etc.).</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="drugScreeningRequired" placeholder=""
                             name="drugScreeningRequired" [(ngModel)]="deaForm.auditInfo.drugScreeningRequired"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is the drug screening process: (1) the same for all
                  new employees, (2) randomly performed throughout the practice, (3) performed on an annual basis of
                  certain employees working with controlled substances, and/or (4) upon reasonable cause of suspicion of
                  personnel involved with diversion? Please specify by numbers in the notes section.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="drugScreeningProcess" placeholder=""
                             name="drugScreeningProcess" [(ngModel)]="deaForm.auditInfo.drugScreeningProcess"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Does the practice have controlled substance logs by
                  schedule (aka records) and supporting documents for the last two years on site as defined in Part
                  1304.03(a): </label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="scheduledLogs" placeholder=""
                             name="scheduledLogs" [(ngModel)]="deaForm.auditInfo.scheduledLogs"/>
                    </div>
                  </div>
                </div>

                <div class="form-row col-sm-12 middle-align">
                  <label class="col-form-label-sm smaller-label">"Every registrant, shall maintain the records and
                    inventory 21 CFR § 1304.03 Persons required to keep records and file reports. (Abbreviated) (a)
                    Every registrant, shall maintain the records and inventories and shall file the reports required by
                    this part, except as exempted by this section. Any registrant that is authorized to conduct other
                    activities without being registered to conduct those activities, pursuant to §§ 1301.22(b), 1307.11,
                    1307.13, or part 1317 of this chapter, shall maintain the records and inventories and shall file the
                    reports required by this part for persons registered or authorized to conduct such activities. This
                    latter requirement should not be construed as requiring stocks of controlled substances being used
                    in various activities under one registration to be stored separately, nor that separate records are
                    required for each activity. The intent of the Administration is to permit the registrant to keep one
                    set of records which are adapted by the registrant to account for controlled substances used in any
                    activity. Also, the Administration does not wish to require separate stocks of the same substance to
                    be purchased and stored for separate activities. Otherwise, there is no advantage gained by
                    permitting several activities under one registration".</label>
                </div>

              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If no (to the above), is there a DEA-approved
                  central recordkeeping site where the practice's records are kept and is there a letter from the DEA
                  authorizing them to do so?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="scheduledLogsStorage" placeholder=""
                             name="scheduledLogsStorage" [(ngModel)]="deaForm.auditInfo.scheduledLogsStorage"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Has the practice performed an inventory in the last
                  two years (biennial) as defined in Part 1304.11? (Note: your State's inventory requirements may
                  differ). "§ 1304.11 Inventory requirements.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="inventoryInLastTwoYears"
                             placeholder="" name="inventoryInLastTwoYears"
                             [(ngModel)]="deaForm.auditInfo.inventoryInLastTwoYears"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row col-sm-12 middle-align">
                <label class="col-form-label-sm smaller-label"><span
                  class="underline-text">(a) General requirements.</span> Each inventory shall contain a complete and
                  accurate record of all controlled substances on hand on the date the inventory is taken, and shall be
                  maintained in written, typewritten, or printed form at the registered location. An inventory taken by
                  use of an oral recording device must be promptly transcribed. Controlled substances shall be deemed to
                  be “on hand” if they are in the possession of or under the control of the registrant, including
                  substances returned by a customer, ordered by a customer but not yet invoiced, stored in a warehouse
                  on behalf of the registrant, and substances in the possession of employees of the registrant and
                  intended for distribution as complimentary samples. A separate inventory shall be made for each
                  registered location and each independent activity registered, except as provided in paragraph (e)(4)
                  of this section. In the event controlled substances in the possession or under the control of the
                  registrant are stored at a location for which he/she is not registered, the substances shall be
                  included in the inventory of the registered location to which they are subject to control or to which
                  the person possessing the substance is responsible. The inventory may be taken either as of opening of
                  business or as of the close of business on the inventory date and it shall be indicated on the
                  inventory.<br/>
                  <span class="underline-text">(b) Initial inventory date.</span> Every person required to keep records
                  shall take an inventory of all stocks of controlled substances on hand on the date he/she first
                  engages in the manufacture, distribution, or dispensing of controlled substances, in accordance with
                  paragraph (e) of this section as applicable. In the event a person commences business with no
                  controlled substances on hand, he/she shall record this fact as the initial inventory.<br/>
                  <span class="underline-text">(c) Biennial inventory date.</span> After the initial inventory is taken,
                  the registrant shall take a new inventory of all stocks of controlled substances on hand at least
                  every two years. The biennial inventory may be taken on any date which is within two years of the
                  previous biennial inventory date.<br/>
                  <span class="underline-text">(d) Inventory date for newly controlled substances.</span> On the
                  effective date of a rule by the Administrator pursuant to §§ 1308.45, 1308.46, or 1308.47 of this
                  chapter adding a substance to any schedule of controlled substances, which substance was, immediately
                  prior to that date, not listed on any such schedule, every registrant required to keep records who
                  possesses that substance shall take an inventory of all stocks of the substance on hand. Thereafter,
                  such substance shall be included in each inventory made by the registrant pursuant to paragraph (c) of
                  this section".</label>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If yes (to the above), does the inventory reflect
                  <span class="underline-text">all</span> controlled substances “on hand”? Please note if the inventory
                  reflects being taken on the opening of the business day or the closing of the business day. Indicate
                  if it does not indicate either one.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="inventoryIndication" placeholder=""
                             name="inventoryIndication" [(ngModel)]="deaForm.auditInfo.inventoryIndication"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">In the event that the biennial inventory date has
                  been changed – (1) is the new date more than 6 months from the previous biennial inventory date or (2)
                  is the next biennial inventory more than 24 months from the previous inventory?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="inventoryDateChanged" placeholder=""
                             name="inventoryDateChanged" [(ngModel)]="deaForm.auditInfo.inventoryDateChanged"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the controlled substance logs, general
                  inventory and supporting documents kept at the practice for a minimum of two years? (Note your State's
                  recordkeeping requirements may differ, so always follow the most stringent regulation).</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="logsKeeping" placeholder=""
                             name="logsKeeping" [(ngModel)]="deaForm.auditInfo.logsKeeping"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Was a closing inventory performed (end of year,
                  moving, transferring, retiring, etc.)?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="closingInventory" placeholder=""
                             name="closingInventory" [(ngModel)]="deaForm.auditInfo.closingInventory"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the controlled substance logs sufficient in
                  detail to be in compliance with 21 CFR 1304.21 Abbreviated=Every registrant required to keep records
                  pursuant to § 1304.03 shall maintain, on a current basis, a complete and accurate record of each
                  substance received, sold, delivered, exported, or otherwise disposed of by him/her. If not, specify
                  deficiencies.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="logsCompliance21CFR" placeholder=""
                             name="logsCompliance21CFR" [(ngModel)]="deaForm.auditInfo.logsCompliance21CFR"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the controlled substance logs and continuing
                  records in compliance with Part 1304.22(c)? If not, specify deficiencies.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="logsCompliance1304" placeholder=""
                             name="logsCompliance1304" [(ngModel)]="deaForm.auditInfo.logsCompliance1304"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row col-sm-12 middle-align">
                <label class="col-form-label-sm smaller-label">"21 CFR § 1304.22 Records for manufacturers,
                  distributors, dispensers, researchers, importers, exporters, registrants that reverse distribute, and
                  collectors. (Abbreviated)<br/>
                  Each person registered or authorized (by §§ 1301.13(e), 1307.11, 1307.13, or part 1317 of this
                  chapter) to manufacture, distribute, <span class="underline-text">dispense</span>, import, export,
                  reverse distribute, destroy, conduct research with controlled substances, or collect controlled
                  substances from ultimate users, shall maintain records with the information listed in paragraphs (a)
                  through (f) of this section.<br/>
                  (a) Records for manufacturers. Each person registered or authorized to manufacture controlled
                  substances shall maintain records with the following information:<br/>
                  (2) For each controlled substance in finished form,<br/>
                  (i) The name of the substance;<br/>
                  (ii) Each finished form (e.g., 10-milligram tablet or 10-milligram concentration per fluid ounce or
                  milliliter) and the number of units or volume of finished form in each commercial container (e.g.,
                  100-tablet bottle or 3-milliliter vial);<br/>
                  (iv) The number of units of finished forms and/or commercial containers acquired from other persons,
                  including the date of and number of units and/or commercial containers in each acquisition to
                  inventory and the name, address, and registration number of the person from whom the units were
                  acquired;<br/>
                  (vii) The number of commercial containers distributed to other persons, including the date of and
                  number of containers in each reduction from inventory, and the name, address, and registration number
                  of the person to whom the containers were distributed; (viii) The number of commercial containers
                  exported directly by the registrant (under a registration as an exporter), including the date, number
                  of containers and export permit or declaration number for each exportation; and<br/>
                  (ix) The number of units of finished forms and/or commercial containers distributed or disposed of in
                  any other manner by the registrant (e.g., by distribution of complimentary samples or by destruction),
                  including the date and manner of distribution or disposal, the name, address, and registration number
                  of the person to whom distributed, and the quantity in finished form distributed or disposed.<br/>
                  (c) Records for <span class="underline-text">dispensers</span> and researchers. Each person registered
                  or authorized to dispense or conduct research with controlled substances shall maintain records with
                  the same information required of manufacturers pursuant to paragraph (a)(2)(i), (ii), (iv), (vii), and
                  (ix) of this section. In addition, records shall be maintained of the number of units or volume of
                  such finished form dispensed, including the name and address of the person to whom it was dispensed,
                  the date of dispensing, the number of units or volume dispensed, and the written or typewritten name
                  or initials of the individual who dispensed or administered the substance on behalf of the dispenser.
                  In addition to the requirements of this paragraph, practitioners dispensing gamma-hydroxybutyric acid
                  under a prescription must also comply with § 1304.26".
                </label>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the controlled substance logs and supporting
                  documents retained on site for a minimum of the last two years? (Note your State's recordkeeping
                  requirements may differ, so always follow the most stringent regulation).</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="logsRetention" placeholder=""
                             name="logsRetention" [(ngModel)]="deaForm.auditInfo.logsRetention"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the controlled substance inventory logs readily
                  retrievable when asked by a DEA Auditor?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="logsRetrievability" placeholder=""
                             name="logsRetrievability" [(ngModel)]="deaForm.auditInfo.logsRetrievability"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are schedule I - II inventory records kept separate
                  from Schedule III - V inventory records (POs, invoices, unused and executed 222 Forms, etc.)?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="inventoryRecordsSeparation"
                             placeholder="" name="inventoryRecordsSeparation"
                             [(ngModel)]="deaForm.auditInfo.inventoryRecordsSeparation"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Were exact inventory quantities and amounts
                  reported for schedule I - II controlled substances?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="inventoryReportFirst" placeholder=""
                             name="inventoryReportFirst" [(ngModel)]="deaForm.auditInfo.inventoryReportFirst"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Were exact inventory quantities and amounts
                  reported for schedule III, IV & V controlled substances?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="inventoryReportLast" placeholder=""
                             name="inventoryReportLast" [(ngModel)]="deaForm.auditInfo.inventoryReportLast"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are all individual bottles and boxes labeled with a
                  unique identifier number, (i.e. 1-2021, 2-2021, etc.).</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="labelUnique" placeholder=""
                             name="labelUnique" [(ngModel)]="deaForm.auditInfo.labelUnique"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Do diluted solutions of controlled substances have
                  their own controlled substance usage log?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="dilutedSolutionsUsageLog"
                             placeholder="" name="dilutedSolutionsUsageLog"
                             [(ngModel)]="deaForm.auditInfo.dilutedSolutionsUsageLog"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Do multi-dose usage vials have their own controlled
                  substance usage log?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="vialsUsageLog" placeholder=""
                             name="vialsUsageLog" [(ngModel)]="deaForm.auditInfo.vialsUsageLog"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are pet parents allowed to bring in their pet's
                  controlled substances to be administered to their pet while inpatient or boarding at the facility? Is
                  there a separate controlled substance usage log?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="externCSWithLog" placeholder=""
                             name="externCSWithLog" [(ngModel)]="deaForm.auditInfo.externCSWithLog"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the DVMs and staff members trained to access
                  the controlled substances, inventory logs, supporting documents and the controlled substances from the
                  lock box when asked by a DEA Auditor during an audit.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="trainingPerformed" placeholder=""
                             name="trainingPerformed" [(ngModel)]="deaForm.auditInfo.trainingPerformed"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the certified veterinary technicians on staff
                  approved to handle controlled substances? (There are veterinary board & state specific regulations on
                  this subject).</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="handlingCSApprovalVT" placeholder=""
                             name="handlingCSApprovalVT" [(ngModel)]="deaForm.auditInfo.handlingCSApprovalVT"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the veterinary assistants on staff approved to
                  handle controlled substances? (There can be veterinary board & state specific regulations on this
                  subject).</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="handlingCSApprovalVA" placeholder=""
                             name="handlingCSApprovalVA" [(ngModel)]="deaForm.auditInfo.handlingCSApprovalVA"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are there any legend drugs that have been
                  reclassified as controlled substances that are not listed in the controlled substance log books, by
                  schedule and stored in the controlled substance lockbox? (e.g. Gabapentin)</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="legendDrugsReclassificationNotListed"
                             placeholder="" name="legendDrugsReclassificationNotListed"
                             [(ngModel)]="deaForm.auditInfo.legendDrugsReclassificationNotListed"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are there any controlled substances that have been
                  reclassified as legend drugs that are still listed in the controlled substance logs?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="legendDrugsReclassificationListed"
                             placeholder="" name="legendDrugsReclassificationListed"
                             [(ngModel)]="deaForm.auditInfo.legendDrugsReclassificationListed"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is the <span class="underline-text">original</span>
                  POA (Power of Attorney) on site and signed by the current DEA Primary Registrant, naming the employee
                  who orders the Schedule II controlled substances for the practice on the Primary Registrant's behalf?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="originalPOAOnSite" placeholder=""
                             name="originalPOAOnSite" [(ngModel)]="deaForm.auditInfo.originalPOAOnSite"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is the <span class="underline-text">original</span>
                  Revocation of POA on site and signed by the current Primary Registrant, removing the previous
                  employee's permission to order the controlled substances for the practice on his/her behalf?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="originalRevocationPOAOnSite"
                             placeholder="" name="originalRevocationPOAOnSite"
                             [(ngModel)]="deaForm.auditInfo.originalRevocationPOAOnSite"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If the original signed POA and/or Revocation POA
                  letter is not on site, is it kept at a DEA approved central recordkeeping site?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="originalPOAOnCentre" placeholder=""
                             name="originalPOAOnCentre" [(ngModel)]="deaForm.auditInfo.originalPOAOnCentre"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are unexecuted DEA 222 forms secured in a locked or
                  restricted access area? Describe controls in place from facility entrance down to locked drawers with
                  numbers of personnel with access. Also note if the DEA 222 forms are kept on site.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="unexecutedFormsSecured" placeholder=""
                             name="unexecutedFormsSecured" [(ngModel)]="deaForm.auditInfo.unexecutedFormsSecured"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are there any log book entries (for receipt,
                  administration or dispensing ) of CI or CII controlled substances that do not have an accompanying DEA
                  222 form? If so, specify the controlled substances, date, DEA 222 number, quantity of controlled
                  substance, etc.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="logBookEntriesCI" placeholder=""
                             name="logBookEntriesCI" [(ngModel)]="deaForm.auditInfo.logBookEntriesCI"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the DEA 222 forms executed in compliance with
                  Part 1305.06? (Destruction and/or Transfer of controlled substance Schedule II). If not, specify
                  non-compliant issues.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="forms222Compliance1305" placeholder=""
                             name="forms222Compliance1305" [(ngModel)]="deaForm.auditInfo.forms222Compliance1305"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are all outdated DEA 222 forms marked VOID, stored
                  in a locked cabinet and kept indefinitely ?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="forms222VoidStorage" placeholder=""
                             name="forms222VoidStorage" [(ngModel)]="deaForm.auditInfo.forms222VoidStorage"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is each executed DEA 222 Form signed by the DEA
                  Registrant at the time of use and not in advance of use?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="forms222SignedByReg" placeholder=""
                             name="forms222SignedByReg" [(ngModel)]="deaForm.auditInfo.forms222SignedByReg"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">As of October 30, 2021 the new single format 222
                  form must be used and the unused triplicate 222 forms stored in a locked file cabinet drawer with VOID
                  written across each form. Has this been done?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="forms222FormatChangeDone"
                             placeholder="" name="forms222FormatChangeDone"
                             [(ngModel)]="deaForm.auditInfo.forms222FormatChangeDone"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If a non-practitioner is ordering controlled
                  substances for the Primary Registrant through the use of the single sheet DEA 222 Form, is there a
                  fully executed Controlled Substance Power of Attorney form, signed by the Primary Registrant and
                  witnessed by two witnesses? (Code of Federal Regulations Title 21 CFR Sec 1305.05 Power of
                  Attorney).</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="codeFR21Supported222Format"
                             placeholder="" name="codeFR21Supported222Format"
                             [(ngModel)]="deaForm.auditInfo.codeFR21Supported222Format"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Controlled Substance Ordering System (CSOS): Is the
                  Primary Registrant signed up to submit his/her Schedule II Controlled Substances through <span
                    class="underline-text">CSOS</span>? (www.deaecom.gov/applycert.html)</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="csosPRsignedupToSubmit" placeholder=""
                             name="csosPRsignedupToSubmit" [(ngModel)]="deaForm.auditInfo.csosPRsignedupToSubmit"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If ordering a CII's through <span
                  class="underline-text">CSOS</span>, is a copy of the CII <span class="underline-text">CSOS</span>
                  order printed and kept on file, in a locked file cabinet in a separate drawer from Schedule III, IV
                  and V Controlled Substance invoices and ready to be matched to the invoice upon arrival?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="csosCIIorderKeeping" placeholder=""
                             name="csosCIIorderKeeping" [(ngModel)]="deaForm.auditInfo.csosCIIorderKeeping"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">If a non-practitioner is ordering controlled
                  substances for the Primary Registrant through the use of <span class="underline-text">CSOS</span>, is
                  there a fully executed Controlled Substance Power of Attorney form, signed by the Primary Registrant
                  and witnessed by two witnesses? (Code of Federal Regulations Title 21 CFR Sec 1305.05 Power of
                  Attorney)</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="codeFR21SupportedCSOS" placeholder=""
                             name="codeFR21SupportedCSOS" [(ngModel)]="deaForm.auditInfo.codeFR21SupportedCSOS"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are any of the DEA 222 forms used in <span
                  class="underline-text">CSOS</span> signed by someone other than an authorized Primary Registrant's
                  POA?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="csosWith222Forms" placeholder=""
                             name="csosWith222Forms" [(ngModel)]="deaForm.auditInfo.csosWith222Forms"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Has the assigned non-practitioner that is ordering
                  controlled substances for the Primary registrant through <span class="underline-text">CSOS</span> left
                  the practice? If so, is there a fully executed Controlled Substance Power of Attorney Revocation Form
                  in place? (Code of Federal Regulations Title 21 CFR Sec 1305.05 Power of Attorney)</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm"
                             id="hasControlledSubstancePoARevocationForm" placeholder=""
                             name="hasControlledSubstancePoARevocationForm"
                             [(ngModel)]="deaForm.auditInfo.hasControlledSubstancePoARevocationForm"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are distributor and/or manufacturer invoices for
                  controlled substances readily retrievable?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="invoicesRetrievable" placeholder=""
                             name="invoicesRetrievable" [(ngModel)]="deaForm.auditInfo.invoicesRetrievable"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are distributor/manufacturer invoices for Schedule
                  II controlled substances kept separately from distributor/manufacturer invoices for Schedule
                  III-V?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="invoicesSeparationBySchedule"
                             placeholder="" name="invoicesSeparationBySchedule"
                             [(ngModel)]="deaForm.auditInfo.invoicesSeparationBySchedule"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Do all invoices contain the following: Name,
                  address, DEA Primary Registrant's Number, strengths, forms and quantities received, registration
                  numbers and the name(s) of the distributors/manufacturers, order date ?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="invoicesContent" placeholder=""
                             name="invoicesContent" [(ngModel)]="deaForm.auditInfo.invoicesContent"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are both boxes in Section 5 of the 222 Form filled
                  out when the Schedule II order is received?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="boxesInSection5Filled" placeholder=""
                             name="boxesInSection5Filled" [(ngModel)]="deaForm.auditInfo.boxesInSection5Filled"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Were all Schedule II CS invoices reviewed, signed
                  and dated upon receipt of delivery of the Schedule II CS? Are the 222 forms filed separately from
                  their corresponding invoices and kept in a locked file cabinet? Are the Schedule II invoices filled
                  out correctly?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="invoicesReviewScheduleII"
                             placeholder="" name="invoicesReviewScheduleII"
                             [(ngModel)]="deaForm.auditInfo.invoicesReviewScheduleII"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Were all Schedule III-V CS invoices reviewed,
                  filled out correctly, signed and dated upon receipt of the Schedule III-V CS and filed separately in a
                  locked file cabinet from the Schedule II CS documents?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="invoicesReviewScheduleIII"
                             placeholder="" name="invoicesReviewScheduleIII"
                             [(ngModel)]="deaForm.auditInfo.invoicesReviewScheduleIII"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are all expired and unwanted controlled substances
                  sent to a Reverse Distributor for destruction? Review the Reverse Distributor destruction records for
                  the expired and unwanted controlled substances for the last two years and compare to the Reverse
                  Distributors logs and supporting documents for the same time period. Note any inconsistencies.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm"
                             id="controlledSubstancesExpiredDestruction" placeholder=""
                             name="controlledSubstancesExpiredDestruction"
                             [(ngModel)]="deaForm.auditInfo.controlledSubstancesExpiredDestruction"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">When a controlled substance is expired/unwanted is
                  it immediately removed from the controlled substance lockbox, dated, witnessed with a reason given in
                  the corresponding controlled substance log by schedule? Suggested reason "expired, sent to reverse
                  distributor".</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="controlledSubstancesExpiredRemoval"
                             placeholder="" name="controlledSubstancesExpiredRemoval"
                             [(ngModel)]="deaForm.auditInfo.controlledSubstancesExpiredRemoval"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is all controlled substance medical waste destroyed
                  using a neutralizing media such as RX Destroyer or Drug Buster and sent out for incineration as
                  required by CFR 21 §1304.22(c)?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="controlledSubstancesDestruction"
                             placeholder="" name="controlledSubstancesDestruction"
                             [(ngModel)]="deaForm.auditInfo.controlledSubstancesDestruction"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row col-sm-12 middle-align">
                <label class="col-form-label-sm smaller-label">Review the destruction methods for Controlled Substance
                  Medical Waste for the last two years and compare to the Controlled Substance Medical Waste records for
                  the same time period. Note any inconsistencies. <br/>
                  "Subpart A - Disposal of Controlled Substances by Registrants § 1317.05 Registrant disposal.<br/>
                  (a) Practitioner inventory. Any registered practitioner in lawful possession of a controlled substance
                  in its inventory that desires to dispose of that substance shall do so in one of the following
                  ways:<br/>
                  (1) Promptly destroy that controlled substance in accordance with subpart C of this part using an
                  on-site method of destruction;<br/>
                  (2) Promptly deliver that controlled substance to a <span
                    class="underline-text">reverse distributor's</span> registered location by common or contract
                  carrier pick-up or by <span class="underline-text">reverse distributor pick-up at the registrant's registered location;</span><br/>
                  (3) For the purpose of return or recall, promptly deliver that controlled substance by common or
                  contract carrier pick-up or pick-up by other registrants at the registrant's registered location to:
                  The registered person from whom it was obtained, the registered manufacturer of the substance, or
                  another registrant authorized by the manufacturer to accept returns or recalls on the manufacturer's
                  behalf; or<br/>
                  (4) Request assistance from the Special Agent in Charge of the Administration in the area in which the
                  practitioner is located.<br/>
                  (i) The request shall be made by submitting one copy of the DEA Form 41 to the Special Agent in Charge
                  in the practitioner's area. The DEA Form 41 shall list the controlled substance or substances which
                  the registrant desires to dispose.<br/>
                  (ii) The Special Agent in Charge shall instruct the registrant to dispose of the controlled substance
                  in one of the following manners:<br/>
                  (A) By transfer to a registrant authorized to transport or destroy the substance;<br/>
                  (B) By delivery to an agent of the Administration or to the nearest office of the Administration;
                  or<br/>
                  (C) By destruction in the presence of an agent of the Administration or other authorized person.<br/>
                  (5) In the event that a practitioner is required regularly to dispose of controlled substances, the
                  Special Agent in Charge may authorize the practitioner to dispose of such substances, in accordance
                  with subparagraph (a)(4) of this section, without prior application in each instance, on the condition
                  that the practitioner keep records of such disposals and file periodic reports with the Special Agent
                  in Charge summarizing the disposals. The Special Agent in Charge may place such conditions as he/she
                  deems proper on practitioner procedures regarding the disposal of controlled substances."
                </label>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">When a controlled substance becomes medical waste,
                  is it immediately placed in the neutralizing media as described above, dated, with the reason given
                  for the medical waste, witnessed and written in the corresponding controlled substance log, by
                  schedule?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="controlledSubstancesWasteProcedure"
                             placeholder="" name="controlledSubstancesWasteProcedure"
                             [(ngModel)]="deaForm.auditInfo.controlledSubstancesWasteProcedure"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are there any documented situations of unaccounted
                  losses or potential diversion of controlled substances that were not reported to DEA? If so, specify
                  situation and reasons why.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="situationsUnaccountedLosses"
                             placeholder="" name="situationsUnaccountedLosses"
                             [(ngModel)]="deaForm.auditInfo.situationsUnaccountedLosses"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Has the practice experienced any losses/ diversion
                  of controlled substances with use of outside contractors (e.g., delivery personnel, consultants,
                  etc.)? If so, specify situation and resolution.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm"
                             id="situationsUnaccountedLossesContractors" placeholder=""
                             name="situationsUnaccountedLossesContractors"
                             [(ngModel)]="deaForm.auditInfo.situationsUnaccountedLossesContractors"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are the accountability systems in the entire
                  practice sufficient to quickly detect the loss or diversion of a controlled substance? If not, please
                  specify potential areas for improvement.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="accountabilitySystemsSufficiency"
                             placeholder="" name="accountabilitySystemsSufficiency"
                             [(ngModel)]="deaForm.auditInfo.accountabilitySystemsSufficiency"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is there an automatic dispensing cabinet (Cubex,
                  VetSnap, etc.) used for capturing and tracking controlled substance movement within the practice? If
                  so, briefly outline characteristics of system.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="automaticDispensingCabinet"
                             placeholder="" name="automaticDispensingCabinet"
                             [(ngModel)]="deaForm.auditInfo.automaticDispensingCabinet"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Are outside regulatory consultants/auditors used to
                  assess the controlled substance quality systems? If so, who?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="qualitySystemsUsage" placeholder=""
                             name="qualitySystemsUsage" [(ngModel)]="deaForm.auditInfo.qualitySystemsUsage"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Does the practice have a controlled substance
                  policy with standard operating procedures that instructs the DVMs and support staff how to handle the
                  practice's controlled substances within the DEA's closed system? Does the policy describe the steps
                  required by the staff when ordering, receiving, storing, logging, recordkeeping, administering,
                  dispensing and prescribing controlled substances to the ultimate end user?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm"
                             id="cspStandardOperatingProceduresClosedSystem" placeholder=""
                             name="cspStandardOperatingProceduresClosedSystem"
                             [(ngModel)]="deaForm.auditInfo.cspStandardOperatingProceduresClosedSystem"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is the controlled substance policy containing the
                  practice's standard operating procedures being followed with respect to continuing records (e.g., each
                  step of the system is completed for every transaction in real time)? If not, specify non-compliant
                  situations.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm"
                             id="cspStandardOperatingProceduresContinuingRecords" placeholder=""
                             name="cspStandardOperatingProceduresContinuingRecords"
                             [(ngModel)]="deaForm.auditInfo.cspStandardOperatingProceduresContinuingRecords"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is the controlled substance policy containing the
                  practice's standard operating procedures being followed with respect to the handling and storage of
                  the controlled substances in the practice? If not, specify non-compliant situations.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="cspStandardOperatingProceduresStorage"
                             placeholder="" name="cspStandardOperatingProceduresStorage"
                             [(ngModel)]="deaForm.auditInfo.cspStandardOperatingProceduresStorage"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Does the practice's records indicate annual,
                  mandatory all staff training regarding the practice's Controlled Substance Policy and corresponding
                  standard operating procedures? If not, specify non-compliant situations.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="staffTraining" placeholder=""
                             name="staffTraining" [(ngModel)]="deaForm.auditInfo.staffTraining"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Does the practice's controlled substance training
                  occur for: (1) all new employees during the onboarding process, (2) only for new employees who are
                  likely to handle controlled substances, before they handle controlled substances on the floor, (3)
                  annually for all employees, (4) annually only for employees likely to handle controlled substances, or
                  (5) on a Practice Manager and Supervisor-specified basis. Indicate any and all situations by numbers
                  in the comments section.</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="staffTrainingOccurs" placeholder=""
                             name="staffTrainingOccurs" [(ngModel)]="deaForm.auditInfo.staffTrainingOccurs"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is controlled substance training only evaluated by
                  written testing?</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="staffTrainingEvaluation"
                             placeholder="" name="staffTrainingEvaluation"
                             [(ngModel)]="deaForm.auditInfo.staffTrainingEvaluation"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="form-row col-sm-12 middle-align">
                <label class="col-sm-5 col-form-label-sm text-right">Is training also evaluated by performance? Please
                  specify if non-compliance issues from an employee will trigger re-training (e.g., weighing and
                  documentation errors).</label>
                <div class="col-sm-7">
                  <div class="form-row">
                    <div class="col">
                      <input type="text" class="form-control form-control-sm" id="staffTrainingEvaluationByPerformance"
                             placeholder="" name="staffTrainingEvaluationByPerformance"
                             [(ngModel)]="deaForm.auditInfo.staffTrainingEvaluationByPerformance"/>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <br/>


            <div class="btn-group float-right mt-2" role="group">
              <button (click)="prevForm()" class="btn btn-outline-primary">&nbsp;&lt;&lt;&nbsp;</button>
              &nbsp;
              <button *ngIf="deaForm.status=='IN_PROGRESS'" type="submit" (click)="save()" class="btn btn-primary">
                Save
              </button>
              &nbsp;
              <button (click)="nextForm()" class="btn btn-outline-primary">&nbsp;&gt;&gt;&nbsp;</button>
            </div>
          </form>
        </div>
      </div>
      <br/>
    </div>
  </div>
</div>
